import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, Subject, from, switchMap, tap } from "rxjs";
import { CompanyDetailsResponse, CompanyResponse } from "../../types/company.types";
import { environment } from "src/environments/environment";
import { Auth } from "aws-amplify";
import { User } from "src/app/user-org-management/create-user/create-user.component";
import { AppState } from "src/app/store/app.state";
import { Store } from "@ngrx/store";
import * as fromCompanySelectors from "../../app/store/selectors/company.selector";
import * as CompanyActions from "../../app/store/actions/company.actions";
import { UpdateUser } from "src/app/user-org-management/update-user/update-user.component";
import { CreateCompany } from "src/app/user-org-management/create-org/create-org.component";

@Injectable({
  providedIn: "root",
})
export class CompanyService {
  subject: Subject<Object> = new Subject<Object>();
  updateCompanyTree = new Subject();

  constructor(private store: Store<AppState>, private http: HttpClient) { }

  getCompanyId(): void {
    from(Auth.currentSession())
      .pipe(
        tap((session) => {
          const token = session.getIdToken().getJwtToken();
          const decodedToken = this.decodeToken(token);
          const company_id = decodedToken["custom:company_id"] || "";
          this.store.dispatch(
            CompanyActions.parentCompanyId({ parentCompanyId: company_id })
          );
          this.store.dispatch(
            CompanyActions.companyId({ companyId: company_id })
          );
        })
      )
      .subscribe();
  }

  getCompanyTree(): Observable<CompanyResponse> {
    return this.store.select(fromCompanySelectors.selectParentCompanyId).pipe(
      switchMap((parentCompanyId) => {
        return this.http.get<CompanyResponse>(
          `${environment.apiUrl}/companies/${parentCompanyId}/devices`
        );
      })
    );
  }

  getCompanyDetails(company_id: string): Observable<CompanyDetailsResponse> {
    return this.http.get<CompanyDetailsResponse>(
      `${environment.apiUrl}/companies/${company_id}`
    );
  }

  private decodeToken(token: string) {
    const base64 = token.split(".")[1].replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  public createUser(companyId: string, user: User): Observable<CompanyResponse> {

    return this.http.post<CompanyResponse>(
      `${environment.apiUrl}/companies/${companyId}/user`, user
    );
  }
  public getUsers(): Observable<any> {
    return this.store.select(fromCompanySelectors.selectCompanyId).pipe(
      switchMap((companyId) => {
        return this.http.get<CompanyResponse>(
          `${environment.apiUrl}/companies/${companyId}/users`
        );
      })
    );
  }
  public getUserDetail(company_id: string, userId: string): Observable<any> {

    return this.http.get<CompanyResponse>(
      `${environment.apiUrl}/companies/${company_id}/users/${userId}`
    );

  }

  public updateUser(user: UpdateUser, company_id: string, user_id: string): Observable<any> {

    return this.http.put<any>(`${environment.apiUrl}/companies/${company_id}/users/${user_id}`, user);

  }


  public deleteUser(company_id: string, userId: string): Observable<any> {

    return this.http.delete<any>(
      `${environment.apiUrl}/companies/${company_id}/users/${userId}`
    );
  }

  public getUpdatedUsers(company_id: string): Observable<any> {

    return this.http.get<CompanyResponse>(
      `${environment.apiUrl}/companies/${company_id}/users`
    );
  }

  public getUpdatedDevice(company_id: string): Observable<any> {
    return from(Auth.currentSession()).pipe(
      switchMap((res) => {
        return this.http.get<any>(`${environment.apiUrl}/companies/${company_id}/devices`);
      })
    );
  }

  public getCompanies(): Observable<any> {
    return this.store.select(fromCompanySelectors.selectCompanyId).pipe(
      switchMap((companyId) => {
        return this.http.get<CompanyResponse>(
          `${environment.apiUrl}/companies/${companyId}`
        );
      })
    );
  }

  public createCompany(company: CreateCompany): Observable<CompanyResponse> {

    return this.http.post<CompanyResponse>(
      `${environment.apiUrl}/company`, company

    );
  }

  public getUpdatedCompany(company_id: string): Observable<any> {

    return this.http.get<CompanyResponse>(
      `${environment.apiUrl}/companies/${company_id}`
    );
  }

  public deleteCompany(company_id: string): Observable<any> {

    return this.http.delete<any>(
      `${environment.apiUrl}/companies/${company_id}`
    );
  }

  public createApiKeys(company_id: string, name: string, role: string): Observable<any> {
    const body = {
      name: name,
      role: role
    };
    return this.http.post<any>(
      `${environment.apiUrl}/company/${company_id}/apikeys`, body
    );

  }

  public getAllApiKeys(company_id: string): Observable<any> {
    return this.http.get<any>(
      `${environment.apiUrl}/company/${company_id}/apikeys`
    );
  }

  public deleteApiKey(company_id: string, client_id: string): Observable<any> {
    return this.http.delete<any>(
      `${environment.apiUrl}/company/${company_id}/apikeys/${client_id}`
    );
  }

}
