import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements ICellRendererAngularComp {
  customValue!: string;
  showSpinner!: boolean;

  agInit(params: any): void {
    this.showSpinner = params.showSpinner;
  }

  refresh(params: any): boolean {
    this.showSpinner = params.showSpinner;
    return true;
  }
}
