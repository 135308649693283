<div class="scrolledTable">
  <ix-tabs [selected]="selectedTab" layout="stretched" class="tabs">
    <ix-tab-item (click)="changeTab(0)">
      <span class="icon">
        <ix-icon name="globe-filled"></ix-icon>
        <div>Orgs</div>
      </span>
    </ix-tab-item>
    <ix-tab-item (click)="changeTab(1)">
      <span class="icon">
        <ix-icon name="user-management"></ix-icon>
        Users
      </span>
    </ix-tab-item>
    <ix-tab-item (click)="changeTab(2)">
      <span class="icon">
        <ix-icon name="ai"></ix-icon>
        Asset
      </span>
    </ix-tab-item>
    <ix-tab-item (click)="changeTab(3)">
      <span class="icon">
        <ix-icon name="lock-key"></ix-icon>
        Access Tokens
      </span>
    </ix-tab-item>
  </ix-tabs>
  <ng-container *ngIf="!isLoading ; else loading">
    <ng-container class="content"
      *ngIf="!isLoadingData && (selectedTab === 0 && companyRowData!.length>0 || selectedTab === 1 && userRowData!.length>0 || selectedTab === 2 && deviceRowData!.length>0 || selectedTab === 3) else noData">
      <div class="header" *ngIf="selectedTab === 0 || selectedTab === 1 || selectedTab === 2">
        <div class="searchBar">
          <form class="needs-validation m-2">
            <ix-input-group>
              <span slot="input-start">
                <ix-icon name="search" size="16"></ix-icon>
              </span>
              <input ng-init="verifyStart()" [(ngModel)]="customSearch" name="input" (keyup)="onKey($event)"
                id="input-string" type="string" />
              <span slot="input-end">
                <ix-icon-button (click)="clearInput()" id="clear-button" icon="clear" ghost="{true}" size="16"
                  [style.display]="display"></ix-icon-button>
              </span>
            </ix-input-group>
          </form>
        </div>
        <ix-button *ngIf="selectedTab === 0" class="m-1" id="button" variant="primary" (click)="open('480')">Create
          Org</ix-button>

        <ix-button *ngIf="selectedTab === 1" class="m-1" id="button" variant="primary" (click)="open('840')">Create
          User</ix-button>

        <ix-button *ngIf="selectedTab === 2" class="m-1" id="button" variant="primary" (click)="open('360')">Claim
          Asset</ix-button>
      </div>
      <div class="header" *ngIf="selectedTab === 3">
        <ix-content-header headerTitle="Create New Access Token"></ix-content-header>

      </div>
      <div class="form-token" *ngIf="selectedTab === 3">
        <div class="form-group">
          <label for="token-name">Enter token name</label>
          <input [(ngModel)]="tokenName" (ngModelChange)="onTokenNameChange()" type="text" id="token-name"
            placeholder="Token name">
          <span *ngIf="showTokenNameError">Must choose a token name</span>
        </div>

        <div class="form-group">
          <label for="role-select">Select Role</label>
          <ix-select [(ngModel)]="roleSelect" name="ix-select-control" id="role-select">
            <ix-select-item label="Company Admin" value="COMPANY_ADMIN"
              (click)="onRoleClick('COMPANY_ADMIN')"></ix-select-item>

            <ix-select-item label="Company User" value="COMPANY_USER"
              (click)="onRoleClick('COMPANY_USER')"></ix-select-item>

          </ix-select>
          <span *ngIf="showRoleError">Must choose a role</span>
        </div>

        <div class="form-group">
          <ix-button class="button-line" [loading]="Loading" (click)="createAccessToken()">
            Create Access Token
          </ix-button>
        </div>
      </div>
      <div class="display-accessToken"
        *ngIf="selectedTab === 3 && client_secret !== '' && client_id !== '' && displayAccess">
        <ix-content-header headerTitle="Your new access token" variant="secondary"
          headerSubtitle="Please make sure to copy the tokens - you won't be able to access it again."><ix-icon-button
            icon="close" size="20" ghost (click)="displayAccessModal()"></ix-icon-button></ix-content-header>
        <div class="form-token-display">
          <div class="form-group">
            <label for="clientID">client-id</label>
            <ix-input-group>
              <span slot="input-start"><ix-icon-button icon="eye" size="16" ghost
                  (click)="displaySecretKey('client_id')"></ix-icon-button></span>
              <input [type]="displayClientId ? 'text' : 'password'" id="clientID" placeholder="client-id"
                [value]="client_id" readonly>
              <span slot="input-end"><ix-icon-button icon="copy" size="16" ghost
                  (click)="copytoclipboardClientId()"></ix-icon-button></span>
            </ix-input-group>
          </div>
          <div class="form-group">
            <label for="clientSecret">client-secret</label>
            <ix-input-group>
              <span slot="input-start"><ix-icon-button icon="eye" size="16" ghost
                  (click)="displaySecretKey('client_secret')"></ix-icon-button></span>
              <input [type]="displayClientSecret ? 'text' : 'password'" id="clientSecret" placeholder="client-secret"
                [value]="client_secret" readonly>
              <span slot="input-end"><ix-icon-button icon="copy" size="16" ghost
                  (click)="copytoclipboardClientSecret()"></ix-icon-button></span>
            </ix-input-group>
          </div>
        </div>
        <ix-button ghost icon="copy" (click)="copyBothToClipboard()">
          Copy both
        </ix-button>
      </div>

      <div class="title-header-token" *ngIf="selectedTab === 3">
        <ix-content-header headerTitle="Active access Tokens"></ix-content-header>
      </div>

      <div class="searchBar" *ngIf="selectedTab === 3">
        <form class="needs-validation m-2">
          <ix-input-group>
            <span slot="input-start">
              <ix-icon name="search" size="16"></ix-icon>
            </span>
            <input ng-init="verifyStart()" [(ngModel)]="customSearch" name="input" (keyup)="onKey($event)"
              id="input-string" type="string" />
            <span slot="input-end">
              <ix-icon-button (click)="clearInput()" id="clear-button" icon="clear" ghost="{true}" size="16"
                [style.display]="display"></ix-icon-button>
            </span>
          </ix-input-group>
        </form>
      </div>
      <!-- <hr> -->


      <div class="title-header" *ngIf="selectedTab !== 3">

        <ix-content-header class="one" *ngIf="selectedTab === 0" headerTitle="Org list">
        </ix-content-header>

        <ix-content-header class="one" *ngIf="selectedTab === 1" headerTitle="Users list">
        </ix-content-header>

        <ix-content-header class="one" *ngIf="selectedTab === 2" headerTitle="Assets list">
        </ix-content-header>


        <div class="two" *ngIf="selectedTab ===2" class="tabsContainer">
          <ix-tabs [selected]="selectedAssetTab" layout="stretched" class="assettabs">
            <ix-tab-item class="one" (click)="changeAssetTab(0)">
              <span>
                <div>4G</div>
              </span>
            </ix-tab-item>
            <ix-tab-item class="two" (click)="changeAssetTab(1)">
              <span>
                LoRa
              </span>
            </ix-tab-item>
            <ix-tab-item class="three" (click)="changeAssetTab(2)">
              <span>
                LoRa Gateway
              </span>
            </ix-tab-item>
          </ix-tabs>
        </div>
      </div>
      <div class="grid" *ngIf="selectedTab === 0">
        <ag-grid-angular style="height: 29rem; width: 100%" class="gridMobile" [rowData]="filteredOrgData"
          [columnDefs]="orgsColumnDefs" class="ag-theme-alpine-dark ag-theme-ix" [context]="{ componentParent: this }">
        </ag-grid-angular>
      </div>
      <div class="grid" *ngIf="selectedTab === 1">
        <ag-grid-angular style="height: 29rem; width: 100%" [rowData]="filteredUserData" [columnDefs]="userColumnDefs"
          suppressCellFocus class="ag-theme-alpine-dark ag-theme-ix" [context]="{ componentParent: this }">
        </ag-grid-angular>
      </div>
      <div class="grid" *ngIf="selectedTab === 2 && selectedAssetTab === 0">
        <ng-container *ngIf="deviceRowData!.length>0; else noData">
          <ag-grid-angular style="height: 29rem; width: 100%" [rowData]="filtered4GData" [columnDefs]="deviceColumnDefs"
            suppressCellFocus class="ag-theme-alpine-dark ag-theme-ix" [context]="{ componentParent: this }">
          </ag-grid-angular>
        </ng-container>
      </div>
      <div class="grid" *ngIf="selectedTab === 2 && selectedAssetTab === 1">
        <ng-container *ngIf="deviceRowData!.length>0 ; else noData">
          <ag-grid-angular style="height: 29rem; width: 100%" [rowData]="filteredLoRaData"
            [columnDefs]="deviceColumnDefs" suppressCellFocus class="ag-theme-alpine-dark ag-theme-ix"
            [context]="{ componentParent: this }">
          </ag-grid-angular>
        </ng-container>
      </div>
      <div class="grid" *ngIf="selectedTab === 3">
        <ag-grid-angular style="height: 29rem; width: 100%" [rowData]="filteredApiKeysData"
          [columnDefs]="apiKeysColumnDefs" suppressCellFocus class="ag-theme-alpine-dark ag-theme-ix"
          [context]="{ componentParent: this }">
        </ag-grid-angular>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #noData>
    <div class="noData"
      *ngIf="!isLoadingData && !(selectedTab === 0 && companyRowData!.length>0 || selectedTab === 1 && userRowData!.length>0 || selectedTab === 2 && deviceRowData!.length>0) else loading ">
      <ix-empty-state
        [header]="selectedTab === 0 ? 'No organisations available' : (selectedTab === 1 ? 'No users available' : (selectedTab === 2 ? 'No assets available' : null))"
        subHeader="Create an element first" icon="add" action="Create element"
        (actionClick)="(selectedTab === 0 ? open('480') : (selectedTab === 1 ? open('840') : (selectedTab === 2? open('360') : null)))"></ix-empty-state>
    </div>
  </ng-template>
  <ng-template #loading>
    <ix-spinner class="spinner" size="large"></ix-spinner>
  </ng-template>
</div>