import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.state';
import { selectCompanyId, selectDevice } from 'src/app/store/selectors/company.selector';
import { DeviceParameterService } from 'src/services/device-parameter/device-parameter.service';
import { map } from 'rxjs/operators';
import { ModalService } from "@siemens/ix-angular";
import { ParameterModalComponent } from '../parameter-modal/parameter-modal.component';
import { setParameterValue } from 'src/app/store/actions/parameter.actions';

@Component({
  selector: 'app-refresh-icon',
  styleUrls: ["./button-read-write-param.component.scss"],
  templateUrl: "./button-read-write-param.component.html",
})
export class ButtonAskData implements ICellRendererAngularComp {
  private params: any;
  private companyId: string | undefined;
  private deviceId: string | undefined;

  constructor(
    private store: Store<AppState>,
    private deviceParameterService: DeviceParameterService,
    private modalService: ModalService,
  ) { }

  agInit(params: any): void {
    this.params = params;

    this.store.select(selectCompanyId).pipe(map(companyId => {
      this.companyId = companyId;
    })).subscribe();

    this.store.select(selectDevice).pipe(
      map(device => device ? device.DeviceId : undefined)
    ).subscribe(deviceId => {
      this.deviceId = deviceId;
    });
  }

  refresh(params: any): boolean {
    return false;
  }

  async openModal() {
    this.store.dispatch(setParameterValue({ parameterValue: this.params.data }));
    if (this.params.data) {
      const modalRef = await this.modalService.open({
        content: ParameterModalComponent,
        size: '600',
        backdrop: true,
        centered: true,
        animation: true,

        data: this.params.data,
      });
      modalRef.onClose.on(() => {
        console.log('Modal closed, let refresh tab after response from modal');
      });
    }
  }
}
