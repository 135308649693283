import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Subscription } from 'rxjs';
import { SidenavService } from 'src/services/Behavior/sidenav-assets';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss']
})
export class ContainerComponent implements OnInit {

  @ViewChild('sidenav', { static: false }) sidenav!: MatSidenav;
  private sidenavSubscription: Subscription;

  constructor(private sidenavService: SidenavService) {
    this.sidenavSubscription = this.sidenavService.sidenavToggleRequested$.subscribe(() => {
      if (this.sidenav) {
        this.sidenav.open();
      }
    });
  }

  ngOnDestroy() {
    if (this.sidenavSubscription) {
      this.sidenavSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
  }

}
