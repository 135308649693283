import { Component, ViewChild, OnInit } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Store } from "@ngrx/store";
import { DeviceService } from "src/services/device/device.service";
import { ButtonRendererComponent } from "../user-org-management/button-renderer/button-renderer.component";
import { AppState } from "../store/app.state";
import { Company } from "src/types/company.types";
import { DeviceEntity } from "../user-org-management/user-org-management.component";
import * as fromThemeSelectors from "../../app/store/selectors/themeswitcher.selector";

@Component({
  selector: "app-dynamictable",
  templateUrl: "./dynamictable.component.html",
  styleUrls: ["./dynamictable.component.scss"],
})
export class DynamictableComponent implements OnInit {
  // displayedColumns = ["Asset Type", "Claim Status", "IMEI", "Serial Number"];
  // dataSource!: MatTableDataSource<any>;
  // @ViewChild(MatPaginator)
  // paginator!: MatPaginator | null;
  // @ViewChild(MatSort)
  // sort!: MatSort | null;
  customSearch = "";
  display = "none";
  deviceRowData: DeviceEntity[] = [];
  filteredDeviceData: any[] | undefined;
  currentTheme: any;

  constructor(
    private apiService: DeviceService,
    private store: Store<AppState>
  ) {
    this.store
      .select(fromThemeSelectors.selectThemeToken)
      .subscribe((token) => {
        this.currentTheme = token;
        if (!token) {
          this.currentTheme = "theme-brand-light";
        }
      });
  }

  /**
   * Set the paginator and sort after the view init since this component will
   * be able to query its view for the initialized paginator and sort.
   */
  // ngAfterViewInit() {
  //   if (this.dataSource) {
  //     this.dataSource.paginator = this.paginator;
  //     this.dataSource.sort = this.sort;
  //   }
  // }

  // applyFilter(event: any) {
  //   let filterValue = event.target.value;
  //   filterValue = filterValue.trim(); // Remove whitespace
  //   filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
  //   this.dataSource.filter = filterValue;
  // }
  deviceColumnDefs = [
    { headerName: "Asset Type", field: "AssetType", flex: 1 },
    { headerName: "Claim Status", field: "ClaimStatus", flex: 1 },
    { headerName: "Device ID", field: "DeviceId", flex: 1 },
    { headerName: "Serial Number", field: "SerialNumber", flex: 1 },
    { headerName: "Area", field: "CompanyName", flex: 1 },
    {
      headerName: "Actions",
      field: "id",
      suppressNavigable: true,
      sortable: false,
      suppressCellSelection: true,
      cellRenderer: ButtonRendererComponent,
    },
    
  ];
  ngOnInit(): void {
    this.apiService.getDevice().subscribe((data) => {
      this.deviceRowData = [];
      this.addChildrenDevice(data.data);
      this.filteredDeviceData = this.deviceRowData;
    });
  }

  clearInput() {
    this.customSearch = "";
    this.display = "none";
  }

  onKey(event: any) {
    event.target.value === ""
      ? (this.display = "none")
      : (this.display = "block");
    this.search(event.target.value);
  }

  search(value1: string) {
    this.filteredDeviceData = this.deviceRowData?.filter((item) =>
      Object.values(item).some((value) =>
        value?.toString().toLowerCase().includes(value1.toLowerCase())
      )
    );
  }

  addChildrenDevice(parentCompany: Company) {
    if (parentCompany.Children.length == 0) {
      const deviceData: DeviceEntity[] = parentCompany?.Devices!;
      deviceData.forEach((data) => {
        data.CompanyName = parentCompany.CompanyName;
      });
      this.deviceRowData = [...this.deviceRowData, ...deviceData];
      return;
    }
    for (let i = 0; i < parentCompany.Children.length; i++) {
      this.addChildrenDevice(parentCompany.Children[i]);
    }
    const deviceData: DeviceEntity[] = parentCompany?.Devices!;
    deviceData.forEach((data) => {
      data.CompanyName = parentCompany.CompanyName;
    });
    this.deviceRowData = [...this.deviceRowData, ...deviceData];
    return;
  }
}
