import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/store/app.state';
import { DeviceService } from 'src/services/device/device.service';

//ce resolver permet de charger les données avant que le composant ne soit rendu
@Injectable({
 providedIn: 'root'
})
export class DeviceResolver implements Resolve<any> {
  constructor(private deviceService: DeviceService) {}

  resolve(): Observable<any> {
    return this.deviceService.getDevice();
  }
}