import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "../store/app.state";
import * as fromCompanySelector from "../../app/store/selectors/company.selector";
@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  device : boolean = false;
  company: boolean = true;
  constructor(private store : Store<AppState>) {}
  displayProps(){
    this.store.select(fromCompanySelector.selectDevice).subscribe(data => {
      if(data){
        this.device = true;
        this.company = false;
      }
    });
    this.store.select(fromCompanySelector.selectToken).subscribe(data => {
      if(data){
        this.company = true;
        this.device = false;
      }
    });
  }
  ngOnInit(): void {
    this.displayProps();
  }
}
