import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, switchMap } from "rxjs";
import { environment } from "src/environments/environment";
import { Store } from "@ngrx/store";
import * as fromCompanySelectors from "../../../app/store/selectors/company.selector";
import { AppState } from "src/app/store/app.state";
import { Device } from "src/app/user-org-management/claim-device/claim-device.component";
import { FTP } from "src/types/ftp.types";

@Injectable({
    providedIn: "root",
})
export class FtpService {
    constructor(
        private http: HttpClient,

        private store: Store<AppState>,
    ) { }

    public AddFTPConfiguration(dataFtp: FTP, CompanyId: string, DeviceId: string): Observable<any> {

        return this.http.post<any>(
            `${environment.apiUrl}/companies/${CompanyId}/devices/${DeviceId}/process-values/forward-ftp`, { host: dataFtp.Host, port: Math.floor(dataFtp.Port), username: dataFtp.Username, password: dataFtp.Password, destination_path: dataFtp.destination_path, ftp_type: dataFtp.FTP_Type }
        );

    }
    public GetFTPConfiguration(CompanyId: string, DeviceId: string): Observable<any> {
        return this.http.get<any>(
            `${environment.apiUrl}/companies/${CompanyId}/devices/${DeviceId}/process-values/forward-ftp`
        );
    }

    public DeleteFTPConfiguration(FTP_Type: string, CompanyId: string, DeviceId: string): Observable<any> {
        return this.http.delete<any>(
            `${environment.apiUrl}/companies/${CompanyId}/devices/${DeviceId}/process-values/forward-ftp/${FTP_Type}`
        );
    }

    public UpdateFTPConfiguration(FTP_Type: string, dataFtp: FTP, CompanyId: string, DeviceId: string): Observable<any> {

        return this.http.put<any>(
            `${environment.apiUrl}/companies/${CompanyId}/devices/${DeviceId}/process-values/forward-ftp/${FTP_Type}`,
            { host: dataFtp.Host, port: Math.floor(dataFtp.Port), username: dataFtp.Username, password: dataFtp.Password, destination_path: dataFtp.destination_path }

        );
    }
}
