import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Point } from 'ol/geom';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import { Feature } from 'ol';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { fromLonLat } from 'ol/proj';

@Component({
  selector: 'app-openlayersmap',
  templateUrl: './openlayersmap.component.html',
  styleUrls: ['./openlayersmap.component.scss'],
})
export class OpenlayersmapComponent implements AfterViewInit {
  @ViewChild('mapContainer', { static: false }) mapContainer!: ElementRef;
  map!: Map;
  store: any;


  ngAfterViewInit(): void {
    this.map = new Map({
      target: this.mapContainer.nativeElement,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      view: new View({
        center: fromLonLat([77, 28]), // Centered at longitude 77 and latitude 28
        zoom: 6, // Zoom level 6
      }),
    });

    this.addRandomMarkers(5);

  }

  addRandomMarkers(count: number): void {
    const vectorSource = new VectorSource();
    const centerLon = 77;
    const centerLat = 28;
    const lonRange = 5; // Adjust the range as needed for longitude
    const latRange = 5; // Adjust the range as needed for latitude

    for (let i = 0; i < count; i++) {
      const randomLon = centerLon + (Math.random() * lonRange - lonRange / 2); // Longitude near the center
      const randomLat = centerLat + (Math.random() * latRange - latRange / 2); // Latitude near the center

      const marker = new Feature({
        geometry: new Point(fromLonLat([randomLon, randomLat])),
      });

      marker.setStyle(
        new Style({
          image: new Icon({
            src: 'assets/point-location.png', //TODO URL to marker icon
            anchor: [0.5, 1],
          }),
        })
      );

      vectorSource.addFeature(marker);
    }

    const vectorLayer = new VectorLayer({
      source: vectorSource,
    });

    this.map.addLayer(vectorLayer);
  }

}
