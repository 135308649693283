import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, switchMap, distinctUntilChanged, catchError, of, tap, BehaviorSubject, take } from 'rxjs';
import { environment } from "src/environments/environment";
import * as fromCompanySelectors from "../../app/store/selectors/company.selector";
import { AppState } from 'src/app/store/app.state';
import * as AlarmsActions from "../../app/store/actions/alarms.actions";

@Injectable({
  providedIn: 'root'
})
export class AlarmsService {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private CompanyLoadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();
  CompanyLoading$ = this.CompanyLoadingSubject.asObservable();

  constructor(
    private http: HttpClient,
    private store: Store<AppState>
  ) { }

  public getAlarms(from?: string, to?: string): Observable<any> {
    let params = new HttpParams();
    return this.store.select(fromCompanySelectors.selectDevice).pipe(
      take(1),
      switchMap(data => {
        this.loadingSubject.next(true)
        if (!data?.CompanyId){
          console.error('CompanyId is missing');
          this.loadingSubject.next(false);
          return of([]);
        } 
        if (from) {
          params = params.set('from_date', from);
          if (to) {
            params = params.set('to_date', to);
          }
        }
        return this.http.get<any>(
          `${environment.apiUrl}/companies/${data?.CompanyId}/devices/${data.DeviceId}/alarms`, { params }).pipe(
            tap(alarmsData => {
              this.store.dispatch(AlarmsActions.alarms({ alarmsData}));
              this.loadingSubject.next(false);
            }),
            catchError(error => {
              console.error('Erreur lors de la récupération des alarmes', error);
              this.loadingSubject.next(false);
              return of([]);
            })
          );
      })
    );
  }

  public getCompanyAlarms(from?: string, to?: string): Observable<any> {
    let params = new HttpParams();
    return this.store.select(fromCompanySelectors.selectCompanyId).pipe(
      take(1),
      switchMap(data => {
        this.CompanyLoadingSubject.next(true)
        if (from) {
          params = params.set('from_date', from);
          if (to) {
            params = params.set('to_date', to);
          }
        }
        return this.http.get<any>(
          `${environment.apiUrl}/companies/${data}/devices/alarms`, { params }).pipe(
            tap(alarmsData => {
              this.store.dispatch(AlarmsActions.alarms({ alarmsData }));
              this.CompanyLoadingSubject.next(false);
            }),
            catchError(error => {
              console.error('Erreur lors de la récupération des alarmes', error);
              this.CompanyLoadingSubject.next(false);
              return of([]);
            })
          );
      })
    );
  }
}
