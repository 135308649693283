<div class="modal-content">

    <ix-modal-header hideClose="True" *ngIf="isReadonly" class="modal-header">
        <h2>Read Value</h2>
        <h3>{{parameter.paramName}}</h3>
    </ix-modal-header>

    <ix-modal-header hideClose="True" *ngIf="!isReadonly" class="modal-header">
        <h2>Modify Value</h2>
        <h3>{{parameter.paramName}}</h3>
    </ix-modal-header>



    <!-- READ ONLY FORMS -->
    <ng-container *ngIf="isReadonly">
        <ng-container [ngSwitch]="true">

            <!-- DEFAULT IN RAW VALUE -->
            <form *ngSwitchDefault>
                <label>Actual value</label>
                <div class="line">
                    <input type="text" [value]="parameter.value" readonly>
                    <ix-button class="button-line" (click)="onRead()">Read</ix-button>
                </div>
                <div class="lineValue margin-bot">
                    <p>Min: {{ parameter.min }}</p>
                    <p>Max: {{ parameter.max }}</p>
                </div>
            </form>

        </ng-container>
    </ng-container>


    <!-- READ AND WRITE FORMS -->
    <ng-container *ngIf="!isReadonly">

        <!-- WRITE PART -->
        <ng-container [ngSwitch]="true">

            <!------------------>
            <!-- CASE BOOLEAN -->
            <!------------------>
            <ng-container *ngSwitchCase="isBool">

                <!-- READ PART -->
                <form>
                    <label>Actual value</label>
                    <div class="line">
                        <input type="text" [value]="parameter.value" readonly>
                        <ix-button class="button-line" (click)="onRead()">Read</ix-button>
                    </div>
                    <div class="lineValue margin-bot">
                        <p>Min: {{ parameter.min }}</p>
                        <p>Max: {{ parameter.max }}</p>
                    </div>
                </form>

                <!--WRITE PART -->
                <form class="radioForm" [formGroup]="formRadio">
                    <label>New value</label>
                    <!-- we should use a dynamic way to render the radio values -->
                    <input type="radio" id="moduleType1" name="radio" formControlName="radio" value="1"
                        (change)="onRadioChange($event)" />
                    <label for="moduleType1">Enable</label>
                    <input type="radio" id="moduleType2" name="radio" formControlName="radio" value="0"
                        (change)="onRadioChange($event)" />
                    <label for="moduleType2">Disable</label>
                    <div class="last-button">
                        <ix-button class="button-line" (click)="onWrite()">Write</ix-button>
                    </div>
                </form>
            </ng-container>


            <!-------------------->
            <!-- CASE NUMERICAL -->
            <!-------------------->
            <ng-container *ngSwitchCase="isNumerical">

                <!-- READ PART -->
                <form>
                    <label>Actual value</label>
                    <div class="line">
                        <input type="text" [value]="parameter.value" readonly>
                        <ix-button class="button-line" (click)="onRead()">Read</ix-button>
                    </div>
                    <div class="lineValue margin-bot">
                        <p>Min: {{ parameter.min }}</p>
                        <p>Max: {{ parameter.max }}</p>
                    </div>
                </form>

                <!--WRITE PART -->
                <form>
                    <label>New value</label>
                    <ix-input-group class="line">
                        <div class="test">
                            <input [(ngModel)]="newValueToWrite" name="newValueToWrite" placeholder="New value"
                                type="text" tabindex="1" autofocus />
                            <div class="lineValue margin-bot">
                                <p>Min: {{ parameter.min }}</p>
                                <p>Max: {{ parameter.max }}</p>
                            </div>
                        </div>
                        <ix-button class="button-line" (click)="onWrite()">Write</ix-button>
                    </ix-input-group>
                </form>
            </ng-container>

            <!----------------------->
            <!-- NUMERICAL SECONDS -->
            <!----------------------->
            <ng-container *ngSwitchCase="isNumericalSeconds">

                <!-- READ PART -->
                <form>
                    <label>Actual value</label>
                    <div class="line">
                        <input type="text" [value]="parameter.value" readonly>
                        <ix-button class="button-line" (click)="onRead()">Read</ix-button>
                    </div>
                    <div class="lineValue margin-bot">
                        <p>Min: {{ parameter.min }}</p>
                        <p>Max: {{ parameter.max }}</p>
                    </div>
                </form>

                <!--WRITE PART -->
                <label>New Value</label>
                <div class="intervalSelection">
                    <div class="interval" *ngIf="parameter.register !== '5024'">
                        <label>Days</label>
                        <ix-select editable="false" label="date" (valueChange)="onDaysChange($event)">
                            <ix-select-item *ngFor="let i of [].constructor(31); let idx = index" label="{{idx}}"
                                value="{{idx}}"></ix-select-item>
                        </ix-select>
                    </div>
                    <div class="interval">
                        <label>Hours</label>
                        <ix-select (valueChange)="onHoursChange($event)">
                            <ix-select-item *ngFor="let i of [].constructor(24); let idx = index" label="{{idx}}"
                                value="{{idx}}"></ix-select-item>
                        </ix-select>
                    </div>
                    <div class="interval">
                        <label>Minutes</label>
                        <ix-select (valueChange)="onMinutesChange($event)">
                            <ix-select-item *ngFor="let i of [].constructor(60); let idx = index" label="{{idx}}"
                                value="{{idx}}"></ix-select-item>
                        </ix-select>
                    </div>
                    <div class="interval" *ngIf="parameter.register !== '5024'">
                        <label>Seconds</label>
                        <ix-select (valueChange)="onSecondsChange($event)">
                            <ix-select-item *ngFor="let i of [].constructor(12); let idx = index" label="{{idx*5}}"
                                value="{{idx*5}}"></ix-select-item>
                        </ix-select>
                    </div>
                    <div class="line">

                        <ix-button class="button-line" (click)="onWrite()"
                            [disabled]="!isWriteValueValid">Write</ix-button>
                    </div>
                </div>

                <div class="lineValue margin-bot">
                    <p [ngClass]="currentClassMin()">
                        Min: {{ parameter.min }}
                    </p>
                    <p>Current: {{ newValueToWrite}}</p>
                    <p [ngClass]="currentClassMax()">
                        Max: {{ parameter.max }}
                    </p>
                </div>
            </ng-container>

            <!-------------->
            <!-- CHECKBOX -->
            <!-------------->
            <ng-container *ngSwitchCase="isCheckbox">

                <!-- READ PART -->
                <form>
                    <label>Actual value</label>
                    <div class="line">
                        <input type="text" [value]="parameter.value" readonly>
                        <ix-button class="button-line" (click)="onRead()">Read</ix-button>
                    </div>
                    <div class="lineValue margin-bot">
                        <p>Min: {{ parameter.min }}</p>
                        <p>Max: {{ parameter.max }}</p>
                    </div>
                </form>

                <!--WRITE PART -->
                <form *ngIf="parameter.register === '5015'" class="form-checkbox">
                    <div class="checkbox-container">
                        <div class="checkbox-item" *ngFor="let checkbox of checkBoxes5015">
                            <input type="checkbox" [id]="'moduleType' + checkbox" name="checkbox" [value]="checkbox"
                                (change)="onCheckboxChange($event)" [checked]="checkedValues.has(checkbox)" />
                            <label [for]="'moduleType' + checkbox">{{ checkbox }}</label>
                        </div>
                    </div>
                    <ix-button class="button-right" (click)="onWrite()">Write</ix-button>
                </form>
            </ng-container>

            <!----------->
            <!-- RADIO -->
            <!----------->
            <ng-container *ngSwitchCase="isRadio">

                <!-- READ PART -->
                <form>
                    <label>Actual value</label>
                    <div class="line">
                        <input type="text" [value]="parameter.value" readonly>
                        <ix-button class="button-line" (click)="onRead()">Read</ix-button>
                    </div>
                    <div class="lineValue margin-bot">
                        <p>Min: {{ parameter.min }}</p>
                        <p>Max: {{ parameter.max }}</p>
                    </div>
                </form>

                <!--WRITE PART -->
                <form class="radioForm" [formGroup]="formRadio" *ngIf="parameter.register === '5016'">
                    <label>{{parameter.paramName}}</label>
                    <!-- we should use a dynamic way to render the radio values -->
                    <input type="radio" id="moduleType1" name="radio" formControlName="radio" value="0"
                        (change)="onRadioChange($event)" />
                    <label for="moduleType1">Poor</label>
                    <input type="radio" id="moduleType2" name="radio" formControlName="radio" value="1"
                        (change)="onRadioChange($event)" />
                    <label for="moduleType2">Bad</label>
                    <input type="radio" id="moduleType3" name="radio" formControlName="radio" value="2"
                        (change)="onRadioChange($event)" />
                    <label for="moduleType3">good</label>
                    <input type="radio" id="moduleType4" name="radio" formControlName="radio" value="3"
                        (change)="onRadioChange($event)" />
                    <label for="moduleType4">Very good</label>
                    <input type="radio" id="moduleType5" name="radio" formControlName="radio" value="4"
                        (change)="onRadioChange($event)" />
                    <label for="moduleType5">Exellent</label>
                    <div class="last-button">
                        <ix-button class="button-line" (click)="onWrite()">Write</ix-button>
                    </div>
                </form>
                <form class="radioForm" [formGroup]="formRadio" *ngIf="parameter.register === '5035'">
                    <label>{{parameter.paramName}}</label>
                    <!-- we should use a dynamic way to render the radio values -->
                    <input type="radio" id="moduleType1" name="radio" formControlName="radio" value="0"
                        (change)="onRadioChange($event)" />
                    <label for="moduleType1">disabled</label>
                    <input type="radio" id="moduleType2" name="radio" formControlName="radio" value="1"
                        (change)="onRadioChange($event)" />
                    <label for="moduleType2">error</label>
                    <input type="radio" id="moduleType3" name="radio" formControlName="radio" value="2"
                        (change)="onRadioChange($event)" />
                    <label for="moduleType3">error + warning</label>
                    <input type="radio" id="moduleType4" name="radio" formControlName="radio" value="3"
                        (change)="onRadioChange($event)" />
                    <label for="moduleType4">error + warning + info</label>
                    <div class="last-button">
                        <ix-button class="button-line" (click)="onWrite()">Write</ix-button>
                    </div>
                </form>
            </ng-container>

            <!------------>
            <!-- STRING -->
            <!------------>
            <ng-container *ngSwitchCase="isString">

                <!-- READ PART -->
                <form>
                    <label>Actual value</label>
                    <div class="line">
                        <input type="text" [value]="parameter.value" readonly>
                        <ix-button class="button-line" (click)="onRead()">Read</ix-button>
                    </div>
                    <div class="lineValue margin-bot">
                        <p>Min: {{ parameter.min }}</p>
                        <p>Max: {{ parameter.max }}</p>
                    </div>
                </form>

                <!--WRITE PART -->
                <form>
                    <label>{{parameter.paramName}}</label>
                    <ix-input-group class="line">
                        <div class="test">
                            <input [(ngModel)]="newValueToWrite" name="newValueToWrite" placeholder="New value"
                                type="text" tabindex="1" autofocus />
                        </div>
                        <ix-button class="button-line" (click)="onWrite()">Write</ix-button>
                    </ix-input-group>
                </form>
            </ng-container>

            <!----------------------------->
            <!-- DEFAULT NUMERICAL VALUE -->
            <!----------------------------->
            <ng-container *ngSwitchDefault>
                <h4>Unit not implemented</h4>
                <!-- READ PART -->
                <form>
                    <label>Actual value</label>
                    <div class="line">
                        <input type="text" [value]="parameter.value" readonly>
                        <ix-button class="button-line" (click)="onRead()">Read</ix-button>
                    </div>
                    <div class="lineValue margin-bot">
                        <p>Min: {{ parameter.min }}</p>
                        <p>Max: {{ parameter.max }}</p>
                    </div>
                </form>

                <!--WRITE PART -->
                <form>
                    <label>New value</label>
                    <ix-input-group class="line">
                        <div class="test">
                            <input [(ngModel)]="newValueToWrite" name="newValueToWrite" placeholder="New value"
                                type="text" tabindex="1" autofocus />
                            <div class="lineValue margin-bot">
                                <p>Min: {{ parameter.min }}</p>
                                <p>Max: {{ parameter.max }}</p>
                            </div>
                        </div>
                        <ix-button class="button-line" (click)="onWrite()">Write</ix-button>
                    </ix-input-group>
                </form>
            </ng-container>

        </ng-container>
    </ng-container>


    <label>Description</label>
    <p class="read-only description">{{ parameter.description }}</p>
    <div class="last-button">
        <ix-button (click)="onClose()" variant="secondary">Close</ix-button>
    </div>
</div>