import { Component, OnInit } from "@angular/core";
import { DeviceService } from "src/services/device/device.service";
import { DeviceStatus } from "src/types/company.types";

@Component({
  selector: "app-connectivity-status",
  templateUrl: "./connectivity-status.component.html",
  styleUrls: ["./connectivity-status.component.scss"],
})
export class ConnectivityStatusComponent implements OnInit {
  totalCount = 0;
  disconnected = 0;
  active = 0;
  neverSeen = 0;
  disconnectedPercentage = 0;
  activePercentage = 0;
  neverSeenPercentage = 0;

  constructor(private deviceService: DeviceService) {}

  ngOnInit(): void {
    this.fetchDeviceData();
  }


  fetchDeviceData(): void {
    this.deviceService.getDevice().subscribe(response => {
      const deviceData = response?.data;
      this.resetCountersAndPercentages();
      if (deviceData) {
        this.processDeviceData(deviceData);
      }
    });
  }

  
  processDeviceData(data: any): void {
    this.traverseAndProcessDevices(data);
    this.calculatePercentages();
  }


  traverseAndProcessDevices(data: any): void {
    if (data.Children?.length > 0) {
      data.Children.forEach((child: any) => this.traverseAndProcessDevices(child));
    }
    this.updateDeviceStatus(data.Devices || []);
  }


  updateDeviceStatus(devices: DeviceStatus[]): void {
    devices.forEach(device => {
      switch (device.ConnectivityStatus) {
        case "DISCONNECTED":
          this.disconnected++;
          break;
        case "ACTIVE":
          this.active++;
          break;
        case "UNKNOWN":
          this.neverSeen++;
          break;
      }
    });
  }


  calculatePercentages(): void {
    this.totalCount = this.disconnected + this.active + this.neverSeen;
    if (this.totalCount > 0) {
      this.disconnectedPercentage = this.calculatePercentage(this.disconnected);
      this.activePercentage = this.calculatePercentage(this.active);
      this.neverSeenPercentage = this.calculatePercentage(this.neverSeen);
    } else {
      this.resetCountersAndPercentages();
    }
  }


  calculatePercentage(count: number): number {
    return (count / this.totalCount) * 100;
  }


  resetCountersAndPercentages(): void {
    this.disconnected = 0;
    this.active = 0;
    this.neverSeen = 0;
    this.totalCount = 0;
    this.disconnectedPercentage = 0;
    this.activePercentage = 0;
    this.neverSeenPercentage = 0;
  }
}
