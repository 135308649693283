import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { first, Observable } from 'rxjs';
import { AppState } from '../store/app.state';
import * as fromThemeSelectors from '../store/selectors/themeswitcher.selector';
import { PasswordService, ConfirmPasswordRequest } from 'src/services/password/password.service';

const EMAIL_MESSAGES = {
  success: 'The email has been successfully sent.',
  fail: 'Failed to send the email. Please try again.',
  notFound: (apiMessage?: string) => apiMessage || 'The email address was not found.',
  internalError: 'You probably tried too many times, or your account is too old. Please delete it and create a new one, then try again',
};

const PASSWORD_RESET_MESSAGES = {
  success: 'The password has been successfully reset.',
  mismatch: 'Passwords do not match. Please try again.',
  fail: 'You probably tried too many times, try again later.',
  incorrect: 'The password must be at least 8 characters long, contain at least one uppercase letter, one number, and one special character.',
  code: 'The recovery code is incorrect. Please try again.',
};

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  @Input() email: string = '';
  @Output() emailUpdated = new EventEmitter<string>();

  currentTheme$: Observable<string>;
  isCodeSent: boolean = false;
  passwordReset: boolean = false;
  showPassword: boolean = false;
  recoveryCode: string = '';
  newPassword: string = '';
  confirmPassword: string = '';

  emailStatus: 'success' | 'fail' | '' = '';
  passwordResetStatus: 'success' | 'fail' | '' = '';
  emailMessage: string = '';
  passwordResetMessage: string = '';


  isLoadingReset: boolean = false;
  isLoadingSubmit: boolean = false;

  constructor(private store: Store<AppState>, private passwordService: PasswordService) { }

  ngOnInit(): void {
    this.currentTheme$ = this.store.select(fromThemeSelectors.selectThemeToken);
  }

  handleTogglePassword(event: Event): void {
    this.showPassword = !this.showPassword;
  }
  onResetPassword(): void {
    this.isLoadingReset = true;
    try {
      this.passwordService.forgotPassword(this.email)
        .pipe(first())
        .subscribe({
          next: () => {
            this.emailStatus = 'success';
            this.emailMessage = EMAIL_MESSAGES.success;
            setTimeout(() => {
              this.isLoadingReset = false;
              this.isCodeSent = true;
            }, 2000);
          },
          error: (error) => {
            this.emailStatus = 'fail';
            this.isLoadingReset = false;

            if (error.status === 404) {
              this.emailMessage = EMAIL_MESSAGES.notFound(error.error?.message);
            } else if (error.status === 500) {
              this.emailMessage = EMAIL_MESSAGES.internalError;
            } else {
              this.emailMessage = EMAIL_MESSAGES.fail;
            }
          },
        });
    } catch (error) {
      this.isLoadingReset = false;
      console.error(error instanceof Error ? error.message : error);
    }
  }
  private validatePassword(password: string): boolean {
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordRegex.test(password);
  }

  onSubmitNewPassword(): void {
    this.isLoadingSubmit= true;
    if (!this.validatePassword(this.newPassword)) {
      this.passwordResetStatus = 'fail';
      this.passwordResetMessage = PASSWORD_RESET_MESSAGES.incorrect;
      this.isLoadingSubmit = false;
      return;
    }

    if (this.newPassword !== this.confirmPassword) {
      this.passwordResetStatus = 'fail';
      this.passwordResetMessage = PASSWORD_RESET_MESSAGES.mismatch;
      console.error('Passwords do not match');
      this.isLoadingSubmit = false;
      return;
    }

    const confirmPasswordRequest: ConfirmPasswordRequest = {
      email: this.email,
      verificationCode: this.recoveryCode,
      newPassword: this.newPassword,
    };

    this.passwordService.confirmPassword(confirmPasswordRequest)
      .pipe(first())
      .subscribe({
        next: () => {
          this.passwordResetStatus = 'success';
          this.passwordResetMessage = PASSWORD_RESET_MESSAGES.success;
          setTimeout(() => {
            this.passwordReset = true;
            this.isLoadingSubmit = false;
            this.return();
          }, 2000);
        },
        error: (error) => {
          this.passwordResetStatus = 'fail';
          this.isLoadingSubmit = false
          if (error.status === 400) {
            this.passwordResetMessage = PASSWORD_RESET_MESSAGES.code;
          } else {
            this.passwordResetMessage = PASSWORD_RESET_MESSAGES.fail;
          }

        },
      });
  }

  onCancel(): void {
    this.return();
  }
  return() {
    this.emailUpdated.emit(this.email);
  }
  resetMessage(): void {
    this.emailMessage = '';
    this.passwordResetMessage = '';
    this.emailStatus = '';
    this.passwordResetStatus = '';
  }
}
