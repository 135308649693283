<div class="modelcontainer">
    <h1>Create organisation</h1>
    <form id="companyForm" class="row1 g-3 needs-validation justify-content:center"
        [ngClass]="{ 'was-validated': wasValidated }" [formGroup]="companyForm">
        <div class="formContainer">
            <div>
                <div class="row1">
                    <label for="name">Organisation name </label>
                    <input id="name" type="text"
                        placeholder="Enter Name"
                        formControlName="name"
                        required />
                   
                    <span class="text-danger"
                        *ngIf="(companyFormControl['name'] && submitted) && companyFormControl['name'].errors?.['required']">
                        <sup>*</sup>Name is required</span>
                </div>
                
                
            </div>
        </div>
        <div class="buttons">
            <ix-button class="m-1" variant="primary"
                (click)="create($event)">Create</ix-button>
            <ix-button class="close-modal" variant="secondary"
                (click)="close()">Close</ix-button>
        </div>
    </form>
</div>

