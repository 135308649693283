<form [formGroup]="headerForm" (ngSubmit)="onSubmit($event)" no-validate>
  <div class="header">
    <div class="text">
      <span class="siemensheader">SIEMENS</span>
      <!-- <span class="siemensSubHeader">SITRANS FM MAG 8000</span> -->
    </div>
    <div class="selectBoxLatest">
      <ix-icon name="bulb-filled" size="16" class="me-2" (click)="toggleTheme()">
      </ix-icon>
      <ix-select name="ix-select-control" formControlName="language">
        <ix-select-item label="English" value="1"></ix-select-item>
        <ix-select-item label="German (not implemented)" value="2"></ix-select-item>
        <ix-select-item label="French (not implemented)" value="3"></ix-select-item>
        <ix-select-item label="Italian (not implemented)" value="4"></ix-select-item>
      </ix-select>
    </div>
  </div>
</form>