import { Component, Input, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { IxActiveModal, ModalService } from "@siemens/ix-angular";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { CompanyService } from "src/services/company/company.service";
import { UpdateUserComponent } from "../update-user/update-user.component";
import { SidenavService } from "src/services/Behavior/sidenav-assets";
import { ActivatedRoute } from "@angular/router";
import { DeviceParameterService } from "src/services/device-parameter/device-parameter.service";
import { ICellRendererParams } from "ag-grid-community";
import { catchError, firstValueFrom } from "rxjs";

@Component({
  selector: "app-button-renderer",
  templateUrl: "./button-renderer.component.html",
  styleUrls: ["./button-renderer.component.scss"],
})
export class ButtonRendererComponent implements ICellRendererAngularComp,OnInit {
  @ViewChild("customModal", { read: TemplateRef }) customModal!: TemplateRef<any>;
  @Input() rowIndex!: number;
  // customModalRef!: TemplateRef<any>;
  params: ICellRendererParams | undefined;
  label!: string;
  userData: any;
  deleteModal: any;
  selectedTab = 1;
  showDelete = true;
  FirmwareVersion: string = '';
  constructor(
    private readonly modalService: ModalService,
    private companyService: CompanyService,
    private sidenavService: SidenavService,
    private route: ActivatedRoute,
    private deviceParameterService: DeviceParameterService

  ) {}
  agInit(params: any): void {
    this.params = params;
    this.rowIndex = params.rowIndex;
    this.determineTab();
   
  }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.showDelete = data["showDelete"];
    });
  }

  determineTab() {
    if (this.params!.data.UserId) {
      this.selectedTab = 1;
    } else if (this.params!.data.DeviceId) {
      this.selectedTab = 2;
    } else if (this.params!.data.AppName) {
      this.selectedTab = 3;
    } else {
      this.selectedTab = 0;
    }
  }

  refresh(params?: any): boolean {
    this.params = params;
    return true;
  }

  public async invokeDeleteMethod($event: any) {
    if(this.selectedTab == 1){
      const instance = await this.modalService.open({
        content: this.customModal,
        centered: true,
        animation: true,
      });
  
      instance.onClose.on(() => {
        this.params!.context.componentParent.deleteUser(this.params!.node.data);
        setTimeout(() => {},5000)
        
      }
      )
    }
    else if(this.selectedTab == 2){
      const instance = await this.modalService.open({
        content: this.customModal,
        data: 'Some data',
        centered: true,
        animation: true,
      });
      
      await instance.onClose.on((a) => {
         this.params!.context.componentParent.deleteDevice(this.params!.node.data)
        
      });
    }

    else if(this.selectedTab == 3){ 
      const instance = await this.modalService.open({
        content: this.customModal,
        data: 'Some data',
        centered: true,
        animation: true,
      });
  
      await instance.onClose.on((a) => {
         this.params!.context.componentParent.deleteAppKey(this.params!.node.data.ClientId)
        
      });
    }

    else{
      const instance = await this.modalService.open({
        content: this.customModal,
        data: 'Some data',
        centered: true,
        animation: true,
      });
  
      await instance.onClose.on((a) => {
         this.params!.context.componentParent.deleteCompany(this.params!.node.data)
        
      });
    }
    


  }

  public async invokeSideNavMethod(): Promise<void> {
    let requestPending = false;
    this.sidenavService.setHomeData(true);
    const companyId = this.params?.data.CompanyId;
    const deviceId = this.params?.data.DeviceId;
  
    if (companyId && deviceId) {
      try {
        const response = await firstValueFrom(
          this.deviceParameterService.getDeviceParameters(companyId, deviceId).pipe(
            catchError(error => {
              console.error('Error in getDeviceParameters:', error);
              return []; // return a safe value
            })
          )
        );
  
  
        if (response?.data && response.data['5000']) {
          if (response.data['5000'].RequestStatus) {
            requestPending = true;
          }
          this.FirmwareVersion = response.data['5000'].Value;
          this.sidenavService.setFirmwareVersion(this.FirmwareVersion, requestPending);
        } else {
          this.FirmwareVersion = 'No firmware data in response';
          this.sidenavService.setFirmwareVersion(this.FirmwareVersion, requestPending);
          console.log('No firmware data in response');
        }
      } catch (error) {
        console.error('Error in try-catch block:', error);
      }
    } else {
      console.log('CompanyId or DeviceId is missing');
    }
  

    this.sidenavService.setDeviceData(this.params?.data);
    this.sidenavService.toggleSidenav();
  }


  public async invokeEditMethod() {
    await this.companyService
      .getUserDetail(
        this.params!.node.data.CompanyId,
        this.params!.node.data.UserId
      )
      .subscribe((data) => {
        this.userData = data.data;
        this.modalService.open({
          content: UpdateUserComponent,
          data: { data: this.userData, title: "edit" },
          title: "edit",
          size: "720",
          backdrop: false,
          centered: true,
          animation: true,
        });
      });
  }
}
