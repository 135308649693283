import { createSelector } from '@ngrx/store';
import { AppState } from '../app.state';

export const selectAuthState = (state: AppState) => state.auth;

export const selectToken = createSelector(
  selectAuthState,
  (state) => state.token
);

export const selectICCID = createSelector(
  selectAuthState,
  (state) => state.iccid
);

export const selectIMEI = createSelector(
  selectAuthState,
  (state) => state.imei
);
