import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { themeSwitcher } from "@siemens/ix";
import { Store } from "@ngrx/store";
import * as ThemeActions from "../store/actions/themeswitcher.actions";

@Component({
  selector: "app-appheader",
  templateUrl: "./appheader.component.html",
  styleUrls: ["./appheader.component.scss"],
})
export class AppheaderComponent implements OnInit {
  headerForm: any;
  constructor(public store: Store) {
    this.headerForm = new FormGroup({
      language: new FormControl(1),
    });
    this.store.dispatch(
      ThemeActions.themeSuccess({
        currentTheme: themeSwitcher.getCurrentTheme(),
      })
    );
  }

  ngOnInit(): void {}

  onSubmit(event: any) {
    // console.log("eve", event);
  }
  toggleTheme() {
    themeSwitcher.toggleMode();
    this.store.dispatch(
      ThemeActions.themeSuccess({
        currentTheme: themeSwitcher.getCurrentTheme(),
      })
    );
  }
}
