import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, of, Subscription, take } from 'rxjs';
import { SidenavService } from 'src/services/Behavior/sidenav-assets';
import * as fromThemeSelectors from "../../app/store/selectors/themeswitcher.selector";
import { AppState } from '../store/app.state';
import { IxActiveModal, ModalService, ToastService, toast } from "@siemens/ix-angular";
import { DeviceService } from 'src/services/device/device.service';
import { EmailService } from 'src/services/Ftp/email/email.service';
import { FtpService } from 'src/services/Ftp/ftps/ftp.service';
import { FTP } from 'src/types/ftp.types';
import { DeviceParameterService } from 'src/services/device-parameter/device-parameter.service';

@Component({
  selector: 'app-sidenav-assets',
  templateUrl: './sidenav-assets.component.html',
  styleUrls: ['./sidenav-assets.component.scss']
})
export class SidenavAssetsComponent implements OnInit {
  deviceData: any;
  isUpdatable: boolean = false;
  firmwareVersion: string = '';
  requestPending: boolean = false;
  CompanyName: string = '';
  CompanyId: string = '';
  emails: string[] = [];
  NewEmails: string[] = [];
  originalEmails: string[] = [];
  FUOTALatest: string = '';
  FUOTAstatus: string = '';
  exceeded: boolean = false;
  loading: boolean = false;
  FUOTAupdateAvailable: boolean = false;
  instance: any;
  fuotaStatus: string = '';
  isLoadingUpdate: boolean = false;
  ftpData: FTP = { Host: '', Port: 0, Username: '', Password: '', destination_path: '', FTP_Type: '' };
  originalFtpData: FTP | undefined;
  @ViewChild('customModal') customModalRef!: TemplateRef<any>;
  @ViewChild('customModalFTP') customModalFtpRef!: TemplateRef<any>;
  @ViewChild('customModalEmail') customModalEmailRef!: TemplateRef<any>;

  private subscriptions: Subscription = new Subscription();

  constructor(private store: Store<AppState>, private sidenavService: SidenavService,
    private readonly modalService: ModalService, private apiService: DeviceService,
    private emailService: EmailService, private ftpService: FtpService, private toastService: ToastService,
    private deviceParameterService: DeviceParameterService
  ) {

  }

  currentTheme: any = "";

  data = {
    firstName: '',
    lastName: '',
    userName: '',
  };

  value = '1';

  ngOnInit(): void {
    this.initializeTheme();
    this.subscribeToDeviceData();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private initializeTheme(): void {
    this.subscriptions.add(
      this.store.select(fromThemeSelectors.selectThemeToken).subscribe((token) => {
        this.currentTheme = token || "theme-brand-light";
      })
    );
  }

  private subscribeToDeviceData(): void {
    this.subscriptions.add(
      this.sidenavService.deviceData$.subscribe((data) => {
        this.deviceData = data;
        this.updateDeviceInfo();
      })
    );
  }

  private updateDeviceInfo(): void {
    this.subscriptions.add(
      this.sidenavService.firmwareVersion$.subscribe((version) => {
        this.firmwareVersion = version || "No firmware version available";
      })
    );

    this.subscriptions.add(
      this.sidenavService.requestPending$.subscribe((pending) => {
        this.requestPending = pending;
      })
    );

    this.subscriptions.add(
      this.apiService.getDevice().subscribe((data: any) => {
        this.CompanyName = data.data.CompanyName;
      })
    );

    this.subscriptions.add(
      this.apiService.getFUOTAavailable().subscribe((data: any) => { })
    );

    this.subscriptions.add(
      this.apiService.getFUOTALatest().subscribe((data: any) => {
        this.FUOTALatest = data.data.parameters_list.module["4G"];
        this.FUOTAupdateAvailable = this.FUOTALatest !== this.firmwareVersion;
      })
    );

    if (this.deviceData) {
      this.subscriptions.add(
        this.apiService.getFuotaStatus(this.deviceData.CompanyId, this.deviceData.DeviceId).pipe(
          catchError((error) => {
            console.error('Error fetching FUOTA status:', error);
            this.fuotaStatus = 'Error';
            return of(null);
          })
        ).subscribe((data: any) => {
          if (data) {
            this.fuotaStatus = data.data.fuota_status;
          }
        })
      );
    }
    this.CheckfirmwareUpdatable();
  }



  /// FIRMWARE FUNCTIONS //////////////

  checkFirmware() {
    const registerAsNumber = "5000";
    if (this.deviceData.CompanyId && this.deviceData.DeviceId) {
      this.deviceParameterService.ReadDeviceParameter(this.deviceData.CompanyId, this.deviceData.DeviceId, registerAsNumber).subscribe({
        next: (response) => {
          this.toastService.setPosition("top-right");
          if (response.message && response.message.includes("pending state")) {
            this.toastService.show({
              type: "warning",
              message: "Firmware version read is already in a pending state.",
            });
          } else {
            this.toastService.show({
              type: "success",
              message: "Successfully sent the message to read Firmware version value",
            });
          }
        },
        error: (error) => {
          console.error('Error fetching data:', error);
          this.toastService.setPosition("top-right");
          this.toastService.show({
            type: "error",
            message: "Error sending request, please try again later.",
          });
        }
      }
      );
    }
  }

  CheckfirmwareUpdatable() {
    if (this.firmwareVersion) {
      const versionParts = this.firmwareVersion.split('.').map(Number);
      const minUpdatableVersion = [2, 0, 0];

      for (let i = 0; i < minUpdatableVersion.length; i++) {
        if (versionParts[i] < minUpdatableVersion[i]) {
          this.isUpdatable = false;
          break;
        } else if (versionParts[i] >= minUpdatableVersion[i]) {
          this.isUpdatable = true;
          break;
        }
      }
    } else {
      console.log('No firmware version provided');
    }
  }



  FirmwareUpdate(modal) {
    if (this.deviceData.CompanyId && this.deviceData.DeviceId) {
      this.isLoadingUpdate = true;
      this.apiService.triggerFuota(this.deviceData.CompanyId, this.deviceData.DeviceId).subscribe({
        next: (data) => {
          this.toastService.setPosition("top-right");
          this.toastService.show({
            type: "success",
            message: "Successfully triggered firmware update",
          });
          modal.close();
          this.isLoadingUpdate = false;
        },
        error: (error) => {
          console.error('Error triggering firmware update:', error);
          this.toastService.setPosition("top-right");
          this.toastService.show({
            type: "error",
            message: "Error triggering firmware update, please try again",
          });
          modal.close();
          this.isLoadingUpdate = false;
        },
        complete: () => {
          this.isLoadingUpdate = false;
        }
      });
    }
  }

  //////////// EMAILS FUNCTIONS //////////////

  addEntry() {
    if (this.emails.length + this.NewEmails.length < 5) {
      this.NewEmails.push('');
    } else {
      console.log("you can't add more than 5 emails");
      this.exceeded = true
    }
  }

  saveEmails() {
    if (this.NewEmails.length === 0) {
      return;
    }
    this.emailService.AddEmailAddresses(this.NewEmails, this.deviceData.CompanyId, this.deviceData.DeviceId).pipe(take(1)).subscribe({
      next: (data) => {
        console.log(data);
        this.toastService.setPosition("top-right");
        this.toastService.show({
          type: "success",
          message: "Successfully Added Email Addresses",
        });
      },
      error: (error) => {
        console.error("Error in adding email addresses", error);
        this.toastService.setPosition("top-right");
        this.toastService.show({
          type: "error",
          message: "Error in adding email addresses, please try again",
        });

      }
    })
  }

  updateEmails() {
    for (let i = 0; i < this.emails.length; i++) {
      if (this.emails[i] !== this.originalEmails[i]) {
        console.log('Original email:', this.originalEmails[i]);
        console.log('Updating email:', this.emails[i]);
        this.emailService.UpdateEmailAddress(this.originalEmails[i], this.emails[i], this.deviceData.CompanyId, this.deviceData.DeviceId).pipe(take(1)).subscribe({
          next: (data) => {
            console.log(data);
            console.log('Email updated successfully');
            this.toastService.setPosition("top-right");
            this.toastService.show({
              type: "success",
              message: "Successfully updated Email Addresses",
            });
          },
          error: (error) => {
            console.error('Failed to update email:', error);
            this.toastService.setPosition("top-right");
            this.toastService.show({
              type: "error",
              message: "Error in updating email addresses, please try again",
            });
          }
        });
      }
    }
  }

  DeleteEmail(index: number) {
    console.log(index);
    let email = this.emails[index];
    console.log(email);
    this.emailService.DeleteEmailAddresses(email, this.deviceData.CompanyId, this.deviceData.DeviceId).pipe(take(1)).subscribe({
      next: (data) => {
        console.log(data);
        this.toastService.setPosition("top-right");
        this.toastService.show({
          type: "success",
          message: "Successfully Deleted Email Addresses",
        });
      },
      error: (error) => {
        console.error("Error in deleting email addresses", error);
        this.toastService.setPosition("top-right");
        this.toastService.show({
          type: "error",
          message: "Error in deleting email addresses, please try again",
        });


      }
    })


  }

  trackByFn(index: number): number {
    return index;
  }
  //////////// END EMAILS FUNCTIONS //////////////

  //////////// FTP FUNCTIONS //////////////

  saveFtp() {
    if (this.ftpData === this.originalFtpData) {
      return;
    }
    this.ftpService.AddFTPConfiguration(this.ftpData!, this.deviceData.CompanyId, this.deviceData.DeviceId)
      .pipe(take(1))
      .subscribe({
        next: (data) => {
          console.log(data);
          this.toastService.setPosition("top-right");
          this.toastService.show({
            type: "success",
            message: "Successfully Added Ftp configuration",
          });
        },
        error: (error) => {
          console.error("Error in adding FTP configuration", error);
          this.toastService.setPosition("top-right");
          this.toastService.show({
            type: "error",
            message: "Error in adding FTP configuration, please try again",
          });
        }
      });
  }
  updateFtp() {
    this.ftpService.UpdateFTPConfiguration(this.ftpData?.FTP_Type!, this.ftpData!, this.deviceData.CompanyId, this.deviceData.DeviceId).pipe(take(1)).subscribe({
      next: (data) => {
        console.log(data);
        console.log('FTP updated successfully');
        this.toastService.setPosition("top-right");
        this.toastService.show({
          type: "success",
          message: "Successfully updated ftp Configuration",
        });
      },
      error: (error) => {
        console.error('Failed to update FTP:', error);
        this.toastService.setPosition("top-right");
        this.toastService.show({
          type: "error",
          message: "Error in updating FTP configuration, please try again",
        });
      }
    });
  }

  deleteFtp() {
    this.ftpService.DeleteFTPConfiguration(this.ftpData?.FTP_Type!, this.deviceData.CompanyId, this.deviceData.DeviceId).pipe(take(1)).subscribe({
      next: (data) => {
        console.log(data);
        this.toastService.setPosition("top-right");
        this.toastService.show({
          type: "success",
          message: "Successfully Deleted FTP configuration",
        });
      },
      error: (error) => {
        console.error("Error in deleting FTP configuration", error);
        this.toastService.setPosition("top-right");
        this.toastService.show({
          type: "error",
          message: "Error in deleting FTP configuration, please try again",
        });
      }
    });
  }



  /////////// END FTP FUNCTIONS //////////////

  async openFtpModal() {
    this.loading = true;
    await this.ftpService.GetFTPConfiguration(this.deviceData.CompanyId, this.deviceData.DeviceId).subscribe({
      next: (data) => {
        console.log(data);
        this.ftpData = data.data.ftp[0];
        this.originalFtpData = { ...this.ftpData! };
        console.log(data.data.ftp[0]);
        this.loading = false;
      },
      error: (error) => {
        this.ftpData = { Host: '', Port: 0, Username: '', Password: '', destination_path: '', FTP_Type: '' };
        this.originalFtpData = { Host: '', Port: 0, Username: '', Password: '', destination_path: '', FTP_Type: '' };
        console.error(error.error.message);
        this.loading = false;
      }
    });

    const instance = await this.modalService.open({
      content: this.customModalFtpRef,
      size: '600',
      backdrop: true,
      centered: true,
      animation: true,
    });

    instance.onClose.on((data) => {
      if (data === "save") {
        if (JSON.stringify(this.originalFtpData) === JSON.stringify({ Host: '', Port: 0, Username: '', Password: '', destination_path: '', FTP_Type: '' })) {
          this.saveFtp();
        } else if (JSON.stringify(this.ftpData) !== JSON.stringify(this.originalFtpData)) {
          this.updateFtp();
        }
      } else if (data === "delete") {
        this.deleteFtp();
      }
      console.log("FTP Modal Closed");
    });
  }

  async openEmailModal() {
    this.exceeded = false;
    this.loading = true;
    await this.emailService.GetEmailAdresses(this.deviceData.CompanyId, this.deviceData.DeviceId).subscribe({
      next: (data) => {
        console.log(data);
        console.log(this.loading)
        this.emails = data.data.emails;
        this.originalEmails = [...this.emails];
        this.loading = false;
        console.log(this.loading)
      },
      error: (error) => {
        console.error(error.error.message);
        this.emails = [];
        this.loading = false;

      }
    });
    const instance = await this.modalService.open({
      content: this.customModalEmailRef,
      size: '600',
      backdrop: true,
      centered: true,
      animation: true,
    });

    instance.onClose.on((data) => {
      if (data === "save") {
        this.updateEmails();
        this.saveEmails();
      } else if (typeof data === 'number') {
        this.DeleteEmail(data);
      }
      this.NewEmails = [];
      console.log("Email Modal Closed");
      this.loading = false;
    });
  }

  async openModal() {
    this.instance = await this.modalService.open({
      content: this.customModalRef,
      size: '600',
      backdrop: true,
      centered: true,
      animation: true,
    });
  }

}
