import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

interface ApiResponse {
    message: string;
}

export interface ConfirmPasswordRequest {
    email: string;
    verificationCode: string;
    newPassword: string;
}

export interface ChangePasswordRequest {
    username: string;
    oldPassword: string;
    newPassword: string;
}

@Injectable({
    providedIn: 'root',
})
export class PasswordService {
    private readonly forgotPasswordUrl = `${environment.apiUrl}/forgot-password`;
    private readonly confirmPasswordUrl = `${environment.apiUrl}/forgot-password/confirm`;
    private readonly changePasswordUrl = `${environment.apiUrl}/change-password`;

    constructor(private readonly http: HttpClient) { }

    public forgotPassword(email: string): Observable<ApiResponse> {
        return this.http.post<ApiResponse>(this.forgotPasswordUrl, { email });
    }

    public confirmPassword(request: ConfirmPasswordRequest): Observable<ApiResponse> {
        const body = {
            email: request.email,
            verification_code: request.verificationCode,
            new_password: request.newPassword,
        };
        return this.http.post<ApiResponse>(this.confirmPasswordUrl, body);
    }

    public changePassword(request: ChangePasswordRequest): Observable<ApiResponse> {
        const body = {
            username: request.username,
            old_password: request.oldPassword,
            new_password: request.newPassword,
        };
        return this.http.patch<ApiResponse>(this.changePasswordUrl, body);
    }
}
