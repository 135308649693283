import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class DeviceParameterService {
  constructor(private http: HttpClient) { }

  getDeviceParameters(companyId: string, deviceId: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('status', 'True');

    const url = `${environment.apiUrl}/companies/${companyId}/devices/${deviceId}/parameters/module`;
    return this.http.get<any>(url, { params }).pipe(
      catchError((error) => {
        console.error('Error while fetching device parameters', error);
        return throwError(() => new Error('Error while fetching device parameters'));
      })
    );
  }

  getHostDeviceParameters(companyId: string, deviceId: string,parameter_type : string): Observable<any> {
    let params = new HttpParams();
    params = params.set('status', 'True');
    params = params.set('parameter_type', parameter_type);

    const url = `${environment.apiUrl}/companies/${companyId}/devices/${deviceId}/parameters/host`;
    return this.http.get<any>(url, { params }).pipe(
      catchError((error) => {
        console.error('Error while fetching host device parameters', error);
        return throwError(() => new Error('Error while fetching host device parameters'));
      })
    );
  }

  /* Function to request device parameter with register number passed as URL parameter */
  ReadDeviceParameter(companyId: string, deviceId: string, registerNumber: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('register_number', registerNumber);


    const url = `${environment.apiUrl}/companies/${companyId}/devices/${deviceId}/parameters/read`;

    return this.http.post<any>(url, {}, {
      params: params,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    }).pipe(
      catchError((error) => {
        console.error('Error while fetching device parameter', error);
        return throwError(() => new Error('Error while fetching device parameter'));
      })
    );
  }

  WriteDeviceParameter(companyId: string, deviceId: string, registerNumber: string, registerValue: string): Observable<any> {
  let params = new HttpParams()
    .set('register_number', registerNumber)
    .set('register_value', registerValue);

  const url = `${environment.apiUrl}/companies/${companyId}/devices/${deviceId}/parameters/write`;

  return this.http.post<any>(url, {}, {
    params: params,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  }).pipe(
    catchError((error) => {
      console.error('Error while writing device parameter', error);
      return throwError(() => new Error('Error while writing device parameter'));
    })
  );
}

  ReadDeviceHostParameter(companyId: string, deviceId: string, parameterType: string, parameterNumber: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('parameter_type', parameterType);
    params = params.set('register_number', parameterNumber);

    const url = `${environment.apiUrl}/companies/${companyId}/devices/${deviceId}/host/read`;

    return this.http.post<any>(url, {}, {
      params: params,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    }).pipe(
      catchError((error) => {
        console.error('Error while fetching device parameter', error);
        return throwError(() => new Error('Error while fetching device parameter'));
      })
    );
  }


  WriteDeviceHostParameter(companyId: string, deviceId: string, parameterType: string, parameterNumber: string, parameterValue: string): Observable<any> {
    let params = new HttpParams()
      .set('parameter_type', parameterType)
      .set('register_number', parameterNumber)
      .set('register_value', parameterValue);

    const url = `${environment.apiUrl}/companies/${companyId}/devices/${deviceId}/host/write`;

    return this.http.post<any>(url, {}, {
      params: params,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    }).pipe(
      catchError((error) => {
        console.error('Error while writing device host parameter', error);
        return throwError(() => new Error('Error while writing device host parameter'));
      })
    );
  }

  ReadQueueStatus(companyId: string, deviceId: string): Observable<any> {
    const url = `${environment.apiUrl}/companies/${companyId}/devices/${deviceId}/queue`;

    return this.http.get<any>(url).pipe(
      catchError((error) => {
        console.error('Error while fetching queue status', error);
        return throwError(() => new Error('Error while fetching queue status'));
      })
    );
  }
}
