import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { Store } from "@ngrx/store";
import { ToastService, IxActiveModal } from "@siemens/ix-angular";
import { AppState } from "src/app/store/app.state";
import { CompanyService } from "src/services/company/company.service";
import * as fromCompanySelector from "src/app/store/selectors/company.selector";

@Component({
  selector: "app-create-user",
  templateUrl: "./create-user.component.html",
  styleUrls: ["./create-user.component.scss"],
})
export class CreateUserComponent implements OnInit {
  companyId: string = "";
  value = "";
  showPassword: boolean = false;
  wasValidated = false;
  userForm!: FormGroup;
  submitted = false;
  errorMessage: string = "";

  user: User = {
    email: '',
    password: '',
    first_name: '',
    last_name: '',
    society: '',
    mobile: '',
    country_code: '',
    language: '',
    title: '',
    role: ''
  };

  constructor(
    private toastService: ToastService,
    private readonly activeModal: IxActiveModal,
    private fb: FormBuilder,
    private companyService: CompanyService,
    private store: Store<AppState>
  ) {
    this.store.select(fromCompanySelector.selectCompanyId).subscribe((data) => {
      this.companyId = data!;
    });

  }

  onLanguageChange(event: any) {
    this.user.language = event.detail
    console.log('Language changed:', this.user);
  }

  onTitleChange(event: any) {
    this.user.title = event.detail;
    console.log('Title changed:', this.user);
  }

  onUserRoleChange(event: any) {
    this.user.role = event.detail;
    console.log('User role changed:', this.user);
  }
  ngOnInit(): void {
    this.userForm = this.fb.group({
      firstName: [
        "",
        {
          validators: [Validators.required],
        },
      ],
      lastName: new FormControl("", {
        validators: [Validators.required],
      }),
      title: new FormControl("", {
        validators: [Validators.required],
      }),
      userSociety: new FormControl("", {
        validators: [Validators.required],
      }),
      email: new FormControl("", {
        validators: [
          Validators.required,
          Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
        ],
      }),
      confirmEmail: new FormControl("", {
        validators: [
          Validators.required,
          Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
        ],
      }),
      password: new FormControl("", {
        validators: [
          Validators.required,
          Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)
        ],
      }),
      confirmPassword: new FormControl("", {
        validators: [
          Validators.required,
          Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)
        ],
      }),
      countryCode: new FormControl("", {
        validators: [
          Validators.required,
          Validators.pattern(/^\s*\+\s*\d{1,3}\s*$/)
        ],
      }),
      mobilePhone: new FormControl("", {
        validators: [
          Validators.required,
          Validators.pattern(/^\s*(\d\s*){10}$/)
        ],
      }),
      language: new FormControl("", {
        validators: [Validators.required],
      }),
      userRole: new FormControl("", {
        validators: [Validators.required],
      }),
    });
  }

  get userFormControl() {
    return this.userForm.controls;
  }
  create(event: any) {
    event.preventDefault();
    this.wasValidated = true;
    this.submitted = true;
    this.user.email = this.userForm.get("email")?.value;
    this.user.password = this.userForm.get("password")?.value;
    this.user.first_name = this.userForm.get("firstName")?.value;
    this.user.last_name = this.userForm.get("lastName")?.value;
    this.user.society = this.userForm.get("userSociety")?.value;

    this.user.mobile = this.userForm.get("mobilePhone")?.value.replace(/\s+/g, '');
    this.user.country_code = this.userForm.get("countryCode")?.value.replace(/\s+/g, '');

    if (!this.ValidateUserData(this.userForm)) return;
    if (!this.ValidateUserData(this.userForm)) return;

    try {
      this.companyService
        .createUser(this.companyId, this.user)
        .subscribe({
          next: (data) => {
            if (data.data) {
              this.toastService.setPosition('top-right');
              this.toastService.show({
                type: 'success',
                message: data.message,
              });
              this.activeModal.close(this.user);
            } else {
              this.toastService.show({
                type: 'error',
                message: data.message,
              });
            }
          },
          error: (error: HttpErrorResponse) => {
            if (error.status >= 400 && error.status < 500) {
              const errorMessage = error.error.message || 'An error occurred.';
              // TODO remove this when the backend is fixed
              console.log("remove this when the backend is fixed");
              if (errorMessage === 'The username already exists.') {
                this.errorMessage = `The email already exists (${this.user.email}).`;
              } else {
                this.errorMessage = errorMessage;
              }
            } else {
              this.errorMessage = 'A server error occurred. Please try again later.';
            }
          },
          complete: () => {
            // Optionally handle when the observable completes
            console.log('Request completed.');
          },
        });
    } catch (error) {
      this.handleErrorWhileCreatingUser(error);
    }


  }

  handleErrorWhileCreatingUser = (error: any) => {
    this.activeModal.close("My close response");
    this.toastService.setPosition("bottom-right");
    this.toastService.show({
      type: "error",
      message: "An error occurred while creating the user",
    });
  };

  handleTogglePassword(event: any) {
    event.preventDefault();
    this.showPassword = !this.showPassword;
  }

  formIsValid = (): boolean => {
    const userValues = Object.values(this.user);

    const allFieldsFilled = userValues.every((value) => value !== '');

    if (!allFieldsFilled) {
      this.errorMessage = "All fields must be filled";
      return false;
    }

    return true;
  };

  ValidateUserData = (userData: FormGroup): boolean => {
    if (!this.formIsValid()) {
      return false;
    }

    this.errorMessage = "";

    if (!this.isEmailValid(userData)) {
      return false;
    }

    if (!this.isPasswordValid(userData)) {
      return false;
    }

    if (!this.isPhoneNumberValid(this.user.mobile)) {
      return false;
    }

    if (!this.isCountryCodeValid(this.user.country_code)) {
      return false;
    }

    return true;
  };

  private isEmailValid(userData: FormGroup): boolean {
    const email = userData.get("email")?.value;
    const confirmEmail = userData.get("confirmEmail")?.value;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (email && !emailRegex.test(email)) {
      this.errorMessage = "The email address must contain a single '@' and no special characters.";
      return false;
    }

    if (email !== "" && email !== confirmEmail) {
      this.errorMessage = "The email addresses do not match";
      return false;
    }

    return true;
  }

  private isPasswordValid(userData: FormGroup): boolean {
    const password = userData.get("password")?.value;
    const confirmPassword = userData.get("confirmPassword")?.value;

    let errorMessage = "Password must have: ";
    const requirements = [];
    const minLength = 8;
    const hasLowercase = /[a-z]/.test(password);
    const hasUppercase = /[A-Z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[@$!%*?&]/.test(password);

    if (!password || password.length < minLength) {
      requirements.push(`at least ${minLength} characters`);
    }
    if (!hasLowercase) {
      requirements.push("1 lowercase letter");
    }
    if (!hasUppercase) {
      requirements.push("1 uppercase letter");
    }
    if (!hasNumber) {
      requirements.push("1 number");
    }
    if (!hasSpecialChar) {
      requirements.push("1 special character (@$!%*?&)");
    }

    if (requirements.length > 0) {
      this.errorMessage = errorMessage + requirements.join(', ');
      return false;
    }

    if (password !== "" && password !== confirmPassword) {
      this.errorMessage = "The passwords do not match";
      return false;
    }

    return true;
  }

  private isPhoneNumberValid(phoneNumber: string): boolean {
    const phoneRegex = /^\d{10}$/;

    if (!phoneRegex.test(phoneNumber)) {
      this.errorMessage = "The phone number is not valid (10 digits)";
      return false;
    }

    return true;
  }

  private isCountryCodeValid(countryCode: string): boolean {
    const countryCodeRegex = /^\+\d{1,3}$/;

    if (!countryCodeRegex.test(countryCode)) {
      this.errorMessage = "The country code is not valid. It should start with a '+' followed by 1 to 3 digits.";
      return false;
    }

    return true;
  }


  close() {
    this.activeModal.close("My close response");
  }
}

export interface User {
  email: string;
  password: string;
  first_name: string;
  last_name: string;
  society: string;
  mobile: string;
  country_code: string;
  language: string;
  title: string;
  role: string;
}
