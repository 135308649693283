import { createReducer, on } from '@ngrx/store';
import { initialState } from '../models/company.state';
import * as CompanyActions from '../actions/company.actions';



export const companyReducer = createReducer(
  initialState,
  on(CompanyActions.company, (state, { selectedCompany }) => ({ ...state, selectedCompany })),
  on(CompanyActions.device, (state, { selectedDevice }) => ({ ...state, selectedDevice })),
  on(CompanyActions.reset, (state) => ({ ...state, selectedCompany: undefined, selectedDevice: undefined })),
  on(CompanyActions.companyId, (state, { companyId }) => ({ ...state, companyId })),
  on(CompanyActions.parentCompanyId, (state, { parentCompanyId }) => ({ ...state, parentCompanyId })),
  on(CompanyActions.userScope, (state, { userScope }) => ({ ...state, userScope }))
);