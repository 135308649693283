import { Component, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromCompanySelector from '../../app/store/selectors/company.selector';
import { AppState } from '../store/app.state';
import { DeviceService } from 'src/services/device/device.service';
import { Subject, takeUntil, switchMap, catchError, of, tap, firstValueFrom } from 'rxjs';
import { CompanyService } from 'src/services/company/company.service';
import { DeviceParameterService } from 'src/services/device-parameter/device-parameter.service'
import { SidenavService } from 'src/services/Behavior/sidenav-assets';
import { Device, DeviceStatus } from 'src/types/company.types';


@Component({
  selector: 'app-device-properties',
  templateUrl: './device-properties.component.html',
  styleUrls: ['./device-properties.component.scss']
})
export class DevicePropertiesComponent implements OnInit {

  private readonly NUM_DECIMALS = 3;

  queueCount: number | null = null;
  processInfoKeys: string[] = [];
  ICCID: string = "";
  SerialNumber: string = "";
  TotalizerUnit: string = "";
  FlowUnit: string = "";
  parentCompany: string = "";
  grandParentCompany: string = "";
  ConnectionStatus: string = "";
  Time: string = "";
  FirmwareVersion: string = '';
  itemSelected: number | null = 0;
  companyId: string | null | undefined = null;

  data: Device | undefined = undefined;
  lotsOfTabs = [
    'All Parameters',
    'Product ID',
    'Operation',
    'Failure',
    'Measurement Setup',
    'I/O and Com.setup',
    'Service',
    'Basic Logger 1',
    'Basic Logger 2',
    'Advanced Informations',
  ]

  ProcessInfo: { [key: string]: number | string } = {
    Flowrate: "N/A",
    Totalizer1: "N/A",
    Totalizer2: "N/A",
    Totalizer3: "N/A",
    "Battery remaining": "N/A",
  }


  readonly = [5000, 5005, 5006, 5007, 5009, 5011, 5012, 5014, 5026]

  private destroy$ = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    private DeviceService: DeviceService,
    private companyService: CompanyService,
    private sidenavService: SidenavService,
    private apiService: DeviceService,
    private DeviceParameterService: DeviceParameterService

  ) { }

  ngOnInit(): void {
    this.processInfoKeys = Object.keys(this.ProcessInfo);
    this.store.select('company').subscribe((data) => {
      if (data.selectedDevice) {
        this.data = data.selectedDevice;
        this.fetchParentCompanyInfo(data.parentCompanyId);
      }
    })
    this.store.select(fromCompanySelector.selectDevice).pipe(
      takeUntil(this.destroy$),
      switchMap(data => this.setDeviceInfo(data))
    ).subscribe({
      next: data => {
        this.setProcessInfo(data);
        this.fetchQueueCount();
        this.fetchCompanyInfo(this.companyId);
      },
      error: error => {
        console.error("Error in fetching device data", error);
      }
    });
  
    this.store.select(fromCompanySelector.selectCompanyId).pipe(
      takeUntil(this.destroy$)
    ).subscribe({
      next: companyId => {
        this.companyId = companyId;
      },
      error: error => {
        console.error("Error in fetching company ID", error);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  fetchCompanyInfo(companyId: string) {
    this.companyService.getCompanyDetails(companyId).subscribe({
      next: (response) => {
        this.parentCompany = response.data.CompanyName;
      },
      error: (error) => {
        console.error('Error while fetching company data:', error);
      }
    });
  }

  fetchParentCompanyInfo(ParentCompanyId: string) {
    this.companyService.getCompanyDetails(ParentCompanyId).subscribe({
      next: (response) => {
        this.grandParentCompany = response.data.CompanyName;
      },
      error: (error) => {
        console.error('Error while fetching company data:', error);
      }
    });
  }

  fetchQueueCount(): void {
    const companyId = this.companyId;
    const deviceId = this.ICCID;
    if (companyId && deviceId) {
      this.queueCount = null;
      this.DeviceParameterService.ReadQueueStatus(companyId, deviceId)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (response) => {
            this.queueCount = response.data.queueCount;
          },
          error: (error) => {
            console.error('Error while fetching queue status:', error);
            this.queueCount = null;
          }
        });
    } else {
      console.log(this.queueCount);
    }
  }


  setProcessInfo(data: any) {
    if (data?.data.Values.length > 0) {
      let recentData = data.data.Values[data.data.Values.length - 1];
      this.ProcessInfo['Flowrate'] = parseFloat(recentData.FlowValue).toFixed(this.NUM_DECIMALS);
      this.ProcessInfo['Totalizer1'] = parseFloat(recentData.Totalizer1).toFixed(this.NUM_DECIMALS);
      this.ProcessInfo['Totalizer2'] = parseFloat(recentData.Totalizer2).toFixed(this.NUM_DECIMALS);
      this.ProcessInfo['Totalizer3'] = parseFloat(recentData.Totalizer3).toFixed(this.NUM_DECIMALS);
      this.ProcessInfo['Battery remaining'] = recentData.BatteryCapacity;
      this.TotalizerUnit = recentData.TotalizerUnit;


      let monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];

      let date = new Date(recentData.TransmittedDatetime);

      let formattedDate = monthNames[date.getUTCMonth()] + ' ' +
        date.getUTCDate() + ' ' +
        date.getUTCFullYear() + ', ' +
        date.getUTCHours() + ':' +
        date.getUTCMinutes() + ' ' +
        (date.getUTCHours() >= 12 ? 'PM' : 'AM') + ' GMT';

      this.Time = formattedDate;

    } else {
      this.ProcessInfo['Flowrate'] = "N/A";
      this.ProcessInfo['Totalizer1'] = "N/A";
      this.ProcessInfo['Totalizer2'] = "N/A";
      this.ProcessInfo['Totalizer3'] = "N/A";
      this.ProcessInfo['Battery remaining'] = "N/A";

      this.Time = "";
    }
  }
setDeviceInfo(data: any) {
    if (data) {
      this.ICCID = data.DeviceId;
      this.SerialNumber = data.SerialNumber;
      this.ConnectionStatus = data.ConnectivityStatus;
      this.companyId = data.CompanyId;
    }
    return this.DeviceService.getProcessData().pipe(
      catchError(error => {
        console.error("Error in fetching device data", error);
        return of(null); // return a safe value
      }),
      takeUntil(this.destroy$)
    );
  }


  onItemClick(item: number) {
    this.itemSelected = item;
  }

  onSelectedChanged(index: any) {
    this.itemSelected = index;
  }

  // function to open the side nav on click of the config icon is clicked
  public async invokeSideNavMethod(): Promise<void> {
    let requestPending = false;
    this.sidenavService.setHomeData(false);

  
    if (this.companyId && this.ICCID) {
      try {
        const response = await firstValueFrom(
          this.DeviceParameterService.getDeviceParameters(this.companyId, this.ICCID).pipe(
            catchError(error => {
              console.error('Error in getDeviceParameters:', error);
              return []; // return a safe value
            })
          )
        );

  
        if (response?.data && response.data['5000']) {
          this.FirmwareVersion = response.data['5000'].Value;
          if (response.data['5000'].RequestStatus) {
            requestPending = true;
          }
          this.sidenavService.setFirmwareVersion(this.FirmwareVersion, requestPending);
        } else {
          console.log('No firmware data in response');
        }
      } catch (error) {
        console.error('Error in try-catch block:', error);
      }
    } else {
      console.log('CompanyId or ICCID is missing');
    }
    this.sidenavService.setDeviceData(this.data);
    this.sidenavService.toggleSidenav();
}
  selectedTab = 1;
  changeTab(tabIndex: number) {
    this.selectedTab = tabIndex;
  }
}
