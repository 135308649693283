import { createSelector } from '@ngrx/store';
import { AppState } from '../app.state';

export const selectCompanyState = (state: AppState) => state.company;

export const selectToken = createSelector(
    selectCompanyState,
    (state) => state.selectedCompany
);
export const selectDevice = createSelector(
    selectCompanyState,
    (state) => state.selectedDevice
);

export const selectCompanyId = createSelector(
    selectCompanyState,
    (state) => state.companyId
);

export const selectParentCompanyId = createSelector(
    selectCompanyState,
    (state) => state.parentCompanyId
);

export const selectUserScope = createSelector(
    selectCompanyState,
    (state) => state.userScope
);
