<div class="piechart" *ngIf="showComponent; else loading">
    <div class="chart-wrapper">
        <div echarts [options]="option" class="pie" #chart></div>
        <ix-button class="m-1 refresh-button" id="button" variant="secondary ghost" (click)="refreshComponent()"><ix-icon name="refresh"></ix-icon></ix-button>
    </div>
</div>

<ng-template #loading>
    <div class="loading">
        <ix-spinner class="spinner" size="large"></ix-spinner>
    </div>
</ng-template>