import { Company, Device } from "src/types/company.types";

export interface CompanyState {
    selectedCompany?: Company;
    selectedDevice?: Device;
    companyId?: string;
    parentCompanyId?: string;
    userScope?: string;
}


export const initialState: CompanyState = {
    selectedCompany: undefined,
    selectedDevice: undefined,
    companyId: undefined,
    parentCompanyId: undefined,
    userScope: undefined
};