import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidenavService {
  private sidenavToggleRequestSource = new BehaviorSubject<boolean>(false);
  private deviceData = new BehaviorSubject<any>(null);
  private firmwareVersion = new BehaviorSubject<string>('');
  private emailData = new BehaviorSubject<any>(null);
  private homeData = new BehaviorSubject<boolean>(false);
  private requestPending = new BehaviorSubject<boolean>(false);
  // Ce flux est observable et peut être écouté par les composants
  public sidenavToggleRequested$ = this.sidenavToggleRequestSource.asObservable();
  public deviceData$ = this.deviceData.asObservable();
  public firmwareVersion$ = this.firmwareVersion.asObservable();
  public requestPending$ = this.requestPending.asObservable();
  public emailData$ = this.emailData.asObservable();
  public homeData$ = this.homeData.asObservable();

  constructor() { }

  // Méthode pour déclencher l'ouverture ou la fermeture du sidenav
  public toggleSidenav() {
    this.sidenavToggleRequestSource.next(true);
  }
  public setDeviceData(data: any) {
    this.deviceData.next(data);
  }
  public setFirmwareVersion(version: string,requestPending: boolean) { 
    this.firmwareVersion.next(version);
    this.requestPending.next(requestPending);
  }

  public setEmailData(emails: any) {
    this.emailData.next(emails);
  }

  public setHomeData(data: boolean) {
    this.homeData.next(data);
  }
}
