import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  Validators,
  FormControl,
  FormGroup,
} from "@angular/forms";
import { ToastService, IxActiveModal } from "@siemens/ix-angular";
import { DeviceService } from "src/services/device/device.service";
import { DeviceRegister } from "src/types/device.types";
import * as fromCompanySelector from "src/app/store/selectors/company.selector";
import * as fromAuthSelector from "src/app/store/selectors/auth.selector";
import { AppState } from "src/app/store/app.state";
import { Store } from "@ngrx/store";

@Component({
  selector: "app-claim-device",
  templateUrl: "./claim-device.component.html",
  styleUrls: ["./claim-device.component.scss"],
})
export class ClaimDeviceComponent implements OnInit {
  assetForm!: FormGroup;
  submitted = false;
  wasValidated = false;
  comanyId: string = "";
  value = "";
  selectedOption: string = "";

  constructor(
    private toastService: ToastService,
    private readonly activeModal: IxActiveModal,
    private fb: FormBuilder,
    private deviceService: DeviceService,
    private store: Store<AppState>
  ) {
    this.store.select(fromCompanySelector.selectCompanyId).subscribe((data) => {
      this.comanyId = data!;
   });
  }

  ngOnInit(): void {
    this.assetForm = this.fb.group({
      type: new FormControl("", {
        validators: [Validators.required],
      }),
      iccid: new FormControl("", {
        validators: [Validators.required],
      }),
      devEUI: new FormControl("", {
        validators: [Validators.required],
      }),

    });

    this.store.select(fromAuthSelector.selectICCID).subscribe((data) => {
      if (data) {
        console.log(data);
        this.assetForm.controls['type'].setValue("4G"); 
        this.assetForm.controls['iccid'].setValue(data); 
      }
    });

      // TODO: ADD IMEI WHEN API IS READY
    // this.store.select(fromAuthSelector.selectIMEI).subscribe((data) => {
    //   if (data) {
    //     this.assetForm.controls['devEUI'].setValue(data);
    //   }
    // });
  }

  get assetFormControl() {
    return this.assetForm.controls;
  }

  onDropdownChange(event: any) {
    this.selectedOption = this.assetForm.get("type")?.value;
  }
  create(event: any) {
    event.preventDefault();
    this.wasValidated = true;
    this.submitted = true;
    if ((this.assetFormControl['type'].valid && this.assetFormControl['devEUI'].valid) || (this.assetFormControl['type'].valid && this.assetFormControl['iccid'].valid)) {
      let claimDevice: DeviceType = {
        id: "",
        type: "",
      }
      if (this.assetForm.get("type")?.value === '4G') {
        claimDevice.id = this.assetForm.get("iccid")?.value;
        claimDevice.type = '4G';
      }
      else if (this.assetForm.get("type")?.value === 'LORA') {
        claimDevice.id = this.assetForm.get("devEUI")?.value;
        claimDevice.type = 'LORA';
      }

      let deviceRegisterData: Device = {

        devices: [claimDevice]
      }

      try {
        this.deviceService.registerDevice(this.comanyId, deviceRegisterData).subscribe(
          (data: any) => {
            if (data) {
              this.toastService.setPosition("top-right");

              this.toastService.show({
                type: "success",
                message: data.message,
              });
              this.activeModal.close(deviceRegisterData);
              this.deviceService.updateCompanyTree.next(true);
            }
          },
          (err: HttpErrorResponse) => {
            this.activeModal.close("My close response");
            this.toastService.setPosition("top-right");

            this.toastService.show({
              type: "error",
              message: err.error.message,
            });
          }
        );
      } catch (error) { }
    }
  }

  close() {
    this.activeModal.close("My close response");
  }
}

export interface User {
  email: string;
  password: string;
  first_name: string;
  last_name: string;
  society: string;
  mobile: string;
  country_code: string;
  language: string;
  title: string;
  role: string;
  parent_company_id: string;
}

export interface Device {
  devices: DeviceType[];
}

export interface DeviceType {
  id: string;
  type: string;
}
