import { createReducer, on } from '@ngrx/store';
import { setParameterValue } from '../actions/parameter.actions';
import { DeviceProperty } from '../models/parameter.state';

export interface State {
  parameterValue: DeviceProperty | null;
}

export const initialState: State = {
  parameterValue: null
};

export const parameterReducer = createReducer(
  initialState,
  on(setParameterValue, (state, { parameterValue }) => ({
    ...state,
    parameterValue
  }))
);
