import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  private registerError = new BehaviorSubject<string>('');

  setRegisterError(message: string) {
    this.registerError.next(message);
  }

  getRegisterError() {
    return this.registerError.asObservable();
  }
}