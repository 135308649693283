import { createAction, props } from '@ngrx/store';
import { Company, Device } from 'src/types/company.types';

export const company = createAction(
  '[ADD] Company',
  props<{ selectedCompany: Company }>()
);

export const device = createAction(
  '[ADD] Device',
  props<{ selectedDevice: Device }>()
);

export const reset = createAction('[Company/Device Component] Reset');

export const parentCompanyId = createAction(
  '[ADD] ParentCompanyId',
  props<{ parentCompanyId: string }>()
);

export const companyId = createAction(
  '[ADD] CompanyId',
  props<{ companyId: string }>()
);

export const userScope = createAction(
  '[ADD] UserScope',
  props<{ userScope: string }>()
);
