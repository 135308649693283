import { createReducer, on } from "@ngrx/store";
import { initialState } from "../models/alarms.state";
import * as AlarmActions from "../actions/alarms.actions";


export const alarmReducer = createReducer(
  initialState,
  on(AlarmActions.alarms, (state, { alarmsData }) => {
    return { ...state, alarmsData: alarmsData };
  })
);