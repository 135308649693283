import { createReducer, on } from "@ngrx/store";
import { initialState } from "../models/auth.state";
import * as AuthActions from "../actions/auth.actions";

export const authReducer = createReducer(
  initialState,
  on(AuthActions.loginSuccess, (state, { token }) => ({ ...state, token })),
  on(AuthActions.logout, (state) => ({ ...state, token: null, iccid: null , imei: null })),
  on(AuthActions.saveICCID, (state, { iccid }) => ({ ...state, iccid })),
  on(AuthActions.saveIMEI, (state, { imei }) => ({ ...state, imei }))
);
