import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./login/login.component";
import { AlertsComponent } from "./alerts/alerts.component";
import { HomeComponent } from "./home/home.component";
import { UserOrgManagementComponent } from "./user-org-management/user-org-management.component";
import { AuthGuardService } from "../services/auth/auth-guard.service";
import { ContainerComponent } from "./container/container.component";
import { LoginGuardService } from "src/services/auth/login-guard.service";
import { DeviceResolver } from "src/services/resolver/dashboard.resolver";

const routes: Routes = [
  { path: "login", component: LoginComponent, canActivate: [LoginGuardService] },
  {
    path: "dashboard",
    component: ContainerComponent,
    canActivate: [AuthGuardService],
    children: [
      { path: "", redirectTo: "home", pathMatch: "full" },
      {
        path: "home", component: HomeComponent,
        resolve: {
          deviceData: DeviceResolver
        },
        data: { showDelete: false },
      },
      { path: "alerts", component: AlertsComponent },
      { path: "userOrgManagement", component: UserOrgManagementComponent , data: { showDelete: true }},
    ],
  },
  { path: "", redirectTo: "/login", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
