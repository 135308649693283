import { Component, OnInit, ElementRef, HostListener, Output, EventEmitter } from '@angular/core';
import { IxActiveModal, ToastService } from '@siemens/ix-angular';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.state';
import {
    FormControl,
    FormGroup,
} from "@angular/forms";
import { DateTimePicker, DeviceProperty } from 'src/types/device.types';
import { selectCompanyId, selectDevice } from 'src/app/store/selectors/company.selector';
import { map } from 'rxjs/operators';
import { DeviceParameterService } from 'src/services/device-parameter/device-parameter.service';


@Component({
    selector: 'app-device-parameter-modal',
    templateUrl: './parameter-modal.component.html',
    styleUrls: ['./parameter-modal.component.scss'],
})
export class ParameterModalComponent implements OnInit {
    @Output() modalClosed: EventEmitter<void> = new EventEmitter<void>();
    formRadio: FormGroup = new FormGroup({});
    formcheckbox: FormGroup = new FormGroup({});
    changed: boolean = false;
    parameter: DeviceProperty;
    days: string = '0';
    hours: string = '0';
    minutes: string = '0';
    seconds: string = '0';

    dateValue: DateTimePicker = {
        from: 'undefined',
        to: 'undefined',
        time: 'undefined'
    };
    canCheckClickOutside: boolean = false;
    private companyId: string | undefined;
    private deviceId: string | undefined;
    newValueToWrite: string = '';
    isWriteValueValid: boolean = false;

    isReadonly: boolean;
    isNumerical: boolean;
    isNumericalSeconds: boolean;
    isBool: boolean;
    isCheckbox: boolean;
    isRadio: boolean;
    isString: boolean;
    public checkBoxes5015 = [0, 1, 2, 3, 4, 5, 6, 7];
    public checkedValues: Set<number> = new Set();

    constructor(
        private store: Store<AppState>,
        private activeModal: IxActiveModal,
        private elRef: ElementRef,
        private deviceParameterService: DeviceParameterService,
        private toastService: ToastService,
    ) {

    }
    ngOnInit(): void {
        this.parameter = this.cleanDeviceProperty(this.activeModal.data);
        this.store.select(selectCompanyId).pipe(map(companyId => {
            this.companyId = companyId;
        })).subscribe();

        this.store.select(selectDevice).pipe(
            map(device => device ? device.DeviceId : undefined)
        ).subscribe(deviceId => {
            this.deviceId = deviceId;
        });

        this.formRadio = new FormGroup({
            radio: new FormControl(this.parameter.value),
        });
        this.isBool = this.isBoolModal(this.parameter);
        this.isNumerical = this.isNumericalModal(this.parameter.register);
        this.isNumericalSeconds = this.isNumericalSecondsModal(this.parameter.register);
        this.isCheckbox = this.isCheckboxModal(this.parameter.register);
        this.isRadio = this.isRadioModal(this.parameter.register);
        this.isString = this.isStringModal(this.parameter.register);
        this.isReadonly = this.isReadonlyModal();
        this.setInitialCheckboxes(this.parameter.value);


    }
    setInitialCheckboxes(value: string) {
        this.checkBoxes5015.forEach(index => {
            if ((Number(value) & Math.pow(2, index)) !== 0) {
                this.checkedValues.add(index);
            }
        });
    }
    // function to check if the modal is a Readonly modal
    isReadonlyModal(): boolean {
        return this.parameter.permissions === "R";
    }
    // function to check if the modal is a numerical modal
    isNumericalModal(value: string): boolean {
        const numValue = Number(value);
        return [5029, 5031, 5043, 5045, 5047, 5049, 5051].includes(numValue);
    }
    // function to check if the modal is Numerical in seconds modal 
    isNumericalSecondsModal(value: string): boolean {
        const numValue = Number(value);
        return [5017, 5019, 5021, 5024].includes(numValue);
    }
    // function to check if the modal is a boolean modal
    isBoolModal(value: DeviceProperty): boolean {

        if (value.DataType === "coil" || value.DataType === "bool") {
            return true;
        }
        return false;

    }
    // function to check if the modal is a checkbox modal
    isCheckboxModal(value: string): boolean {
        const numValue = Number(value);
        return [5015].includes(numValue);
    }

    // function to check if the modal is a boolean modal with more than 2 options
    isRadioModal(value: string): boolean {
        const numValue = Number(value);
        return [5016, 5035].includes(numValue);
    }

    // function to check if the modal is a string modal
    isStringModal(value: string): boolean {
        const numValue = Number(value);
        return [5036].includes(numValue);
    }

    // function to initialize the parameter data
    cleanDeviceProperty(property: DeviceProperty | null): DeviceProperty {
        if (!property) {
            return {
                register: 'Undefined',
                paramName: 'Undefined',
                value: 'Undefined',
                lastUpdate: 'Undefined',
                lastParameterChange: 'Undefined',
                min: 'Undefined',
                max: 'Undefined',
                permissions: "Undefined",
                description: 'Undefined',
                DataType: 'Undefined',
            };
        }

        const register = property.register || 'Undefined';
        let parameterType = 'Undefined';

        if (Number(register) >= 5000 && Number(register) <= 5051) {
            parameterType = "module";
        } else {
            parameterType = "host";
        }

        return {
            register: property.register || 'Undefined',
            paramName: property.paramName || 'Undefined',
            value: property.value || 'Undefined',
            lastUpdate: property.lastUpdate || 'Undefined',
            lastParameterChange: property.lastParameterChange || 'Undefined',
            min: property.min || 'Undefined',
            max: property.max || 'Undefined',
            permissions: property.permissions || 'Undefined',
            description: property.description || 'Undefined',
            parameter_type: parameterType,
            DataType: property.DataType || 'Undefined',
        };
    }

    onRead() {
        const registerAsNumber = this.parameter.register;
        if (this.companyId && this.deviceId) {
            if (this.parameter.parameter_type === "module") {
                this.deviceParameterService.ReadDeviceParameter(this.companyId, this.deviceId, registerAsNumber).subscribe({
                    next: (response) => {
                        this.toastService.setPosition("top-right");
                        this.toastService.show({
                            type: "success",
                            message: "Successfully sent the message to read parameter value",
                        });
                    },
                    error: (error) => {
                        console.error('Error fetching data:', error)
                        this.toastService.setPosition("top-right");
                        this.toastService.show({
                            type: "error",
                            message: "Error sending request, please try again later.",
                        });
                    }
                });
            } else {
                if (this.parameter.DataType === "coil") {
                    this.deviceParameterService.ReadDeviceHostParameter(this.companyId, this.deviceId, this.parameter.DataType, registerAsNumber).subscribe({
                        next: (response) => {
                            this.toastService.setPosition("top-right");
                            this.toastService.show({
                                type: "success",
                                message: "Successfully sent the message to read parameter value",
                            });
                        },
                        error: (error) => {
                            console.error('Error fetching data:', error)
                            this.toastService.setPosition("top-right");
                            this.toastService.show({
                                type: "error",
                                message: "Error sending request, please try again later.",
                            });
                        }
                    });
                } else {
                    this.deviceParameterService.ReadDeviceHostParameter(this.companyId, this.deviceId, "register", registerAsNumber).subscribe({
                        next: (response) => {
                            this.toastService.setPosition("top-right");
                            this.toastService.show({
                                type: "success",
                                message: "Successfully sent the message to read parameter value",
                            });
                        },
                        error: (error) => {
                            console.error('Error fetching data:', error)
                            this.toastService.setPosition("top-right");
                            this.toastService.show({
                                type: "error",
                                message: "Error sending request, please try again later.",
                            });
                        }
                    });
                }

            }
        }
        this.onClose();
    }
    onWrite() {
        const registerAsNumber = this.parameter.register;
        if (this.companyId && this.deviceId) {
            if (this.parameter.value === this.newValueToWrite) {
                this.toastService.setPosition("top-right");
                this.toastService.show({
                    type: "warning",
                    message: "The value is the same, not writing",
                });
                return;
            } else {
                if (this.parameter.parameter_type === "module") {
                    this.deviceParameterService.WriteDeviceParameter(this.companyId, this.deviceId, registerAsNumber, this.newValueToWrite).subscribe({
                        next: (response) => {
                            this.toastService.setPosition("top-right");
                            this.toastService.show({
                                type: "success",
                                message: "Successfully sent the message to write parameter value",
                            });
                        },
                        error: (error) => {
                            console.error('Error fetching data:', error)
                            this.toastService.setPosition("top-right");
                            this.toastService.show({
                                type: "error",
                                message: "Error sending request, please try again later.",
                            });
                        },
                    });
                } else {
                    this.deviceParameterService.WriteDeviceHostParameter(this.companyId, this.deviceId, this.parameter.DataType, registerAsNumber, this.newValueToWrite).subscribe({
                        next: (response) => {
                            this.toastService.setPosition("top-right");
                            this.toastService.show({
                                type: "success",
                                message: "Successfully sent the message to write parameter value",
                            });
                        },
                        error: (error) => {
                            console.error('Error fetching data:', error)
                            this.toastService.setPosition("top-right");
                            this.toastService.show({
                                type: "error",
                                message: "Error sending request, please try again later.",
                            });
                        },
                    });
                }
            }
        }
        this.onClose();
    }
    currentClassMin(): string {
        const newValueToWriteNum = Number(this.newValueToWrite);
        const parameterMinNum = Number(this.parameter.min);
        if (this.newValueToWrite !== "" && newValueToWriteNum < parameterMinNum) {
            return 'highlight-error';
        }
        return '';
    }

    currentClassMax(): string {
        const newValueToWriteNum = Number(this.newValueToWrite);
        const parameterMaxNum = Number(this.parameter.max);
        if (this.newValueToWrite !== "" && newValueToWriteNum > parameterMaxNum) {
            return 'highlight-error';
        }
        return '';
    }
    checkValueValidity(): void {
        const newValueToWriteNum = Number(this.newValueToWrite);
        const parameterMinNum = Number(this.parameter.min);
        const parameterMaxNum = Number(this.parameter.max);
        switch (this.parameter.register) {
            case "5024":
                if (this.parameter.max == "<current UTC Unix time>") {
                    const currentUnixTime = Math.floor(Date.now() / 1000);
                    console.log(currentUnixTime);
                    console.log();
                }
                else {
                    console.log("ERROR");
                }
                break;
            default:
                this.isWriteValueValid =
                    (newValueToWriteNum >= parameterMinNum) &&
                    (newValueToWriteNum <= parameterMaxNum);
        }
    }

    // functions to update the value of the Time parameter
    onDaysChange(event: any) {
        this.days = event.detail;
        this.timeSelect();
        this.checkValueValidity();
    }

    onHoursChange(event: any) {
        this.hours = event.detail;
        this.timeSelect();
        this.checkValueValidity();
    }

    onMinutesChange(event: any) {
        this.minutes = event.detail;
        this.timeSelect();
        this.checkValueValidity();
    }

    onSecondsChange(event: any) {
        this.seconds = event.detail;
        this.timeSelect();
        this.checkValueValidity();
    }

    timeSelect() {
        this.changed = true;
        const totalSeconds = Number(this.days) * 86400 + Number(this.hours) * 3600 + Number(this.minutes) * 60 + Number(this.seconds);
        this.newValueToWrite = totalSeconds.toString();
    }

    // function to update the value of the Radio parameter
    onRadioChange(event: any) {
        this.changed = true;
        this.newValueToWrite = event.target.value;
    }

    onCheckboxChange(event: any) {
        this.changed = true;
        const value = Math.pow(2, parseInt(event.target.value, 10));
        const currentValue = this.newValueToWrite ? parseInt(this.newValueToWrite, 10) : 0;

        if (event.target.checked) {
            this.newValueToWrite = (currentValue + value).toString();
        } else {
            this.newValueToWrite = (currentValue - value).toString();
        }
    }


    onClose() {
        this.modalClosed.emit();
        this.activeModal.close("closing modal param");
    }

    @HostListener('document:click', ['$event'])
    onDocumentClick(event: MouseEvent) {
        if (this.canCheckClickOutside && !this.elRef.nativeElement.contains(event.target)) {
            this.activeModal.close("closing due to click outside");
        }
        if (!this.canCheckClickOutside) {
            this.canCheckClickOutside = true;
        }
    }
}
