import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, switchMap } from "rxjs";
import { environment } from "src/environments/environment";
import { Store } from "@ngrx/store";
import * as fromCompanySelectors from "../../../app/store/selectors/company.selector";
import { AppState } from "src/app/store/app.state";
import { Device } from "src/app/user-org-management/claim-device/claim-device.component";

@Injectable({
    providedIn: "root",
})
export class EmailService {
    constructor(
        private http: HttpClient,

        private store: Store<AppState>,
    ) { }

    public AddEmailAddresses(email_addresses: string[], CompanyId: string, DeviceId: string): Observable<any> {

        return this.http.post<any>(
            `${environment.apiUrl}/companies/${CompanyId}/devices/${DeviceId}/process-values/forward-email`, { email_addresses: email_addresses }
        );

    }
    public GetEmailAdresses(CompanyId: string, DeviceId: string): Observable<any> {
        return this.http.get<any>(
            `${environment.apiUrl}/companies/${CompanyId}/devices/${DeviceId}/process-values/forward-email`
        );
    }

    public DeleteEmailAddresses(email_address: string, CompanyId: string, DeviceId: string): Observable<any> {
        return this.http.delete<any>(
            `${environment.apiUrl}/companies/${CompanyId}/devices/${DeviceId}/process-values/forward-email/${email_address}`
        );
    }

    public UpdateEmailAddress(oldEmail: string, newEmail: string, CompanyId: string, DeviceId: string): Observable<any> {
        return this.store.select(fromCompanySelectors.selectDevice).pipe(
            switchMap((data) => {
                return this.http.put<any>(
                    `${environment.apiUrl}/companies/${data?.CompanyId}/devices/${data?.DeviceId}/process-values/forward-email/${oldEmail}`,
                    { email_address: newEmail }
                );
            })
        );
    }
}
