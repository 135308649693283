<div class="connectivityStatus">
    <h1 class="title">Connectivity Status</h1>
    <h3>Total Flowmeters</h3>
    <span>{{ totalCount }}</span>

    <div class="stacked-bar">
        <div class="bar-segment" [style.width.%]="disconnectedPercentage" *ngIf="disconnected !== 0"
            style="background-color: #66667E">
            <h2 *ngIf="disconnected !== 0">{{ disconnected }}</h2>
        </div>
        <div class="bar-segment" [style.width.%]="activePercentage" style="background-color: #01893A"
            *ngIf="active !== 0">
            <h2 *ngIf="active !== 0">{{ active }}</h2>
        </div>
        <div class="bar-segment" [style.width.%]="neverSeenPercentage" style="background-color: #7F7F7F"
            *ngIf="neverSeen !== 0">
            <h2 *ngIf="neverSeen !== 0">{{ neverSeen }}</h2>
        </div>
    </div>

    <div class="ConnectionStatus">
        <div class="status">
            <ix-icon name="circle-filled" size="16" style="color: #66667E"></ix-icon>
            <h3>Disconnected</h3>
        </div>
        <div class="status">
            <ix-icon name="circle-filled" style="color: #01893A" size="16"></ix-icon>
            <h3>Active</h3>
        </div>
        <div class="status">
            <ix-icon name="circle-dot" size="16" color="color-7"></ix-icon>
            <h3>Never Seen</h3>
        </div>
    </div>
</div>