import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IxActiveModal, ToastService } from '@siemens/ix-angular';
import { CompanyService } from 'src/services/company/company.service';
import { DeviceService } from 'src/services/device/device.service';
import * as fromCompanySelector from "src/app/store/selectors/company.selector";
import { AppState } from 'src/app/store/app.state';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-create-org',
  templateUrl: './create-org.component.html',
  styleUrls: ['./create-org.component.scss']
})
export class CreateOrgComponent implements OnInit {

  companyForm!: FormGroup;
  submitted = false;
  wasValidated = false;
  comanyId: string = "";

  constructor(
    private toastService: ToastService,
    private readonly activeModal: IxActiveModal,
    private fb: FormBuilder,
    private companyService: CompanyService,
    private store: Store<AppState>,
    
  ) {
    this.store.select(fromCompanySelector.selectCompanyId).subscribe((data) => {
      this.comanyId = data!;
   });
  }

  ngOnInit(): void {
    this.companyForm = this.fb.group({
      name: [
        "",
        {
          validators: [Validators.required],
        },
      ]
    });
  }

  get companyFormControl() {
    return this.companyForm.controls;
  }
  create(event: any) {
    event.preventDefault();
    this.wasValidated = true;
    this.submitted = true;
    if (this.companyForm.valid) {
      let newCompany: CreateCompany = {
        name: this.companyForm.get("name")?.value,
        parent_company_id: this.comanyId, 

      }

      try {
        this.companyService.createCompany(newCompany).subscribe(
          (data: any) => {
            if (data) {
              this.toastService.setPosition("top-right");

              this.toastService.show({
                type: "success",
                message: data.message,
              });
              this.activeModal.close("");
              this.companyService.updateCompanyTree.next(true);
            }
          },
          (err: HttpErrorResponse) => {
            this.toastService.setPosition("top-right");
            this.activeModal.close("My close response");

            this.toastService.show({
              type: "error",
              message: err.error.message,
            });
          }
        );
      } catch (error) {}
    }
  }

  close() {
    this.activeModal.close("My close response");
  }

}

export interface CreateCompany {
  name: string;
  parent_company_id: string;
}
