import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ToastService } from "@siemens/ix-angular";
import { Observable, Subject, switchMap } from "rxjs";
import { environment } from "src/environments/environment";
import { Store } from "@ngrx/store";
import * as fromCompanySelectors from "../../app/store/selectors/company.selector";
import { AppState } from "src/app/store/app.state";
import { Device } from "src/app/user-org-management/claim-device/claim-device.component";

@Injectable({
  providedIn: "root",
})
export class DeviceService {
  updateCompanyTree = new Subject();
  private deviceClickedSource = new Subject<boolean>();
  deviceClicked$ = this.deviceClickedSource.asObservable();

  constructor(
    private http: HttpClient,
    public toastService: ToastService,
    private store: Store<AppState>,
  ) { }

  emitDeviceClicked(clicked: boolean) {
    this.deviceClickedSource.next(clicked);
  }

  public registerDevice(companyId: string, device: Device): Observable<any> {
    return this.http.post<any>(
      `${environment.apiUrl}/companies/${companyId}/devices`,
      device

    );
  }

  public getDevice(): Observable<any> {
    return this.store.select(fromCompanySelectors.selectCompanyId).pipe(
      switchMap((companyId) => {
        return this.http.get<any>(
          `${environment.apiUrl}/companies/${companyId}/devices`
        );
      })
    );

  }
  public deleteDevice(companyId: string, iccid: string): Observable<any> {

    return this.http.delete<any>(
      `${environment.apiUrl}/companies/${companyId}/devices/${iccid}`
    );
  }

  public getProcessData(): Observable<any> {
    return this.store.select(fromCompanySelectors.selectDevice).pipe(
      switchMap((data) => {
        return this.http.get<any>(
          `${environment.apiUrl}/companies/${data?.CompanyId}/devices/${data?.DeviceId}/process-values/latest`
        );
      })
    );
  }
  public getFUOTAavailable(): Observable<any> {
    return this.http.get<any>(
      `${environment.apiUrl}/fuota/firmware/versions`
    );
  }

  public getFUOTALatest(): Observable<any> {
    return this.http.get<any>(
      `${environment.apiUrl}/fuota/firmware/versions/latest`
    );
  }

  public triggerFuota(companyId : string,deviceId : string): Observable<any> {
        return this.http.post<any>(
          `${environment.apiUrl}/companies/${companyId}/devices/${deviceId}/update-firmware`, []
        );
  }

  public getFuotaStatus(companyId: string, deviceId: string): Observable<any> {
    return this.http.post<any>(
      `${environment.apiUrl}/companies/${companyId}/devices/${deviceId}/fuota-status`,[]
    );
  }
}
