import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { IxModule } from "@siemens/ix-angular";
import { AppRoutingModule } from "./app-routing.module";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import '@siemens/ix-echarts';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialListModule } from "./materiallist.module";
import { LeafletModule } from "@asymmetrik/ngx-leaflet";

import { ApiService } from "../../src/services/auth/auth.service";
import { AuthInterceptor } from "./../auth-interceptor";

import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { AppheaderComponent } from "./appheader/appheader.component";
import { AlertsComponent } from "./alerts/alerts.component";
import { HomeComponent } from "./home/home.component";
import { TreeComponent } from "./tree/tree.component";
import { CreateUserComponent } from "./user-org-management/create-user/create-user.component";
import { ClaimDeviceComponent } from "./user-org-management/claim-device/claim-device.component";
import { UserOrgManagementComponent } from "./user-org-management/user-org-management.component";
import { StoreModule } from "@ngrx/store";
import { authReducer } from "./store/reducers/auth.reducer";
import { themeReducer } from "./store/reducers/theme.reducer";
import { AuthGuardService } from "src/services/auth/auth-guard.service";
import { ContainerComponent } from "./container/container.component";
import { LoginGuardService } from "src/services/auth/login-guard.service";
import { RegisterComponent } from "./register/register.component";
import { PiechartComponent } from "./piechart/piechart.component";
import { OpenlayersmapComponent } from "./openlayersmap/openlayersmap.component"; 
import { DynamictableComponent } from "./dynamictable/dynamictable.component";
import { AgGridAngular } from "ag-grid-angular";
import { companyReducer } from "./store/reducers/company.reducer";
import { DevicePropertiesComponent } from "./device-properties/device-properties.component";
import { ButtonRendererComponent } from "./user-org-management/button-renderer/button-renderer.component";
import { UpdateUserComponent } from "./user-org-management/update-user/update-user.component";
import { ConnectivityStatusComponent } from "./connectivity-status/connectivity-status.component";
import { CreateOrgComponent } from "./user-org-management/create-org/create-org.component";
import { MatSidenavModule } from '@angular/material/sidenav';
import { SidenavAssetsComponent } from './sidenav-assets/sidenav-assets.component';
import { TableDetailsComponent } from "src/app/tableparam/tableparam.component";
import { ButtonAskData } from "./button-read-write-param/button-read-write-param.component";
import { ParameterModalComponent } from "./parameter-modal/parameter-modal.component";
import { NgxEchartsModule } from "ngx-echarts";
import { alarmReducer } from "./store/reducers/alarms.reducer";
import { parameterReducer } from './store/reducers/parameter.reducer';
import { SpinnerComponent } from './spinner/spinner.component';
import { EmailService } from "src/services/Ftp/email/email.service";
import { ForgotPasswordComponent } from "./password/forgot-password.component";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    AppheaderComponent,
    AlertsComponent,
    HomeComponent,
    TreeComponent,
    CreateUserComponent,
    ClaimDeviceComponent,
    UserOrgManagementComponent,
    ContainerComponent,
    RegisterComponent,
    PiechartComponent,
    OpenlayersmapComponent,
    DynamictableComponent,
    DevicePropertiesComponent,
    ButtonRendererComponent,
    UpdateUserComponent,
    ConnectivityStatusComponent,
    CreateOrgComponent,
    SidenavAssetsComponent,
    TableDetailsComponent,
    ButtonAskData,
    ParameterModalComponent,
    SpinnerComponent,
    ForgotPasswordComponent
  ],
  imports: [
    NgxEchartsModule.forRoot({
      /**
       * This will import all modules from echarts.
       * If you only need custom modules,
       * please refer to [Custom Build] section.
       */
      echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
    }),
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    IxModule.forRoot(),
    BrowserAnimationsModule,
    MatSidenavModule,
    MaterialListModule,
    LeafletModule,
    StoreModule.forRoot({
      auth: authReducer,
      company: companyReducer,
      providedTheme: themeReducer,
      alarms: alarmReducer,
      parameter: parameterReducer
    }),
    FormsModule,
    AgGridAngular,
  ],
  providers: [
    ApiService,
    AuthGuardService,
    LoginGuardService,
    EmailService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
