import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ApiService } from './auth.service';
import { Store } from '@ngrx/store';
import { Observable, from, map, switchMap, take } from 'rxjs';
import * as fromAuthSelectors from '../../app/store/selectors/auth.selector';
import { AppState } from 'src/app/store/app.state';
import { saveICCID, saveIMEI } from 'src/app/store/actions/auth.actions';
@Injectable()
export class AuthGuardService implements CanActivate {
constructor(private store: Store<AppState>,public auth: ApiService, public router: Router) {}

canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
  const iccid = route.queryParams['iccid']; 
  const imei = route.queryParams['imei'];

  return from(this.auth.loadToken()).pipe(
    switchMap(() => {
      return this.store.select(fromAuthSelectors.selectToken).pipe(
        take(1), 
        map(token => {
          if (!token) {
            // If no token, redirect to login but save the ICCID in store
            if (iccid && imei) {
              this.store.dispatch(saveICCID({ iccid })); 
              this.store.dispatch(saveIMEI({ imei }));
            }
            this.router.navigate(['login']);
            return false;
          }
          // If token exists, save ICCID and redirect to userOrgManagement
          if (iccid && imei) {
            this.store.dispatch(saveICCID({ iccid }));
            this.store.dispatch(saveIMEI({ imei }));
            this.router.navigate(['dashboard/userOrgManagement']);
          }
          return true;
        })
      );
    })
  );
}
}