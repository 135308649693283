import { environment } from "../src/environments/environment";
/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

// const awsmobile = {
//     "aws_project_region": "eu-north-1",
//     "aws_cognito_identity_pool_id": "eu-north-1:1b7ded89-82ff-417e-b847-f7b2837b42b7",
//     "aws_cognito_region": "eu-north-1",
//     "aws_user_pools_id": "eu-north-1_b5mnmfVWW",
//     "aws_user_pools_web_client_id": "6ehorcjpjm6p1sbf138chp9jec",
//     "oauth": {},
//     "aws_cognito_username_attributes": [
//         "EMAIL"
//     ],
//     "aws_cognito_social_providers": [],
//     "aws_cognito_signup_attributes": [
//         "EMAIL"
//     ],
//     "aws_cognito_mfa_configuration": "OFF",
//     "aws_cognito_mfa_types": [
//         "SMS"
//     ],
//     "aws_cognito_password_protection_settings": {
//         "passwordPolicyMinLength": 8,
//         "passwordPolicyCharacters": []
//     },
//     "aws_cognito_verification_mechanisms": [
//         "EMAIL"
//     ]
// };
//export default awsmobile;

const awsconfig = {
  Auth: {
    region: process.env["REACT_APP_COGNITO_USER_POOL_REGION"]
      ? process.env["REACT_APP_COGNITO_USER_POOL_REGION"]
      : environment.REACT_APP_COGNITO_USER_POOL_REGION,
    userPoolId: process.env["REACT_APP_COGNITO_USER_POOL_ID"]
      ? process.env["REACT_APP_COGNITO_USER_POOL_ID"]
      : environment.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env["REACT_APP_COGNITO_APP_CLIENT_ID"]
      ? process.env["REACT_APP_COGNITO_APP_CLIENT_ID"]
      : environment.REACT_APP_COGNITO_APP_CLIENT_ID,
    mandatorySignIn: true,
    signUpVerificationMethod: "link",
    // oauth: {
    //     domain: import.meta.environment.VITE_COGNITO_DOMAIN,
    //     scope: [
    //         'phone',
    //         'email',
    //         'profile',
    //         'openid',
    //         'aws.cognito.signin.user.admin',
    //     ],
    //     redirectSignIn: 'http://localhost:3000/',
    //     redirectSignOut: 'http://localhost:3000/',
    //     responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    // },
  },
};

export default awsconfig;
