<ix-menu class="left-menu">
  <ix-menu-item home-tab tab-icon="home" (click)="goToPage('/dashboard/home')">
    Home
  </ix-menu-item>
  <ix-menu-item tab-icon="logic-diagram" *ngIf="!isAdmin"> Time Series Analysis </ix-menu-item>
  <ix-menu-item
    tab-icon="alarm-bell-filled"
    (click)="goToPage('/dashboard/alerts')"
    *ngIf="isAdmin"
  >
    Alert Managment
  </ix-menu-item>
  <ix-menu-item tab-icon="log"  *ngIf="!isAdmin"> Logs Tracking </ix-menu-item>
  <ix-menu-item tab-icon="library"  *ngIf="!isAdmin"> FUOTA Managment </ix-menu-item>
  <ix-menu-item tab-icon="product-management" *ngIf="!isAdmin"> MF Managment </ix-menu-item>
  <ix-menu-item tab-icon="capacity-filled"  *ngIf="!isAdmin">
    Payload Monitoring System
  </ix-menu-item>
  <ix-menu-item
    tab-icon="user-management-filled"
    (click)="goToPage('/dashboard/userOrgManagement')"
    *ngIf="isAdmin"
  >
    Org & User Managment
  </ix-menu-item>
  <ix-menu-item tab-icon="log-out" slot="bottom" (click)="logout()">
    Logout
  </ix-menu-item>
  <ix-menu-item tab-icon="user-settings-filled" slot="bottom">
    Settings
  </ix-menu-item>
  <ix-menu-item tab-icon="info" slot="bottom">
    About & legal information
  </ix-menu-item>
</ix-menu>
