<div class="forgot-password" [ngClass]="(currentTheme$ | async) === 'theme-brand-light' ? 'light-theme' : 'dark-theme'">
    <!-- Form when code is not sent -->
    <form *ngIf="!isCodeSent" (ngSubmit)="onResetPassword()">
        <h3 class="mainHeader">Reset Password</h3>
        <div *ngIf="emailStatus" class="confirmation-message" [ngClass]="emailStatus">
            {{ emailMessage }}
        </div>
        <div class="form-group">
            <label for="email">Enter your email address</label>
            <input type="email" id="email" [(ngModel)]="email" name="email" class="form-control"
                (input)="resetMessage()" placeholder="Email address" required>
        </div>
        <div class="button">
            <ix-button type="submit" [loading]="isLoadingReset" >Reset Password</ix-button>
            <ix-button variant="secondary" (click)="onCancel()">Cancel</ix-button>
        </div>
    </form>

    <!-- Form when code is sent -->
    <form *ngIf="isCodeSent" (ngSubmit)="onSubmitNewPassword()">
        <h3 class="mainHeader">Reset Password</h3>

        <div *ngIf="passwordResetStatus" class="confirmation-message" [ngClass]="passwordResetStatus">
            {{ passwordResetMessage }}
        </div>

        <div class="form-group">
            <label for="recoveryCode">Recovery Code</label>
            <input type="text" id="recoveryCode" [(ngModel)]="recoveryCode" name="recoveryCode" class="form-control"
                (input)="resetMessage()" required />
        </div>

        <div class="form-group">
            <label for="newPassword">New Password</label>
            <div class="input-group">
                <input [type]="showPassword ? 'text' : 'password'" id="newPassword" [(ngModel)]="newPassword"
                    (input)="resetMessage()" name="newPassword" class="form-control" required />
                <div class="input-group-append">
                    <ix-button type="button" outline variant="secondary" (click)="handleTogglePassword($event)">
                        <ix-icon [name]="showPassword ? 'eye' : 'eye-cancelled'" size="20"></ix-icon>
                    </ix-button>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label for="confirmPassword">Confirm New Password</label>
            <div class="input-group">
                <input [type]="showPassword ? 'text' : 'password'" id="confirmPassword" [(ngModel)]="confirmPassword"
                    (input)="resetMessage()" name="confirmPassword" class="form-control" required />
                <div class="input-group-append">
                    <ix-button type="button" outline variant="secondary" (click)="handleTogglePassword($event)">
                        <ix-icon [name]="showPassword ? 'eye' : 'eye-cancelled'" size="20"></ix-icon>
                    </ix-button>
                </div>
            </div>
        </div>

        <div class="button">
            <ix-button [loading]="isLoadingSubmit" type="submit" >Submit New Password</ix-button>
            <ix-button variant="secondary"  (click)="onCancel()">Cancel</ix-button>
        </div>
    </form>

</div>