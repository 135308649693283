<div style="height: 100%; padding-top: 0.75rem ;">
  <div *ngIf="company && !device" style="height: 100%;">
    <div class="firstSection">
        <app-connectivity-status class="firstSubSection"></app-connectivity-status>

        <app-openlayersmap class="secondSubSection"></app-openlayersmap>
     
      
    </div>
    <div class="secondSection">
      <app-dynamictable></app-dynamictable>
    </div>
  </div>
  <div *ngIf="device && !company">
    <app-device-properties></app-device-properties>
  </div>
</div>