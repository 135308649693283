<div class="modelcontainer scrolledTable">
  <h1>CREATE USER</h1>
  <p class="error">
    {{ errorMessage }}
  </p>
  <form id="userform" class="row g-3 needs-validation justify-content:center "
    [ngClass]="{ 'was-validated': wasValidated }" no-validate [formGroup]="userForm">
    <div class="formContainer scrolledTable">
      <div class="row">
        <div class="col">
          <label for="first-name">First Name </label>
          <span>
            <ix-input-group>
              <input id="first-name" type="text" placeholder="Enter first name" formControlName="firstName" required />
              <span slot="input-end">
                <ix-icon *ngIf="userFormControl['firstName'].valid" class="tick" name="success" size="16"></ix-icon>
              </span>
            </ix-input-group>

            <span class="text-danger"
              *ngIf="(userFormControl['firstName'] && submitted) && userFormControl['firstName'].errors?.['required']">
              <span><sup>*</sup>First Name is required</span>
            </span>
          </span>

        </div>
        <div class="col">
          <div class="input-text">

            <label for="last-name">Last Name </label>
            <ix-input-group>
              <input id="last-name" type="text" placeholder="Enter last name" formControlName="lastName" required />
              <span slot="input-end">
                <ix-icon *ngIf="userFormControl['lastName'].valid" class="tick" name="success" size="16"></ix-icon>
              </span>
            </ix-input-group>

            <span class="text-danger"
              *ngIf="(userFormControl['lastName'] && submitted) && userFormControl['lastName'].errors?.['required']">
              <span>
                <sup>*</sup>Last Name is required
              </span>
            </span>
          </div>
        </div>

        <div class="col">
          <div class="input-text">
            <label for="title">Title </label>

            <ix-select name="ix-select-control" class="selectInput" id="title" formControlName="title"
              (valueChange)="onTitleChange($event)">
              <ix-select-item label="Mr" value="Mr"></ix-select-item>
              <ix-select-item label="Ms" value="Ms"></ix-select-item>
              <ix-select-item label="Mx" value="Mx"></ix-select-item>
            </ix-select>
            <span class="text-danger">
              <span *ngIf="wasValidated && !user.title">
                <sup>*</sup>Title is required
              </span>
            </span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label for="userSociety">User Society </label>
          <ix-input-group>
            <input id="userSociety" type="text" placeholder="Choose society name" formControlName="userSociety"
              required />
            <span slot="input-end">
              <ix-icon *ngIf="userFormControl['userSociety'].valid" class="tick" name="success" size="16"></ix-icon>
            </span>
          </ix-input-group>
          <span class="text-danger"
            *ngIf="(userFormControl['userSociety'] && submitted) && userFormControl['userSociety'].errors?.['required']">
            <sup>*</sup>User Society is required
          </span>
        </div>
        <div class="col">
          <label for="email">Email </label>
          <ix-input-group>
            <input id="email" type="text" placeholder="Enter a email" formControlName="email" required />
            <span slot="input-end">
              <ix-icon *ngIf="userFormControl['email'].valid" class="tick" name="success" size="16"></ix-icon>
            </span>
          </ix-input-group>
          <span class="text-danger"
            *ngIf="(userFormControl['email'] && submitted) && userFormControl['email'].errors?.['required']">
            <sup>*</sup>Email is required
          </span>
        </div>
        <div class="col">
          <label for="confirmEmail">Confirm Email </label>
          <ix-input-group>
            <input id="confirmEmail" type="text" placeholder="Confirm your email" formControlName="confirmEmail"
              required />
            <span slot="input-end">
              <ix-icon *ngIf="userFormControl['confirmEmail'].valid" class="tick" name="success" size="16"></ix-icon>
            </span>
          </ix-input-group>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label for="password">Password </label>
          <ix-input-group>
            <input id="password" [type]="showPassword ? 'text' : 'password'" formControlName="password"
              placeholder="Enter a password" required />
            <span slot="input-end">
              <ix-icon *ngIf="userFormControl['password'].valid" class="tick" name="success" size="16"></ix-icon>
            </span>
            <span slot="input-end">
              <ix-icon [name]="showPassword ? 'eye' : 'eye-cancelled'" size="20"
                (click)="handleTogglePassword($event)"></ix-icon>
            </span>
          </ix-input-group>
          <span class="text-danger"
            *ngIf="(userFormControl['password'] && submitted) && userFormControl['password'].errors?.['required']">
            <sup>*</sup>Password is required
          </span>
        </div>

        <div class="col">
          <label for="confirmPassword">Confirm Password </label>
          <ix-input-group>
            <input id="confirmPassword" [type]="showPassword ? 'text' : 'password'" placeholder="Confirm your password"
              formControlName="confirmPassword" required />
            <span slot="input-start">
              <ix-icon *ngIf="userFormControl['confirmPassword'].valid" name="success" class="tick" size="16"></ix-icon>
            </span>
            <span slot="input-end">
              <ix-icon [name]="showPassword ? 'eye' : 'eye-cancelled'" size="20"
                (click)="handleTogglePassword($event)"></ix-icon>
            </span>
          </ix-input-group>
        </div>

        <div class="col">
          <label for="countryCode">Country Code </label>
          <ix-input-group>
            <input id="countryCode" type="text" placeholder="Enter country code" formControlName="countryCode"
              required />
            <span slot="input-end">
              <ix-icon *ngIf="userFormControl['countryCode'].valid" class="tick" name="success" size="16"></ix-icon>
            </span>
          </ix-input-group>
          <span class="text-danger"
            *ngIf="(userFormControl['countryCode'] && submitted) && userFormControl['countryCode'].errors?.['required']">
            <sup>*</sup>Country Code is required
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label for="mobilePhone">Mobile Phone </label>
          <ix-input-group>
            <input id="mobilePhone" type="text" placeholder="Enter mobile phone number" formControlName="mobilePhone"
              required />
            <span slot="input-end">
              <ix-icon *ngIf="userFormControl['mobilePhone'].valid" class="tick" name="success" size="16"></ix-icon>
            </span>
          </ix-input-group>
          <span class="text-danger"
            *ngIf="(userFormControl['mobilePhone'] && submitted) && userFormControl['mobilePhone'].errors?.['required']">
            <sup>*</sup>Mobile Phone is required
          </span>
        </div>

        <div class="col">
          <label for="language">Language </label>

          <ix-select name="ix-select-control" class="selectInput" id="language" formControlName="language"
            (valueChange)="onLanguageChange($event)">
            <ix-select-item label="fr" value="FRENSH"></ix-select-item>
            <ix-select-item label="en" value="ENGLISH"></ix-select-item>
          </ix-select>
          <span class="text-danger" *ngIf="wasValidated && !user.language">
            <sup>*</sup>Language is required
          </span>
        </div>

        <div class="col">
          <label for="userRole">User Role </label>

          <ix-select name="ix-select-control" class="selectInput" id="userRole" formControlName="userRole"
            (valueChange)="onUserRoleChange($event)">
            <ix-select-item *ngIf="companyId == 'SIEMENS'" label="SIEMENS_ADMIN" value="SIEMENS_ADMIN"></ix-select-item>
            <ix-select-item label="COMPANY_ADMIN" value="COMPANY_ADMIN"></ix-select-item>
            <ix-select-item label="COMPANY_USER" value="COMPANY_USER"></ix-select-item>
          </ix-select>
          <span class="text-danger" *ngIf="wasValidated && !user.role">
            <sup>*</sup>User Role is required
          </span>
        </div>
      </div>
    </div>

    <div class="buttons">
      <ix-button class="m-1" variant="primary" (click)="create($event)">Save</ix-button>
      <ix-button class="close-modal" variant="secondary" (click)="close()">Close</ix-button>
    </div>
  </form>
</div>