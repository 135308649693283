<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #sidenav mode="over" class="sidenav" position="end">
    <app-sidenav-assets></app-sidenav-assets>
  </mat-sidenav>
    <div class="containerDashboard">
      <div class="mymaincontent">
        <div class="dashboard">
          <app-dashboard></app-dashboard>
        </div>
        <div class="tree">
          <app-tree></app-tree>
        </div>
        <div class="outlet">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
</mat-sidenav-container>