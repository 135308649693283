<div class="bodyLogin">
  <ix-message-bar type="warning" *ngIf="loginError" class="Loginerror" dismissible="false">
    {{ loginError }}
  </ix-message-bar>
  <ix-message-bar type="warning" *ngIf="registerError" class="Loginerror" dismissible="false">
    {{ registerError }}
  </ix-message-bar>
  <ix-message-bar *ngIf="registerSuccess" class="Loginerror" dismissible="false">
    Registration Successful.
  </ix-message-bar>
  <div class="logincontext" [ngClass]="{'register-mode': register}">
    <form *ngIf="!showForgotPassword && !register" [formGroup]="loginForm" (ngSubmit)="onSubmit($event)" no-validate>
      <div class="loginBody" *ngIf="!showPrivacyNotice && !register" [ngClass]="
          currentTheme === 'theme-brand-light' ? 'light-theme' : 'dark-theme'
        ">
        <div class="innerBodyContent">
          <h4>WELCOME TO</h4>
          <h3 class="mainHeader">Watermeter IIoT</h3>
          <div class="row m-t-24">
            <div class="col-md-12 col-sm-12 col-xs-12 m-b-24">
              <input name="userName" placeholder="Username" type="email" id="validateCustomerName" class="h48"
                formControlName="username" aria-describedby="inputGroupPrepend" />
              <div class="invalid-feedback" *ngIf="submitted && !loginForm.get('username')?.valid">
                <sup>*</sup>Please enter a valid username.
              </div>
            </div>
            <div class="col-md-4 col-sm-12 col-xs-12 m-b-24">
              <input name="password" placeholder="Password" type="password" class="h48" formControlName="password"
                id="validationPassword" />
              <div class="invalid-feedback" *ngIf="submitted && !loginForm.get('password')?.valid">
                <sup>*</sup>Please enter a valid password.
              </div>
            </div>

            <div class="col-md-12 col-lg-12 col-xs-12 col-sm-12">
              <ix-button class="m-1" id="button" style="width: 99%; height: 48px" [loading]="isLogin" type="submit" variant="primary">Login</ix-button>
            </div>
            <div class="linksContainer">
              <div class="link">
                <a class="forgotPassword" (click)="forgotPassword()">Forgot Password?</a>
              </div>
              <div class="link">
                New User ?
                <a class="registerLink" (click)="register = true">Register</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

    <div class="newLoginBody" *ngIf="showPrivacyNotice && !register" [ngClass]="
        currentTheme === 'theme-brand-light' ? 'light-theme' : 'dark-theme'
      ">
      <div class="innerBodyContent privacyContent">

        <h3 class="privacyHeader">Privacy Notice</h3>
        <span>Protecting the security and privacy of your personal data including
          sensitive personal data is important to Siemens. Therefore, Siemens or
          a third party engaged by the Siemens Group shall process your data in
          accordance with the applicable Data Privacy & Protection Laws &
          Regulations. Unless indicated otherwise at the time of collection of
          your personal data, we erase your personal data if the retention of
          that personal data is no longer necessary (i) for the purposes for
          which they were collected or otherwise processed or (ii) to comply
          with legal obligations.  This information shall only be available with
          the core team and treated confidentially.</span>
        <div class="row">
          <div class="privacyNotecontent">
            <input type="checkbox" id="checkbox_01" [checked]="checked" (change)="checked = !checked" />
            <label class="privacyNote" for="checkbox_01">I hereby voluntarily consent to the collection and processing
              of
              my personal data as described herein in the Privacy Notice.</label>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12 agree-button">
            <ix-button class="m-1 cancelHeight" outline variant="primary">
              Cancel
            </ix-button>
            &nbsp; &nbsp;
            <ix-button class="m-1" id="button" (click)="navigateToDashboard()" [loading]="isLoading" [disabled]="!checked" variant="primary">I agree</ix-button>
          </div>
        </div>
      </div>
    </div>

    <app-forgot-password *ngIf="showForgotPassword" [email]="email" (emailUpdated)="onEmailUpdated($event)">
    </app-forgot-password>

    <app-register *ngIf="register" [register]="register" (registerChange)="onRegisterChange($event)"
      (registrationSuccess)="handleRegistrationSuccess($event)" class="register"></app-register>

  </div>
  <div class="footer">
    <div class="footer-text">
      <span>Version {{ versionNumber }}</span> &nbsp; &nbsp; &nbsp;
      <span>© 2023–{{ currentDate.getFullYear() }} Siemens AG</span>
    </div>
  </div>
</div>