import { Alarms } from "src/types/alerts.types";


export interface AlarmsState {
    alarmsData: Alarms;
}

export const initialState: AlarmsState = {
    alarmsData: {
        DeviceId: '',
        NextToken: '',
        Values: [],
    },
};