<div class="modelcontainer scrolledTable">
    <h1>UPDATE USER</h1>
  
    <form id="updateUserForm" class="row g-3 needs-validation justify-content:center "
      [ngClass]="{ 'was-validated': wasValidated }" no-validate
      [formGroup]="updateUserForm">
      <div class="formContainer scrolledTable">
        <div class="row">
          <div class="col">
            <label for="first-name" >First Name </label>
            <span>
              <span>
                <input
                  id="first-name"
                  type="text"
                  placeholder="Enter first name"
                  formControlName="firstName"
                  required />
                <ix-icon
                  *ngIf="updateUserFormControl['firstName'].valid"
                  name="success"
                  class="tick"
                  size="16"></ix-icon>
              </span>
  
              <span
                class="text-danger"
                *ngIf="(updateUserFormControl['firstName'] && submitted) && updateUserFormControl['firstName'].errors?.['required']">
                <sup>*</sup>First Name is required
              </span>
            </span>
  
          </div>
          <div class="col">
            <label for="last-name" >Last Name </label>
            <input
              id="last-name"
              type="text"
              placeholder="Enter last name"
              formControlName="lastName"
              required />
            <i>
              <ix-icon
                *ngIf="updateUserFormControl['lastName'].valid"
                name="success"
                class="tick"
                size="16"></ix-icon>
            </i>
  
            <span
              class="text-danger"
              *ngIf="(updateUserFormControl['lastName'] && submitted) && updateUserFormControl['lastName'].errors?.['required']">
              <sup>*</sup>Last Name is required
            </span>
          </div>
  
        
        </div>
        <div class="row">
          <div class="col" style="display: grid;">
            <label for="title" >Title </label>
  
            <ix-select
              name="ix-select-control"
              class="selectInput"
              [value]="value"
              id="title"
              formControlName="title">
              <ix-select-item label="Mr" value="Mr"></ix-select-item>
              <ix-select-item label="Ms" value="Ms"></ix-select-item>
              <ix-select-item label="Mx" value="Mx"></ix-select-item>
            </ix-select>
            <span
              class="text-danger"
              *ngIf="(updateUserFormControl['title'] && submitted) && updateUserFormControl['title'].errors?.['required']">
              <sup>*</sup>Title is required
            </span>
          </div>
          <div class="col">
            <label for="userSociety" >User Society </label>
            <input
              id="userSociety"
              type="text"
              placeholder="Choose society name"
              formControlName="userSociety"
              required />
            <ix-icon
              *ngIf="updateUserFormControl['userSociety'].valid"
              name="success"
              class="tick"
              size="16"></ix-icon>
            <span
              class="text-danger"
              *ngIf="(updateUserFormControl['userSociety'] && submitted) && updateUserFormControl['userSociety'].errors?.['required']">
              <sup>*</sup>User Society is required
            </span>
          </div>
         
         </div> 
        <div class="row">
            <div class="col" >
                <label for="email" >Email </label>
                <input
                  id="email"
                  type="text"
                  placeholder="Enter a email"
                  formControlName="email"
                  readonly />
                <ix-icon
                  *ngIf="updateUserFormControl['email'].valid"
                  name="success"
                  class="tick"
                  size="16"></ix-icon>
                
              </div>
  
          
  
          <div class="col">
            <label for="countryCode">Country Code </label>
            <input
              id="countryCode"
              type="text"
              placeholder="Enter country code"
              formControlName="countryCode"
              required />
            <ix-icon
              *ngIf="updateUserFormControl['countryCode'].valid"
              name="success"
              class="tick"
              size="16"></ix-icon>
            <span
              class="text-danger"
              *ngIf="(updateUserFormControl['countryCode'] && submitted) && updateUserFormControl['countryCode'].errors?.['required']">
              <sup>*</sup>Country Code is required
            </span>
            <span
              class="text-danger"
              *ngIf="updateUserFormControl['countryCode'].value && !updateUserFormControl['countryCode'].valid">
              <sup>*</sup>Country Code is invalid
              
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <label for="mobilePhone" >Mobile Phone </label>
            <input
              id="mobilePhone"
              type="text"
              placeholder="Enter mobile phone number"
              formControlName="mobilePhone"
              required />
            <ix-icon
              *ngIf="updateUserFormControl['mobilePhone'].valid"
              name="success"
              class="tick"
              size="16"></ix-icon>
            <span
              class="text-danger"
              *ngIf="(updateUserFormControl['mobilePhone'] && submitted) && updateUserFormControl['mobilePhone'].errors?.['required'] && !updateUserFormControl['mobilePhone'].value">
              <sup>*</sup>Mobile Phone is required
              
            </span>
            <span
              class="text-danger"
              *ngIf="updateUserFormControl['mobilePhone'].value && !updateUserFormControl['mobilePhone'].valid">
              <sup>*</sup>Mobile Phone must be of 10 digits
              
            </span>
          </div>
          
  
          <div class="col" style="display: grid;">
            <label for="language" >Language </label>
  
            <ix-select
              name="ix-select-control"
              [value]="value"
              class="selectInput"
              id="language"
              formControlName="language">
              <ix-select-item label="fr" value="fr"></ix-select-item>
              <ix-select-item label="en" value="en"></ix-select-item>
            </ix-select>
            <span
              class="text-danger"
              *ngIf="(updateUserFormControl['language'] && submitted) && updateUserFormControl['language'].errors?.['required']">
              <sup>*</sup>Language is required
            </span>
          </div>
  
          
        </div>
      </div>
  
      <div class="buttons">
        <ix-button
          class="m-1"
          variant="primary"
          (click)="create($event)">Save</ix-button>
        <ix-button class="close-modal" variant="secondary"
          (click)="close()">Close</ix-button>
      </div>
    </form>
  </div>
  

