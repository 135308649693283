import { Component, OnInit, ViewChild } from "@angular/core";
import { ModalService, IxModalSize, ToastService } from "@siemens/ix-angular";
import { CreateUserComponent } from "./create-user/create-user.component";
import { ClaimDeviceComponent } from "./claim-device/claim-device.component";
import { GridOptions } from "ag-grid-community";
import { Company } from "src/types/company.types";
import { DeviceService } from "src/services/device/device.service";
import { CompanyService } from "src/services/company/company.service";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/store/app.state";
import * as fromCompanySelector from "../../app/store/selectors/company.selector";
import { ButtonRendererComponent } from "./button-renderer/button-renderer.component";
import { HttpErrorResponse } from "@angular/common/http";
import * as fromThemeSelectors from "../../app/store/selectors/themeswitcher.selector";
import * as fromAuthSelectors from '../../app/store/selectors/auth.selector';
import { CreateOrgComponent } from "./create-org/create-org.component";
import { Clipboard } from '@angular/cdk/clipboard';


@Component({
  selector: "app-user-org-management",
  templateUrl: "./user-org-management.component.html",
  styleUrls: ["./user-org-management.component.scss"],
})
export class UserOrgManagementComponent implements OnInit {
  title = "mag8000";
  selectedTab: number = 1;
  isLoading = true;
  isLoadingData = false;
  displayAccess = false;
  displayClientSecret = false;
  displayClientId = false;
  selectedAssetTab = 0;
  gridOptions!: GridOptions;
  assetsGridOptions!: GridOptions;
  userApiData!: Company;
  deviceApiData!: Company;
  userRowData: UserEntity[] = [];
  deviceRowData: DeviceEntity[] = [];
  companyRowData: CompanyEntity[] = [];
  apiKeysRowData: any[] = [];
  Loading: boolean = false;
  ICCID: string = "";
  IMEI: string = "";

  res: string = "";
  filteredDeviceData: any[] | undefined;
  filtered4GData: any[] | undefined;
  filteredLoRaData: any[] | undefined;
  rowData: any[] | undefined;

  columnDefs: any[] | undefined;
  defaultColDef: any;

  defaultColDef1: any;
  customSearch = "";
  client_secret = "";
  client_id = "";
  tokenName = "";
  roleSelect: string | null = null;
  display = "none";
  f: UserEntity[] | undefined;
  filteredUserData: any[] | undefined;
  filteredOrgData: any[] | undefined;
  filteredApiKeysData: any[] | undefined;
  frameworkComponents = {
    buttonRenderer: ButtonRendererComponent,
  };
  company!: Company;
  companyNameMap = new Map<string, string>();
  companyId: string = "";
  currentTheme: any = "";

  showTokenNameError: boolean = false;
  showRoleError: boolean = false;

  constructor(
    private readonly modalService: ModalService,
    private companyService: CompanyService,
    private deviceService: DeviceService,
    private store: Store<AppState>,
    private toastService: ToastService,
    private clipboard: Clipboard
  ) {
    this.store.select(fromCompanySelector.selectDevice).subscribe((data) => {
      this.userRowData = [];
      this.filteredUserData = this.userRowData;
    });
    this.store.select(fromCompanySelector.selectCompanyId).subscribe((data) => {
      this.companyId = data!;
    });

    this.companyService.subject.subscribe(() => {
      this.updateUserData(this.companyId);
    });

    this.assetsGridOptions = {
      suppressCellFocus: true,
      context: {
        componentParent: this,
      },
    };

    this.store
      .select(fromThemeSelectors.selectThemeToken)
      .subscribe((token) => {
        this.currentTheme = token;
        if (!token) {
          this.currentTheme = "theme-brand-light";
        }
      });

    this.store.select(fromAuthSelectors.selectICCID).subscribe((iccid) => {
      if (iccid) {
        this.ICCID = iccid;
      }
    }
    );
    this.store.select(fromAuthSelectors.selectIMEI).subscribe((imei) => {
      if (imei) {
        this.IMEI = imei;
      }
    }
    );
  }

  userColumnDefs = [
    { headerName: "Email Address", field: "Email", flex: 1 },
    { headerName: "First Name", field: "FirstName", flex: 1 },
    { headerName: "Last Name", field: "LastName", flex: 1 },
    { headerName: "Role", field: "Scope", flex: 1 },
    { headerName: "Area", field: "CompanyName", flex: 1 },
    {
      headerName: "Actions",
      field: "id",
      suppressNavigable: true,
      sortable: false,
      suppressCellSelection: true,
      cellRenderer: ButtonRendererComponent,
    },
  ];
  deviceColumnDefs = [
    { headerName: "Asset Type", field: "AssetType", flex: 1 },
    { headerName: "Claim Status", field: "ClaimStatus", flex: 1 },
    { headerName: "IMEI", field: "IMEI", flex: 1 },
    { headerName: "Serial Number", field: "SerialNumber", flex: 1 },
    { headerName: "Area", field: "CompanyName", flex: 1 },
    {
      headerName: 'Actions', field: 'id',
      suppressNavigable: true,
      sortable: false,
      suppressCellSelection: true,
      cellRenderer: ButtonRendererComponent,

    }


  ];

  orgsColumnDefs = [
    { headerName: "Company Name", field: "CompanyName", flex: 1 },
    { headerName: "Area", field: "ParentCompany", flex: 1 },
    {
      headerName: "Actions",
      field: "id",
      suppressNavigable: true,
      sortable: false,
      suppressCellSelection: true,
      cellRenderer: ButtonRendererComponent,
    }
  ];

  apiKeysColumnDefs = [
    { headerName: "Token name", field: "AppName", flex: 1 },
    { headerName: "Organisation", field: "Organisation", flex: 1 },
    { headerName: "Created on", field: "CreatedOn", flex: 1 },
    { headerName: "Last used", field: "LastUsed", flex: 1 },
    { headerName: "Expires on", field: "ExpiresOn", flex: 1 },
    { headerName: "Role", field: "Role", flex: 1 },
    {
      headerName: "Actions",
      field: "id",
      suppressNavigable: true,
      sortable: false,
      suppressCellSelection: true,
      cellRenderer: ButtonRendererComponent,
    },
  ];

  ngOnInit() {

    this.companyService.getUsers().subscribe((data) => {
      this.userRowData = [];
      this.userApiData = data.data;

      this.addChildrenUser(this.userApiData);
      this.filteredUserData = this.userRowData;
      this.isLoading = false;
    });

    this.deviceService.getDevice().subscribe((data) => {
      this.apiKeysRowData = [];
      this.deviceRowData = [];
      this.addChildrenDevice(data.data);
      this.filteredDeviceData = this.deviceRowData;
      this.filtered4GData = this.deviceRowData.filter(device => device.AssetType === '4G');
      this.filteredLoRaData = this.deviceRowData.filter(device => device.AssetType === 'LORA');

    });

    this.companyService.getCompanies().subscribe((data) => {
      this.apiKeysRowData = [];
      this.companyRowData = [];
      this.addChildrenOrgs(data.data);
      this.updateApiKeysData(this.companyId);
      this.filteredOrgData = this.companyRowData;
    });

    if (this.customSearch !== "") {
      this.display = "block";
    }


    this.defaultColDef = {
      sortable: true,
      filter: true,
    };

    this.defaultColDef = {
      sortable: true,
      filter: true,
    };

    this.filteredUserData = this.userRowData;

    if (this.ICCID && this.IMEI) {
      this.selectedTab = 2;
      this.openModal("360", ClaimDeviceComponent);
    }

  }

  async open(size: IxModalSize) {
    switch (this.selectedTab) {
      case 0:
        this.openModal(size, CreateOrgComponent);
        break;
      case 1:
        this.openModal(size, CreateUserComponent);
        break;

      case 2:
        this.openModal(size, ClaimDeviceComponent);
        break;

      default:
        console.log("No such day exists!");
        break;
    }
  }

  async openModal(size: IxModalSize, tab: any) {
    const instance = await this.modalService.open({
      content: tab,
      size: size,
      backdrop: true,
      centered: true,
      animation: true,
    });
    switch (tab) {
      case CreateOrgComponent:
        await instance.onClose.on((a) => {
          this.updateCompanyData(this.companyId);
          this.companyService.getCompanyTree();
        });
        break;
      case CreateUserComponent:
        await instance.onClose.on((a) => {
          this.updateUserData(this.companyId);
        });
        break;
      case ClaimDeviceComponent:
        await instance.onClose.on((a) => {
          this.updateClaimDevices(this.companyId);
        });
        break;
      default:
    }
  }
  onTokenNameChange() {
    this.showTokenNameError = false;
  }
  onRoleClick(selectedRole: string): void {
    this.roleSelect = selectedRole;
    console.log('Role selected (click):', this.roleSelect);
  }

  createAccessToken(): void {

    this.showTokenNameError = !this.tokenName;
    this.showRoleError = !this.roleSelect;

    if (!this.tokenName || !this.roleSelect) {
      console.error('Form not valid');
      return;
    }

    this.Loading = true;
    this.companyService.createApiKeys(this.companyId, this.tokenName, this.roleSelect).subscribe({
      next: (data) => {
        this.updateApiKeysData(this.companyId);
        this.client_secret = data.data.client_secret;
        this.client_id = data.data.client_id;
        this.Loading = false;
        this.displayAccess = true;
      },
      error: (error) => {
        this.Loading = false;
        console.error('Error creating access token:', error);
      },
      complete: () => {
        console.log('Token creation process completed.');
      }
    });
  }

  copytoclipboardClientSecret() {
    this.clipboard.copy(this.client_secret);
  }

  copytoclipboardClientId() {
    this.clipboard.copy(this.client_id);
  }

  copyBothToClipboard() {
    const combinedText = `Client ID: ${this.client_id}\nClient Secret: ${this.client_secret}`;
    this.clipboard.copy(combinedText);
  }

  changeTab(tab: number) {
    this.selectedTab = tab;
    this.clearInput();
  }
  changeAssetTab(tab: number) {
    this.selectedAssetTab = tab;
    this.clearInput();
  }

  clearInput() {
    this.customSearch = "";
    this.display = "none";
    this.search(this.customSearch, this.selectedTab);
  }

  onKey(event: any) {
    event.target.value === ""
      ? (this.display = "none")
      : (this.display = "block");
    this.search(event.target.value, this.selectedTab);
  }

  search(value1: string, tabIndex: number) {
    if (value1 === '') {
      this.filteredUserData = this.userRowData;
      this.filteredOrgData = this.companyRowData;
      this.filteredDeviceData = this.deviceRowData;
      this.filtered4GData = this.deviceRowData.filter(device => device.AssetType === '4G');
      this.filteredLoRaData = this.deviceRowData.filter(device => device.AssetType === 'LORA');
      this.filteredApiKeysData = this.apiKeysRowData;
      return;
    }
    switch (tabIndex) {
      case 0:
        this.filteredOrgData = this.companyRowData?.filter((item) =>
          Object.values(item).some((value) =>
            value?.toString().toLowerCase().includes(value1.toLowerCase())
          )
        );

        break;
      case 1:
        this.filteredUserData = this.userRowData?.filter((item) =>
          Object.values(item).some((value) =>
            value?.toString().toLowerCase().includes(value1.toLowerCase())
          )
        );
        break;
      case 2:
        switch (this.selectedAssetTab) {
          case 0:
            this.filtered4GData = this.deviceRowData.filter(device => device.AssetType === '4G')?.filter((item) =>
              Object.values(item).some((value) =>
                value?.toString().toLowerCase().includes(value1.toLowerCase())
              )
            );
            break;
          case 1:
            this.filteredLoRaData = this.deviceRowData.filter(device => device.AssetType === 'LORA')?.filter((item) =>
              Object.values(item).some((value) =>
                value?.toString().toLowerCase().includes(value1.toLowerCase())
              )
            );
            break;
          default:
            console.log("No such data exists!");
            break;
        }
        break;
      case 3:
        this.filteredApiKeysData = this.apiKeysRowData?.filter((item) =>
          Object.values(item).some((value) =>
            value?.toString().toLowerCase().includes(value1.toLowerCase())
          )
        );
        break;
      default:
        console.log("No such data exists!");
        break;
    }
  }

  searchFilter(records: any[], searchFields: string[], query: string): any[] {
    return query
      ? records?.filter((record) =>
        searchFields.some((field) =>
          record[field]
            ?.toString()
            .toLowerCase()
            .includes(query.toLowerCase())
        )
      )
      : records;
  }

  updateUserData(comanyId: string) {
    this.isLoadingData = true;
    this.userRowData = [];
    this.companyService.getUpdatedUsers(comanyId).subscribe((data) => {
      this.userApiData = data.data;

      this.addChildrenUser(this.userApiData);
      this.filteredUserData = this.userRowData;
      this.isLoadingData = false;
    });
  }

  updateClaimDevices(comanyId: string) {
    this.isLoadingData = true;
    this.deviceRowData = [];
    this.companyService
      .getUpdatedDevice(comanyId)
      .subscribe((data: any) => {
        this.deviceApiData = data.data;
        this.addChildrenDevice(this.deviceApiData);
        this.filteredDeviceData = this.deviceRowData;
        this.isLoadingData = false;
      });
  }

  updateCompanyData(comanyId: string) {
    this.isLoadingData = true;
    this.companyRowData = [];
    this.companyService
      .getUpdatedCompany(comanyId)
      .subscribe((data: any) => {
        this.addChildrenOrgs(data.data);
        this.filteredOrgData = this.companyRowData;
        this.isLoadingData = false;
      });
  }

  updateApiKeysData(comanyId: string) {
    this.isLoadingData = true;
    this.companyService
      .getAllApiKeys(comanyId)
      .subscribe((data: any) => {
        if(data){
          this.addChildrenApiKeys(data.data);
          this.filteredApiKeysData = this.apiKeysRowData;
          this.isLoadingData = false;
        }else{
          this.apiKeysRowData = [];
          this.filteredApiKeysData = this.apiKeysRowData
          this.isLoadingData = false;
        }
      });
  }
  addChildrenUser(parentCompany: Company) {
    if (parentCompany.Children.length == 0) {
      const userData: UserEntity[] = parentCompany?.Users!;
      userData.forEach((data) => {
        data.CompanyName = parentCompany.CompanyName;
      });
      this.userRowData = [...this.userRowData, ...userData];

      return;
    }
    for (let i = 0; i < parentCompany.Children.length; i++) {
      this.addChildrenUser(parentCompany.Children[i]);
    }
    const userData: UserEntity[] = parentCompany?.Users!;
    userData.forEach((data) => {
      data.CompanyName = parentCompany.CompanyName;
    });
    this.userRowData = [...this.userRowData, ...userData];
  }
  addChildrenDevice(parentCompany: Company) {
    if (parentCompany.Children.length == 0) {
      const deviceData: DeviceEntity[] = parentCompany?.Devices!;
      deviceData.forEach((data) => {
        data.CompanyName = parentCompany.CompanyName;
      });
      this.deviceRowData = [...this.deviceRowData, ...deviceData];
      return;
    }
    for (let i = 0; i < parentCompany.Children.length; i++) {
      this.addChildrenDevice(parentCompany.Children[i]);
    }
    const deviceData: DeviceEntity[] = parentCompany?.Devices!;
    deviceData.forEach((data) => {
      data.CompanyName = parentCompany.CompanyName;
    });
    this.deviceRowData = [...this.deviceRowData, ...deviceData];
    return;
  }

  addChildrenOrgs(parentCompany: any) {
    if (parentCompany.Children.length == 0) {
      return;

    }
    for (let i = 0; i < parentCompany.Children.length; i++) {

      this.addChildrenOrgs(parentCompany.Children[i]);
    }
    const companyData: CompanyEntity[] = parentCompany.Children;
    companyData.forEach((data) => {
      data.ParentCompany = parentCompany.CompanyName;
    });
    this.companyRowData = [...this.companyRowData, ...companyData];

  }

  addChildrenApiKeys(data: any) {
    data.applications.forEach((app) => {
      this.apiKeysRowData.push({
        AppName: app.AppName,
        ClientId: app.ClientId
      });
    });
  }

  deleteUser(user: any) {
    this.companyService.deleteUser(user.CompanyId, user.UserId).subscribe(
      (data) => {
        if (data) {
          this.updateUserData(this.companyId);
          this.toastService.setPosition("top-right");
          this.toastService.show({
            type: "success",
            message: data.message,
          });
        } else {
          this.toastService.setPosition("top-right");

          this.toastService.show({
            type: "error",
            message: data.message,
          });
        }
      },
      (error: HttpErrorResponse) => {
        this.toastService.setPosition("top-right");
        this.toastService.show({
          type: "error",
          message: error.error.message,
        });
      }
    );
  }

  deleteDevice(device: any) {

    this.deviceService.deleteDevice(device.CompanyId, device.DeviceId).subscribe((data) => {
      if (data) {
        this.updateClaimDevices(this.companyId);
        this.toastService.setPosition("top-right");
        this.toastService.show({
          type: "success",
          message: data.message,
        });
        this.deviceService.updateCompanyTree.next(true);
      } else {
        this.toastService.setPosition("top-right");

        this.toastService.show({
          type: "error",
          message: data.message,
        });
      }
    },
      (error: HttpErrorResponse) => {
        this.toastService.setPosition("top-right");
        this.toastService.show({
          type: "error",
          message: error.error.message,
        });
      }
    )
  }

  deleteCompany(company: any) {

    this.companyService.deleteCompany(company.CompanyId).subscribe((data) => {
      if (data) {
        this.updateCompanyData(this.companyId);
        this.toastService.setPosition("top-right");
        this.toastService.show({
          type: "success",
          message: data.message,
        });
        this.companyService.updateCompanyTree.next(true);
      } else {
        this.toastService.setPosition("top-right");

        this.toastService.show({
          type: "error",
          message: data.message,
        });
      }
    },
      (error: HttpErrorResponse) => {
        this.toastService.setPosition("top-right");
        this.toastService.show({
          type: "error",
          message: error.error.message,
        });
      }
    )
  }

  deleteAppKey(app: any) {
    this.companyService.deleteApiKey(this.companyId, app).subscribe((data) => {
      if (data) {
        this.updateApiKeysData(this.companyId);
        this.toastService.setPosition("top-right");
        this.toastService.show({
          type: "success",
          message: "API Key deleted successfully",
        });
      } else {
        this.toastService.setPosition("top-right");

        this.toastService.show({
          type: "error",
          message: data.message,
        });
      }
    },
      (error: HttpErrorResponse) => {
        this.toastService.setPosition("top-right");
        this.toastService.show({
          type: "error",
          message: error.error.message,
        });
      }
    )
  }

  displayAccessModal() {
    this.displayAccess = !this.displayAccess;
  }

  displaySecretKey(type: string) {
    if (type === 'client_id') {
      this.displayClientId = !this.displayClientId;
    } else {
      this.displayClientSecret = !this.displayClientSecret;
    }
  }
}

export interface UserEntity {
  Email: string;
  FirstName: string;
  LastName: string;
  UserId: string;
  Scope: string;
  CompanyId: string;
  CompanyName: string;
}

export interface DeviceEntity {
  AssetType: string;
  ClaimStatus: string;
  SerialNumber: string;
  DeviceId: string;
  IMEI: string;
  CompanyId: string;
  CompanyName: string;
  ConnectivityStatus: string;
}

export interface CompanyEntity {
  CompanyId: string;
  CompanyPath: string;
  CompanyName: string;
  Devices: DeviceEntity[];
  Children: CompanyEntity[];
  Users: UserEntity[];
  ParentCompany: string;
}
