<div class="app-container">
  <div class="header_section">
    <ix-breadcrumb visibleItemCount="3">
      <ix-breadcrumb-item *ngIf="grandParentCompany !== parentCompany" color="--theme-color-dynamic--hover">{{grandParentCompany}}</ix-breadcrumb-item>
      <ix-breadcrumb-item color="--theme-color-dynamic--hover">{{parentCompany}}</ix-breadcrumb-item>
      <ix-breadcrumb-item>{{ICCID}}</ix-breadcrumb-item>
    </ix-breadcrumb>
    <div class="header-buttons">
      <ix-button color="color-7" size="small" (click)="invokeSideNavMethod()"><ix-icon name="configuration"></ix-icon></ix-button>
      <ix-button color="color-7" size="small">Qualification Certificate</ix-button>
    </div>
  </div>
  <div class="info_section">
    <div class="info-details">
      <div class="serial-number">
        Serial Number: {{SerialNumber}} - Device ID: {{ICCID}}
      </div>
      <div class="status-container">
        <div class="status-indicator">
          <div *ngIf="ConnectionStatus === 'ACTIVE'" class="status-circle"></div>
          <div *ngIf="ConnectionStatus === 'UNKNOWN'"><ix-icon name="circle-dot" size="16" color="color-7"></ix-icon>
          </div>
          <div *ngIf="ConnectionStatus === 'DISCONNECTED'" class="Disconnected-circle"></div>
          <div class="status-core"></div>
        </div>
        <div *ngIf="ConnectionStatus !== 'UNKNOWN'">{{ConnectionStatus}}</div>
        <div *ngIf="ConnectionStatus === 'UNKNOWN'">NEVER SEEN</div>
      </div>
    </div>
    <div class="last-updated">
      {{Time}}
    </div>
    <div *ngIf="queueCount === null" class="queue-container">
      <ix-spinner class="spinner"></ix-spinner>
    </div>
    <div *ngIf="queueCount !== null && queueCount > 0" class="queue-container">
      <ix-spinner class="spinner"></ix-spinner> Requests pending: {{ queueCount }}
    </div>
    <div *ngIf="queueCount !== null && queueCount === 0" class="queue-container">
      No requests pending
    </div>
  </div>
  <div class="totalizers">
    <div class="totalizer">
      <div *ngFor="let key of processInfoKeys">
        <div class="row">
          <div class="key">{{ key }}</div>
          <div class="value">{{ ProcessInfo[key] }}</div>
          <div class="unit">
            <span *ngIf="key === 'Flowrate'">m3/h</span>
            <span *ngIf="key.includes('Totalizer')">m3</span>
            <span *ngIf="key.includes('Battery')">%</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="parameters">
    <div class="one">
        <ix-group-item  *ngFor="let tab of lotsOfTabs; let i = index" [selected]="itemSelected === i" [text]="tab"
          (click)="onItemClick(i)" (selectedChanged)="onSelectedChanged(i)">
        </ix-group-item>
    </div>
    <app-table-param class="two" [readonly]="readonly" [selectedIndex]="itemSelected"></app-table-param>
  </div>
</div>