import { createSelector } from "@ngrx/store";
import { AppState } from "../app.state";
import { AlarmsState } from "../models/alarms.state";



export const selectAlarmState = (state: AppState) => state.alarms;



export const selectAlarms = createSelector(
    selectAlarmState,
    (state) => state.alarmsData
);