import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup, FormControl, Validators, AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { Router } from "@angular/router";
import { ErrorService } from "src/services/Errors/registerError";
import { ApiService } from "src/services/auth/auth.service";
import { CompanyRegister, CompanyRegisterResponse, devicesType } from "src/types/auth.types";
import { AppState } from "../store/app.state";
import { Store } from "@ngrx/store";
import * as fromThemeSelectors from "../../app/store/selectors/themeswitcher.selector";
import * as fromAuthSelectors from '../../app/store/selectors/auth.selector';

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  submitted: boolean;
  step : number = 0;

  @Input() register: boolean | undefined;
  @Output() registerChange = new EventEmitter<boolean>();
  @Output() registrationSuccess = new EventEmitter<boolean>();
  currentTheme: any = "";

  changeRegister(value: boolean) {
    this.register = value;
    this.registerChange.emit(this.register);
  }
  
  stepChange(event: any) {
    console.log(event.detail);
    this.step = event.detail;
  }

  constructor(
    private errorService: ErrorService,
    public apiService: ApiService,
    public route: Router,
    public store: Store<AppState>
  ) {
    this.submitted = false;
    this.registerForm = new FormGroup(
      {
        password: new FormControl("", [
          Validators.required,
          Validators.minLength(8),
          this.passwordValidator,
        ]),
        username: new FormControl("", [Validators.required, Validators.email]),
        confirmPassword: new FormControl("", [
          Validators.required,
          Validators.minLength(8),
        ]),
        iccid: new FormControl("", []),
        imei: new FormControl("", []),
        organization: new FormControl("", [
          Validators.required,
          Validators.minLength(1),
        ]),
        radio: new FormControl("4G"),
        devEUI: new FormControl("", []),
      },
      { validators: this.passwordsMatch }
    );
    this.store
      .select(fromThemeSelectors.selectThemeToken)
      .subscribe((token) => {
        this.currentTheme = token || "theme-classic-light";
      });
    this.store.select(fromAuthSelectors.selectICCID).subscribe((iccid) => {
      if (iccid) {
        this.registerForm.controls['iccid'].setValue(iccid);
      }
    });
    this.store.select(fromAuthSelectors.selectIMEI).subscribe((imei) => {
      if (imei) {
        this.registerForm.controls['imei'].setValue(imei);
      }
    });
  }

  ngOnInit(): void {
    this.registerForm.controls['radio'].valueChanges.subscribe(value => {
      this.registerForm.controls['iccid'].clearValidators();
      this.registerForm.controls['devEUI'].clearValidators();

      if (value === '4G') {
        this.registerForm.controls['iccid'].setValidators([Validators.required, Validators.minLength(10)]);
      } else if (value === 'LORA') {
        this.registerForm.controls['devEUI'].setValidators([Validators.required]);
      }

      this.registerForm.controls['iccid'].updateValueAndValidity();
      this.registerForm.controls['devEUI'].updateValueAndValidity();
    });
  }

  passwordsMatch(control: AbstractControl): ValidationErrors | null {
    const group = control as FormGroup;
    const password = group.controls["password"].value;
    const confirmPassword = group.controls["confirmPassword"].value;

    return password === confirmPassword ? null : { notSame: true };
  }

  passwordValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    const hasUpperCase = /[A-Z]/.test(value);
    const hasLowerCase = /[a-z]/.test(value);
    const hasNumeric = /[0-9]/.test(value);
    const hasSpecialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(value);
    const isLengthValid = value.length >= 8;

    if (isLengthValid && hasUpperCase && hasLowerCase && hasNumeric && hasSpecialChar) {
      return null;
    } else if (!hasSpecialChar) {
      return { passwordNoSpecialChar: true };
    } else {
      return { passwordInvalid: true };
    }
  }

  setError() {
    const controls = this.registerForm.controls;
    for (const name in controls) {
      if (controls[name].errors) {
        this.processErrors(name, controls[name].errors);
        return 1;
      }
    }
    if (this.registerForm.errors && this.registerForm.errors["notSame"]) {
      this.errorService.setRegisterError(`Passwords do not match`);
      return 1;
    }
    return 0;
  }

  private processErrors(name: string, errors: ValidationErrors): void {
    const errorMessages = {
      required: `${name} is required`,
      minlength: `${name} must be at least ${errors['minlength']?.requiredLength} characters long`,
      email: `${name} is not valid`,
      passwordInvalid: `Password needs at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character ( ! @ # ... )`,
      passwordNoSpecialChar: `Password must contain at least one special character ( ! @ #`
    };

    for (const errorName in errors) {
      if (errorMessages[errorName]) {
        this.errorService.setRegisterError(errorMessages[errorName]);
        return;
      }
    }
  }

  onNext() {
    if(this.setError() === 0) {
      this.step = 1;
    }
    setTimeout(() => {
      this.errorService.setRegisterError("");
    }, 3000);
  }


  async onSubmit(event: SubmitEvent) {
    this.submitted = true;
    this.setError();
    if (this.registerForm.valid) {
      let devicePayload: devicesType = { id: "", type: "" };
      if (this.registerForm.controls["radio"].value === "4G") {
        devicePayload = {
          id: this.registerForm.controls["iccid"].value,
          type: this.registerForm.controls["radio"].value,
        }
      } else if (this.registerForm.controls["radio"].value === "LORA") {
        devicePayload = {
          id: this.registerForm.controls["devEUI"].value,
          type: this.registerForm.controls["radio"].value,
        }
      }
      let data: CompanyRegister = {
        email: this.registerForm.controls["username"].value,
        password: this.registerForm.controls["password"].value,
        company_name: this.registerForm.controls["organization"].value,
        devices: [devicePayload],
      };
      console.log(data);
      (await this.apiService.register(data)).subscribe({
        next: (res: CompanyRegisterResponse) => {
          if (res) {
            this.changeRegister(false)
            this.registrationSuccess.emit(true);
          }
        },
        error: (error) => {
          if (error.error.data) {
            this.errorService.setRegisterError(error.error.data.message);
          } else {
            this.errorService.setRegisterError(error.error.message);
          }
        },
      });
    }
    setTimeout(() => {
      this.errorService.setRegisterError("");
    }, 3000);
  }
}
