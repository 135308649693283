import { Component, Inject, Renderer2 } from "@angular/core";
// import { AuthInterceptor } from "./../auth-interceptor";
import { ApiService } from "./../services/auth/auth.service";
import { Subscription, interval } from "rxjs";
import { Auth } from "aws-amplify";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { CompanyService } from "../services/company/company.service";
import * as CompanyActions from '../app/store/actions/company.actions';
import * as AuthActions from '../app/store/actions/auth.actions';
import { AppState } from "./store/app.state";
import { Store } from "@ngrx/store";
import { DOCUMENT } from "@angular/common";
import { environment } from '../environments/environment';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "mag8000";
  userLogged: boolean = false;
  intervalSubscription: Subscription | undefined;
  source: any;
  constructor(
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    public apiService: ApiService,
    private CompanyService : CompanyService,
    private store : Store<AppState>
  ) {
    this.addLink(environment.apiUrl);
  }

  // adding to the DOM a Link of this form <link rel="preconnect" href=""> to preconnect to our Api link
  addLink(url: string) {
    const link = this.renderer.createElement('link');
    this.renderer.setAttribute(link, 'rel', 'preconnect');
    this.renderer.setAttribute(link, 'href', url);
    this.renderer.appendChild(this.document.head, link);
  }

  ngOnInit() {
    this.CompanyService.getCompanyId();
    this.source = interval(1000);


    this.intervalSubscription = this.source.subscribe((val: any) => {
      Auth.currentSession()
        .then((res: CognitoUserSession) => {
          if (
            res.getIdToken().getJwtToken()
          ) {
            this.store.dispatch(AuthActions.loginSuccess({ token: res.getIdToken().getJwtToken() }));
            this.userLogged = true;
          } else {
            this.userLogged = false;
          }
        })
        .catch((error) => {
          this.userLogged = false;
        });
    });

    Auth.currentAuthenticatedUser().then((user) => {
      this.store.dispatch(CompanyActions.userScope({ userScope: user.signInUserSession.idToken.payload['custom:user_scope']}));
    }
    ).catch((error) => {
      console.log(error);
    });
  }

  
}
