import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import packageJSON from "../../../package.json";
import { ApiService } from "../../services/auth/auth.service";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Router } from "@angular/router";
import { ErrorService } from "src/services/Errors/registerError";
import * as fromThemeSelectors from "../../app/store/selectors/themeswitcher.selector";
import * as fromAuthSelectors from '../../app/store/selectors/auth.selector';
import { Store } from "@ngrx/store";
import { AppState } from "../store/app.state";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted: any;
  currentDate: any;
  versionNumber: any;
  showPrivacyNotice: boolean = false;
  showForgotPassword: boolean = false;
  register: boolean = false;
  checked: boolean = false;
  loginError: string | null = "";
  registerError: string | null = "";
  registerSuccess: boolean = false;
  currentTheme: any = "";
  ICCID: string = "";
  IMEI: string = "";
  isLogin: boolean = false;
  isLoading: boolean = false;
  constructor(
    private errorService: ErrorService,
    public apiService: ApiService,
    public route: Router,
    public store: Store<AppState>
  ) {
    this.submitted = false;
    this.loginForm = new FormGroup({
      password: new FormControl("", [
        Validators.required,
        Validators.minLength(5),
      ]),
      username: new FormControl("", [Validators.required, Validators.email]),
      language: new FormControl(1),
    });
    this.currentDate = new Date();
    this.versionNumber = packageJSON.version;
    this.store
      .select(fromThemeSelectors.selectThemeToken)
      .subscribe((token) => {
        this.currentTheme = token;
        if (!token) {
          this.currentTheme = "theme-classic-light";
        }
      });
  }
  ngOnInit(): void {
    this.errorService.getRegisterError().subscribe((message) => {
      this.registerError = message;
    });
    this.store.select(fromAuthSelectors.selectICCID).subscribe((data) => {
      this.ICCID = data!;
    });
    this.store.select(fromAuthSelectors.selectIMEI).subscribe((data) => {
      this.IMEI = data!;
    });
  }
  getEmail(): string {
    return this.loginForm.get('username')?.value || '';
  }
  onRegisterChange(value: boolean) {
    this.register = value;
  }
  onEmailUpdated(newEmail: string): void {
    this.loginForm.get('username')?.setValue(newEmail);
    console.log('Email updated:', newEmail);
    this.showForgotPassword = false;
  }

  handleRegistrationSuccess(success: boolean) {
    if (success) {
      this.registerSuccess = true;
    }
    setTimeout(() => {
      this.registerSuccess = false;
    }, 3000);
  }
  forgotPassword(): void {
    this.showForgotPassword = true;
    console.log("forgot password");
  }
  get email(): string {
    return this.loginForm.get('username')?.value || '';
  }

  onSubmit(event: SubmitEvent) {
    this.submitted = true;
    if (this.loginForm.valid) {
      this.isLogin = true;
      let data = {
        email: this.loginForm.controls["username"].value,
        password: this.loginForm.controls["password"].value,
      };
      this.apiService
        .login(data)
        .then((res: CognitoUser) => {
          this.isLogin = false;
          this.showPrivacyNotice = true;
          if (res) {
            if (res.challengeName) {

            } else {
              this.showPrivacyNotice = true;
            }
          }

          // if (res && res.getAttributeVerificationCode)
        })
        .catch((error) => {
          this.loginError = null;
          this.isLogin = false;
          if (error.message.includes("NotAuthorizedException")) {
            this.loginError =
              "Votre nom d'utilisateur ou mot de passe est incorrect.";
          } else if (error.message.includes("UserNotFoundException")) {
            this.loginError = "Aucun compte trouvé avec ce nom d'utilisateur.";
          } else {
            this.loginError =
              "Une erreur inattendue s'est produite. Veuillez réessayer.";
          }
          setTimeout(() => {
            this.loginError = null;
            this.isLogin = false;
          }, 3000);
        });
    }
  }

  navigateToDashboard() {
    this.isLoading = true;
    if (this.ICCID && this.IMEI) {
      this.route.navigate(["/dashboard/userOrgManagement"], {
        queryParams: {
          iccid: this.ICCID, imei: this.IMEI

        },
      });
      this.apiService.loginStatus.next(true);
      return;
    }
    setTimeout(() => {
      this.route.navigate(["/dashboard/home"]);
      this.apiService.loginStatus.next(true);
      this.isLoading = false;
    }, 3000);
  }
}
