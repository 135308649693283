<div style="display: flex; justify-content: space-around; padding: 4px;">
  <ix-icon *ngIf="selectedTab == 1" class="any-class" name="pen" size="24" (click)="invokeEditMethod()"
    style="cursor:pointer"></ix-icon>
  <ix-tooltip for=".any-class">Edit</ix-tooltip>
  <ix-icon *ngIf="showDelete" class="delete" name="trashcan-filled" size="24" (click)="invokeDeleteMethod($event)"
    style="cursor:pointer; color:red"></ix-icon>

  <ix-tooltip for=".delete">Delete</ix-tooltip>

  <ix-icon *ngIf="selectedTab == 2" class="info" name="about" size="24" style="cursor:pointer"
    (click)="invokeSideNavMethod()"></ix-icon>


  <ng-template #customModal let-modal>
    <div>

      <ix-modal-header *ngIf="selectedTab == 1"> Delete User </ix-modal-header>
      <ix-modal-content *ngIf="selectedTab == 1">Are you sure to delete the user?</ix-modal-content>
      <ix-modal-header *ngIf="selectedTab == 2"> Delete Device</ix-modal-header>
      <ix-modal-content *ngIf="selectedTab == 2">Are you sure to delete the device?</ix-modal-content>
      <ix-modal-header *ngIf="selectedTab == 0"> Delete Company</ix-modal-header>
      <ix-modal-content *ngIf="selectedTab == 0">Are you sure to delete the company?</ix-modal-content>
      <ix-modal-header *ngIf="selectedTab == 3"> Delete Access Token</ix-modal-header>
      <ix-modal-content *ngIf="selectedTab == 3">Are you sure to delete the access token?</ix-modal-content>
      <ix-modal-footer>
        <ix-button outline class="dismiss-modal" (click)="modal.dismiss('dismiss')">
          No
        </ix-button>
        <ix-button class="close-modal" (click)="modal.close('okay')">
          Yes
        </ix-button>
      </ix-modal-footer>
    </div>
  </ng-template>