import { Injectable } from "@angular/core";
import { Auth } from "aws-amplify";
import { Subject } from "rxjs";
import { CompanyRegister, CompanyRegisterResponse, SignInProps } from "../../types/auth.types";
import { ToastService } from "@siemens/ix-angular";
import { Store } from '@ngrx/store';
import * as AuthActions from '../../app/store/actions/auth.actions';
import * as CompanyActions from '../../app/store/actions/company.actions';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { CompanyService } from "../company/company.service";



@Injectable({
  providedIn: "root",
})
export class ApiService {
  loginStatus = new Subject();
  constructor(private CompanyService: CompanyService, private http: HttpClient, private store: Store, public toastService: ToastService) { }

  async login(data: SignInProps) {
    try {
      const user = await Auth.signIn(data.email, data.password);

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        console.log('new password required');
        await Auth.completeNewPassword(
          user,
          data.password

        )
      }

      this.store.dispatch(AuthActions.loginSuccess({ token: user.signInUserSession.idToken.jwtToken }));
      this.store.dispatch(CompanyActions.userScope({ userScope: user.signInUserSession.idToken.payload['custom:user_scope'] }));
      localStorage.setItem('idToken', user.signInUserSession.idToken.jwtToken);
      this.CompanyService.getCompanyId();
      return user;
    } catch (error: any) {
      throw new Error(error);
    }
  }

  async loadToken() {
    return Auth.currentSession()
      .then((res) => {
        if (res.getIdToken().getJwtToken()) {
          this.store.dispatch(AuthActions.loginSuccess({ token: res.getIdToken().getJwtToken() }));
        }
      })
      .catch((error) => {
        console.error('Error loading token:', error);
      });
  }

  async register(data: CompanyRegister) {
    try {
      const user = this.http.post<CompanyRegisterResponse>(`${environment.apiUrl}/company/register`, data);
      return user;
    } catch (error: any) {
      throw new Error(error);
    }
  }


  async logout() {
    try {
      await Auth.signOut();
      this.store.dispatch(AuthActions.logout());
      localStorage.removeItem('idToken');
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }
}
