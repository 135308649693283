<form [formGroup]="registerForm" (ngSubmit)="onSubmit($event)" novalidate>
  <div class="register" [ngClass]="
      currentTheme === 'theme-brand-light' ? 'light-theme' : 'dark-theme'
    ">
    <div class="header">
      <h1 class="app-title">Watermeter IIoT Web Application</h1>
      <h2 class="register-title">Register your account</h2>
      <p class="register-subtitle">Fill the form below to register your account.</p>
    </div>
    <ix-workflow-steps clickable (stepSelected)="stepChange($event)">
      <ix-workflow-step status="open" [selected]="step === 0 ? true : false">Basic informations</ix-workflow-step>
      <ix-workflow-step status="open" [selected]="step === 1 ? true : false">Device details</ix-workflow-step>
    </ix-workflow-steps>

    <div class="form-register">
      <div class="step0" *ngIf="step === 0">
        <p class="register-subtitle">Enter your basic information required for registration.</p>
        <div class="input-group">
          <label for="username">Enter your email</label>
          <input type="text" placeholder="Email" class="input-field" formControlName="username" id="username" />
        </div>
        <div class="input-group">
          <label for="organization">Enter your organisation name</label>
          <input type="text" placeholder="Organisation" class="input-field" formControlName="organization"
            id="organization" />
        </div>
        <div class="password-group">
          <ix-input-group>
            <label for="password">Enter your password</label>
            <input type="password" placeholder="Password" class="input-field" formControlName="password"
              id="password" />
          </ix-input-group>
          <ix-input-group>

            <label for="confirmPassword">Retype the password</label>
            <input type="password" placeholder="Retype Password" class="input-field" formControlName="confirmPassword"
              id="confirmPassword" />
          </ix-input-group>
        </div>
        <div class="password-instructions">
          The password should have at least 8 characters, including a mix of
          uppercase and lowercase letters, numbers, and special characters.
        </div>
        <ix-button variant="secondary" (click)="changeRegister(false)">Cancel</ix-button>
        <ix-button variant="primary" (click)="onNext()">Next</ix-button>
      </div>
      <div class="step1" *ngIf="step === 1">
        <p class="register-subtitle">Enter any one device details to register. You can always add other devices under
          ‘Asset Management ‘ option after you login.</p>

        <div class="module-type-selection">
          <label for="radio">choose the device module type </label>
          <div class="radio-group" id="radio">
            <div class="radio-option">
              <input type="radio" id="moduleType1" name="radio" formControlName="radio" value="4G" />
              <label for="moduleType1">4G</label>
            </div>
            <div class="radio-option">
              <input type="radio" id="moduleType2" name="radio" formControlName="radio" value="LORA" />
              <label for="moduleType2">LORA</label>
            </div>
          </div>
        </div>
        <div class="input-group" *ngIf="registerForm.controls['radio'].value === '4G'">
          <label for="iccid"> Enter ICCID of the device</label>
          <input type="text" placeholder="ICCID" class="input-field" formControlName="iccid" id="iccid" />
        </div>
        <div class="input-group" *ngIf="registerForm.controls['radio'].value === '4G'">
          <label for="iccid"> Enter IMEI of the device</label>
          <input type="text" placeholder="IMEI" class="input-field" formControlName="imei" id="imei" />
        </div>
        <div class="input-group" *ngIf="registerForm.controls['radio'].value === 'LORA'">
          <label for="devEUI"> Enter devEUI of the device</label>
          <input type="text" placeholder="DevEUI" class="input-field" formControlName="devEUI" />
        </div>
        <ix-button variant="secondary" (click)="step = 0">Back</ix-button>
        <ix-button type="submit" variant="primary">Register</ix-button>
      </div>



    </div>
  </div>
</form>