<aside [ngClass]="
currentTheme === 'theme-brand-light'
  ? 'sidenav-light'
  : 'sidenav-dark'
">
    <header [ngClass]="
    currentTheme === 'theme-brand-light'
      ? 'sidenav-header-light'
      : 'sidenav-header-dark'
  ">
        <h2>Company sites</h2>
    </header>
    <section class="sidenav-map-container">
        <!-- La carte serait chargée ici -->
    </section>
    <nav class="sidenav-links">
        <ix-button outline class="sidenav-button update-location">Update location</ix-button>
        <!-- boutons ou liens si nécessaire -->
    </nav>
    <section class="sidenav-content">
        <ix-layout-grid class="grid-layout">
            <ix-row>
                <ix-col [ngClass]="
                currentTheme === 'theme-brand-light'
                  ? 'nav-col-light'
                  : 'nav-col-dark'
                ">
                    <ix-key-value label="Asset Template" value="Value"></ix-key-value>
                    <ix-key-value label="Device Id" [value]="deviceData?.DeviceId"></ix-key-value>
                    <ix-key-value label="Owner" [value]="CompanyName"></ix-key-value>
                    <!-- <ix-key-value label="Owner" *ngIf="deviceData?.CompanyId" [value]="deviceData?.CompanyId"></ix-key-value> -->
                    <ix-dropdown-button label="Delimiter" variant="secondary">
                        <div [ngClass]="
                        currentTheme === 'theme-brand-light'
                          ? 'label-light'
                          : 'label-dark'
                        ">
                            <ix-dropdown-item label="Item 1" value="1"></ix-dropdown-item>
                            <ix-dropdown-item label="Item 2" value="2"></ix-dropdown-item>
                        </div>
                    </ix-dropdown-button>
                    <ix-dropdown-button label="Australia/NZ" variant="secondary">
                        <div [ngClass]="
                        currentTheme === 'theme-brand-light'
                          ? 'label-light'
                          : 'label-dark'
                        ">
                            <ix-dropdown-item label="Item 1" value="1"></ix-dropdown-item>
                            <ix-dropdown-item label="Item 2" value="2"></ix-dropdown-item>
                        </div>
                    </ix-dropdown-button>
                </ix-col>
                <ix-col [ngClass]="
                currentTheme === 'theme-brand-light'
                  ? 'nav-col-light'
                  : 'nav-col-dark'
                ">
                    <ix-key-value label="Asset description" [value]="deviceData?.AssetType"></ix-key-value>
                    <ix-key-value label="IMEI" [value]="deviceData?.IMEI"></ix-key-value>
                    <ix-key-value label="Serial Number" [value]="deviceData?.SerialNumber"></ix-key-value>
                    <ix-dropdown-button label="Time Zone name" variant="secondary">
                        <div [ngClass]="
                        currentTheme === 'theme-brand-light'
                          ? 'label-light'
                          : 'label-dark'
                        ">
                            <ix-dropdown-item label="Item 1" value="1"></ix-dropdown-item>
                            <ix-dropdown-item label="Item 2" value="2"></ix-dropdown-item>
                        </div>
                    </ix-dropdown-button>
                </ix-col>
            </ix-row>

        </ix-layout-grid>
    </section>
    <header [ngClass]="
    currentTheme === 'theme-brand-light'
      ? 'sidenav-header-light'
      : 'sidenav-header-dark'
  ">
        <h2>Firmware Update Over The Air (FUOTA)</h2>
    </header>
    <nav *ngIf="firmwareVersion !== 'No firmware version available' && firmwareVersion !== 'No firmware data in response'">
        <h3 [ngClass]="
        currentTheme === 'theme-brand-light'
          ? 'sidenav-header-light'
          : 'sidenav-header-dark'
      ">Current firmware version: {{firmwareVersion}}</h3>
        <ix-button *ngIf="requestPending" loading outline class="sidenav-button update-location" disabled="true">Reading
            firmware Version...</ix-button>
    </nav>
    <nav class="sidenav-links" *ngIf="firmwareVersion === 'No firmware version available'">
        <ix-button *ngIf="!requestPending" [loading]="loading" outline class="sidenav-button update-location"
            (click)="checkFirmware()">Check Firmware
            Version</ix-button>
        <ix-button *ngIf="requestPending" loading outline class="sidenav-button update-location" disabled="true">Reading
            firmware Version...</ix-button>
    </nav>
    <div *ngIf="fuotaStatus && fuotaStatus!=='Error'">

        <nav class="sidenav-links" *ngIf="fuotaStatus ==='success' ">
            <!-- <h4 [ngClass]="
            currentTheme === 'theme-brand-light'
              ? 'sidenav-header-light'
              : 'sidenav-header-dark'
          "
          *ngIf="isUpdatable"
          >Last Update :</h4> -->
            <ix-button ghost class="sidenav-button update-location" disabled variant="primary"
                icon="success">Success</ix-button>
        </nav>
        <nav class="sidenav-links" *ngIf="fuotaStatus !=='success' && fuotaStatus !== 'None' ">
            <!-- <h4 [ngClass]="
            currentTheme === 'theme-brand-light'
              ? 'sidenav-header-light'
              : 'sidenav-header-dark'
          "
          *ngIf="isUpdatable"
          >Last Update :</h4> -->
            <ix-button ghost class="sidenav-button update-location" disabled variant="primary"
                icon="error">{{fuotaStatus}}</ix-button>
        </nav>
    </div>
    <nav class="sidenav-links"
        *ngIf="firmwareVersion !== 'No firmware version available' && FUOTAupdateAvailable && fuotaStatus !== 'None' ">
        <h4 [ngClass]="
            currentTheme === 'theme-brand-light'
              ? 'sidenav-header-light'
              : 'sidenav-header-dark'
          " *ngIf="isUpdatable">New Firmware version available for this device</h4>
        <ix-button outline class="sidenav-button update-location" (click)="openModal()" variant="primary"
            [disabled]="!isUpdatable">Update Firmware</ix-button>
    </nav>
    <nav class="sidenav-links" *ngIf="fuotaStatus === 'None' ">
        <ix-button outline class="sidenav-button update-location" (click)="openModal()" variant="primary"
            loading>Updating </ix-button>
    </nav>



    <div>

        <header [ngClass]="
        currentTheme === 'theme-brand-light'
          ? 'sidenav-header-light'
          : 'sidenav-header-dark'
      ">
            <h2>Other actions</h2>
        </header>
        <footer class="sidenav-footer">
            <ix-button variant="secondary" class="sidenav-button " (click)="openFtpModal()">FTP</ix-button>
            <ix-button variant="secondary" class="sidenav-button ">SFTP</ix-button>
            <ix-button variant="secondary" class="sidenav-button " (click)="openEmailModal()"><ix-icon
                    name="mail"></ix-icon></ix-button><br>
            <ix-button variant="secondary" outline class="sidenav-button ">Move</ix-button>
            <ix-button variant="secondary" outline class="sidenav-button ">Delete</ix-button>
        </footer>
    </div>
    <ng-template #customModal let-modal>
        <div>
            <ix-modal-header class="headerModal">
                Update firmware
            </ix-modal-header>

            <ix-modal-content class="ModalMessage">
                Do you want to update the firmware version now?
            </ix-modal-content>

            <ix-modal-footer>
                <ix-button variant="secondary" (click)="modal.close()">Cancel</ix-button>
                <ix-button variant="primary" [loading]="isLoadingUpdate"
                    (click)="FirmwareUpdate(modal)">Update</ix-button>
            </ix-modal-footer>
        </div>
    </ng-template>
    <ng-template #customModalFTP let-modal>
        <div>
            <ix-modal-header class="headerModal">
                FTP Destination Server Configuration
            </ix-modal-header>
            <div *ngIf="!loading">

                <ix-modal-content class="ModalMessage">
                    <ix-input-group class="input_container">
                        <label for="URL">Server URL</label>
                        <input id="URL" type="text" placeholder="Enter url" [(ngModel)]="ftpData.Host" />
                        <label for="Port">Port</label>
                        <input id="Port" type="text" placeholder="Enter port" [(ngModel)]="ftpData.Port" />
                        <label for="Username">Username</label>
                        <input id="Username" type="text" placeholder="Enter username" [(ngModel)]="ftpData.Username" />
                        <label for="Ftp_type">Ftp Type</label>
                        <input id="Ftp_type" type="text" placeholder="Enter Ftp type" [(ngModel)]="ftpData.FTP_Type" />
                        <label for="Password">Password</label>
                        <input id="password" type="password" placeholder="Enter password"
                            [(ngModel)]="ftpData.Password" />
                        <label for="Destination">Destination Path</label>
                        <input id="Destination" type="text" placeholder="Enter destination"
                            [(ngModel)]="ftpData.destination_path" />
                    </ix-input-group>
                </ix-modal-content>
                <ix-modal-footer class="buttonFtp">
                    <ix-button variant="secondary" (click)="modal.close('delete')">Delete</ix-button>
                    <ix-button variant="secondary" (click)="modal.close()">Cancel</ix-button>
                    <ix-button variant="primary" (click)="modal.close('save')">Save</ix-button>
                </ix-modal-footer>
            </div>

            <div *ngIf="loading">
                <ix-spinner class="spinner" size="large"></ix-spinner>
            </div>
        </div>
    </ng-template>
    <ng-template #customModalEmail let-modal>
        <div>
            <ix-modal-header class="headerModal" (closeClick)="modal.close()">
                Email Destination Addresses
            </ix-modal-header>
            <div *ngIf="!loading">
                <ix-modal-content class="ModalMessage">
                    <ix-input-group class="input_container"
                        *ngFor="let email of emails; let i = index ; trackBy: trackByFn">
                        <label [for]="'Email' + i">Email {{i + 1}}</label>
                        <div class="input_icon">
                            <input [id]="'Email' + i" type="text" placeholder="Enter url" [(ngModel)]="emails[i]" />
                            <ix-icon name="trashcan" color="red" (click)="modal.close(i)"></ix-icon>
                        </div>
                    </ix-input-group>
                    <ix-input-group class="input_container"
                        *ngFor="let email of NewEmails; index as i; trackBy: trackByFn">
                        <label [for]="'Email' + (i + emails.length)">Email {{i + emails.length + 1}}</label>
                        <input [id]="'Email' + (i + emails.length)" type="text" placeholder="Enter url"
                            [(ngModel)]="NewEmails[i]" />
                    </ix-input-group>
                    <div *ngIf="(emails && emails.length === 0) && ( NewEmails && NewEmails.length === 0 )">
                        No emails found. Please add an entry for an email.
                    </div>
                </ix-modal-content>

                <ix-modal-footer class="buttonFtp">
                    <ix-button variant="secondary" (click)="addEntry()">Add entry</ix-button>
                    <ix-button variant="secondary" (click)="modal.close()">Cancel</ix-button>
                    <ix-button variant="primary" (click)="modal.close('save')">Save</ix-button>
                </ix-modal-footer>
            </div>
            <div *ngIf="loading">
                <ix-spinner class="spinner" size="large"></ix-spinner>
            </div>
        </div>
    </ng-template>

</aside>