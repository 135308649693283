import { Component, OnInit } from '@angular/core';
import { TreeContext, TreeModel } from '@siemens/ix';
import { CompanyResponse } from '../../types/company.types';
import { CompanyService } from '../../services/company/company.service';
import { Company, Device } from '../../types/company.types';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.state';
import { company, companyId, device, reset } from '../store/actions/company.actions';
import { DeviceService } from 'src/services/device/device.service';
import { TreeData, icon } from '../../types/tree.types';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'app-tree',
    styleUrls: ["./tree.component.scss"],
    templateUrl: "./tree.component.html"
})
export class TreeComponent implements OnInit {
    context: TreeContext = {
        'sample': { isExpanded: true, isSelected: false }
    };
    model!: TreeModel<TreeData>;
    companyTree!: CompanyResponse;
    searchText: string = '';
    allDevices: Device[] = [];
    filteredDevices: Device[] = [];
    selectedDevice?: Device;
    constructor(private store: Store<AppState>, private companyService: CompanyService, private deviceService: DeviceService) {
    }

    ngOnInit(): void {
        this.companyService.updateCompanyTree.subscribe(res => {
            if (res) {
                this.fetchCompanyDetails();
            }
        })
        this.deviceService.updateCompanyTree.subscribe(res => {
            if (res) {
                this.fetchCompanyDetails();
            }
        })
        this.fetchCompanyDetails();
    }
    fetchCompanyDetails() {
        this.companyService.getCompanyTree().subscribe({
            next: (data: CompanyResponse) => {
                this.companyTree = data;
                this.model = this.buildTreeModel(this.companyTree.data);
            },
            error: (error: any) => {
                console.error('Error: ', error);
            }
        });
    }
    onDeviceClick(device: Device): void {
        if (this.selectedDevice === device) {
            this.selectedDevice = undefined;
        } else {
            this.selectedDevice = device;
        }
    }
    filterDevices(): void {
        if (!this.searchText) {
            this.filteredDevices = this.allDevices;
        } else {
            this.filteredDevices = this.allDevices.filter(device =>
                device.DeviceId.toLowerCase().includes(this.searchText.toLowerCase())
            );
        }
    }
    buildTreeModel(company: Company): TreeModel<TreeData> {
        const icon_main_company: icon = {
            name: 'globe'
        }
        const res: TreeModel<TreeData> = {
            root: {
                id: 'root',
                data: {
                    name: '',
                },
                hasChildren: true,
                children: ["sample"],
            },
            sample: {
                id: "sample",
                data: {
                    name: company.CompanyName,
                    icon: icon_main_company,
                    rawcompany: company,
                },
                hasChildren: true,
                children: this.linkChildrens(company),
            }, ...this.linkAllChildrensAndDevices(company),
        };
        return res;
    }

    linkChildrens(company: Company): string[] {
        const sortedChildren = company.Children
            .sort((a, b) => a.CompanyName.localeCompare(b.CompanyName))
            .map(child => child.CompanyId);
    
        const deviceIds = company.Devices.map(device => device.DeviceId);

        return [...sortedChildren, ...deviceIds];
    }
    
    onNodeClicked(event: any): void {
        const nodeId = event.detail;
        if (nodeId) {
            const node = this.model[nodeId]?.data;
            if (node?.rawcompany) {
                this.store.dispatch(reset());
                this.store.dispatch(company({ selectedCompany: node.rawcompany }));
                this.store.dispatch(companyId({ companyId: node.rawcompany.CompanyId }))
                this.deviceService.emitDeviceClicked(false);

            } else if (node?.rawdevice) {
                this.store.dispatch(reset());
                this.store.dispatch(device({
                    selectedDevice: node.rawdevice
                }));
                this.deviceService.emitDeviceClicked(true);
                const parentId = node.ParrentId || "UNKNOWN";
                this.store.dispatch(companyId({ companyId: parentId }))
            } else {
                console.log('No data found for node', nodeId);
            }
        } else {
            console.log('Event does not contain node ID');
        }
    }


    linkAllChildrensAndDevices(company: Company): TreeModel<TreeData> {
        // Initialize the result object that will hold the tree model
        let treeModel: TreeModel<TreeData> = {};
        const icon_main: icon = {
            name: 'plant-outline'
        }
        // Iterate over each child of the company
        company.Children.forEach(child => {
            // Construct data for the current child
            let childTreeData = {
                id: child.CompanyId,
                data: {
                    name: child.CompanyName,
                    icon: icon_main,
                    rawcompany: child,
                    status: 'company'
                },
                hasChildren: child.Children.length > 0 || child.Devices.length > 0,
                children: this.linkChildrens(child)
            };
            /*
                Recursively link all children and devices of the current child
                and merge them into the tree model using Object.assign
                Object.assign is used here to merge the tree model of the current child
                into the main tree model. It effectively flattens the structure
                into a single-level object where each child and device is directly accessible.
            */
            Object.assign(treeModel, this.linkAllChildrensAndDevices(child));
            treeModel[child.CompanyId] = childTreeData;
        });
        company.Devices.forEach(device => {
            /* Determine the alarm color based on the device's connectivity status using a switch statement */
            const alarm_color = (() => {
                switch (device.ConnectivityStatus) {
                    case 'ACTIVE':
                        return "color-success--active";
                    case 'DISCONNECTED':
                        return "color-7";
                    case 'UNKNOWN':
                        return "color-7";
                    default:
                        return "";
                }
            })();

            const icon_device: icon = {
                name: 'circle-filled',
                color: alarm_color,
            }
            const icon_device_unkown: icon = {
                name: 'circle-dot',
                color: 'color-7',
            }

            const deviceData = (() => {
                switch (device.ConnectivityStatus) {
                    case 'UNKNOWN':
                        return {
                            id: device.DeviceId,
                            data: {
                                name: device.DeviceId,
                                icon: icon_device_unkown,
                                rawdevice: device,
                                ParrentId: company.CompanyId,
                            },
                            hasChildren: false,
                            children: []
                        };
                    default:
                        return {
                            id: device.DeviceId,
                            data: {
                                name: device.DeviceId,
                                icon: icon_device,
                                rawdevice: device,
                                ParrentId: company.CompanyId,
                            },
                            hasChildren: false,
                            children: []
                        };
                    }
            })();

            treeModel[device.DeviceId] = deviceData;
            this.allDevices.push(device);
        });
        return treeModel;
    }
}