
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "../store/app.state";
import { AlarmsService } from "src/services/alarms/alarms.service";
import { GridOptions } from "ag-grid-community";
import { Subject, distinctUntilChanged, take, takeUntil } from "rxjs";
import * as fromThemeSelectors from "../../app/store/selectors/themeswitcher.selector";
import * as fromCompanySelectors from "../../app/store/selectors/company.selector";
import { alarmsData } from "src/types/alerts.types";
import { AlarmResponse } from "src/types/alerts.types";
import { ModalService } from "@siemens/ix-angular";
import { ColDef } from 'ag-grid-community';
import { DeviceService } from "src/services/device/device.service";

@Component({
  selector: "app-alerts",
  templateUrl: "./alerts.component.html",
  styleUrls: ["./alerts.component.scss"],
})
export class AlertsComponent implements OnInit, OnDestroy {
  @ViewChild('customModal', { read: TemplateRef })
  customModalRef!: TemplateRef<any>;

  private unsubscribe$ = new Subject<void>();
  selectedTab: number = 0;
  customSearch: string = "";
  display: string = "none";
  isLoading: boolean = false;
  isCompanyLoading: boolean = false;
  alarms: alarmsData[] = [];
  alarmsCount: number = 0;
  currentTheme: any = "";
  historyFrom: string = "";
  historyTo: string = "";
  filteredAlarms: alarmsData[] | undefined;
  weekalarams: alarmsData[] = [];
  weekdaysAgo: string = "";
  filteredWeekAlarms: alarmsData[] | undefined;

  alarmsColumnDefs: ColDef[] = [
    { headerName: "Timestamp", field: "Date", flex: 1 },
    { headerName: "Asset name", field: "DeviceId", flex: 1 },
    {
      headerName: "Alert description",
      valueGetter: (params: { data: { HostDeviceAlarms: any[]; ModuleAlarms: any[]; }; }) => {
        const hostDeviceAlarms = params.data.HostDeviceAlarms.map(alarm => alarm.Description);
        const moduleAlarms = params.data.ModuleAlarms.map(alarm => alarm.Description);
        return [...hostDeviceAlarms, ...moduleAlarms].join(', ');
      },
      flex: 1
    },
    { headerName: "Acknowledge Timestamp", field: "AckTimestamp", flex: 1 },
  ];

  constructor(
    private store: Store<AppState>,
    private alarmsService: AlarmsService,
    private readonly modalService: ModalService,
    private deviceService: DeviceService
  ) {

    this.store
      .select(fromThemeSelectors.selectThemeToken)
      .subscribe((token) => {
        this.currentTheme = token;
        if (!token) {
          this.currentTheme = "theme-brand-light";
        }
      });

    this.deviceService.deviceClicked$.subscribe(clicked => {
      if (clicked) {
        this.getAlarms();
      }else{
        this.getCompanyAlarms();
      }
    });

    this.alarmsService.loading$.subscribe(loading => {
      this.isLoading = loading;
    });
    this.alarmsService.CompanyLoading$.subscribe(loading => {
      this.isCompanyLoading = loading;
    });
  }


  // to open tha date picker modal
  async openModal() {
    const instance = await this.modalService.open({
      content: this.customModalRef,
      data: 'Some data',
    });

    instance.onClose.on((event) => {
      this.historyFrom = event.detail.from.replace(/\//g, '-');
      if (event.detail.to) {
        this.historyTo = event.detail.to.replace(/\//g, '-');
      }
      this.getAlarms(this.historyFrom, this.historyTo);
    });

    instance.htmlElement.addEventListener(
      'keydown',
      (keyboardEvent: KeyboardEvent) => {
        console.log(keyboardEvent.key);
      }
    );
  }

  ngOnInit() {
    // to get the data of the alarms
    this.getAlarms();
    this.getCompanyAlarms();
    this.weekdaysAgo = this.get7DaysAgoDate();
    this.getWeekAlarms();

    // to display the clear input icon
    if (this.customSearch !== "") {
      this.display = "block";
    }
  }

  getCompanyAlarms(from?: string, to?: string) {
    this.alarmsService.getCompanyAlarms(from, to).pipe(
      distinctUntilChanged(),
      takeUntil(this.unsubscribe$),
    ).pipe(take(1)).subscribe({
      next: (response: AlarmResponse) => {
        if (response?.data?.Values) {
          this.alarms = response.data.Values.map(value => ({
            Date: value.Date,
            DeviceId: response.data.DeviceId,
            HostDeviceAlarms: value.HostDeviceAlarms,
            ModuleAlarms: value.ModuleAlarms,
            AckTimestamp: value.AckTimestamp
          }));
          this.filteredAlarms = this.alarms;
          this.alarmsCount = this.alarms.length;
        } else {
          console.error('No values available in response:', response);
          this.alarms = [];
          this.filteredAlarms = [];
          this.alarmsCount = 0;
        }
      },
      error: (error) => {
        console.error('Failed to fetch alarms:', error);
      }
    });
  }
  getAlarms(from?: string, to?: string) {
    this.alarmsService.getAlarms(from, to).pipe(
      distinctUntilChanged(),
      takeUntil(this.unsubscribe$),
    ).pipe(take(1)).subscribe({
      next: (response: AlarmResponse) => {
        if (response?.data?.Values) {
          this.alarms = response.data.Values.map(value => ({
            Date: value.Date,
            DeviceId: response.data.DeviceId,
            HostDeviceAlarms: value.HostDeviceAlarms,
            ModuleAlarms: value.ModuleAlarms,
            AckTimestamp: value.AckTimestamp
          }));
          this.filteredAlarms = this.alarms;
          this.alarmsCount = this.alarms.length;
        } else {
          console.error('No values available in response:', response);
          this.alarms = [];
          this.filteredAlarms = [];
          this.alarmsCount = 0;
        }
      },
      error: (error) => {
        console.error('Failed to fetch alarms:', error);

      }
    });
  }

  getWeekAlarms() {
    this.alarmsService.getAlarms(this.weekdaysAgo).pipe(
      distinctUntilChanged(),
      takeUntil(this.unsubscribe$),
    ).pipe(take(1)).subscribe({
      next: (response: AlarmResponse) => {
        if (response?.data?.Values) {
          this.weekalarams = response.data.Values.map(value => ({
            Date: value.Date,
            DeviceId: response.data.DeviceId,
            HostDeviceAlarms: value.HostDeviceAlarms,
            ModuleAlarms: value.ModuleAlarms,
            AckTimestamp: value.AckTimestamp
          }));
          this.filteredWeekAlarms = this.weekalarams;
          this.alarmsCount = this.weekalarams.length;
        } else {
          console.error('No week values available:', response);
          this.weekalarams = [];
          this.filteredWeekAlarms = [];
          this.alarmsCount = 0;
        }
      },
      error: (error) => {
        console.error('Failed to fetch week alarms:', error);
      }
    });
  }


  get7DaysAgoDate() {
    const date = new Date();
    date.setDate(date.getDate() - 7);
    return date.toISOString().split('T')[0];
  }

  // to clear the input of the search bar
  clearInput() {
    this.customSearch = "";
    this.display = "none";
    this.search(this.customSearch, this.selectedTab);
  }

  onKey(event: any) {
    event.target.value === ""
      ? (this.display = "none")
      : (this.display = "block");
    this.search(event.target.value, this.selectedTab);
  }


  // to search and filter the data in the table

  search(value1: string, tabIndex: number) {
    if (value1 === '') {
      this.filteredAlarms = this.alarms;
      this.filteredWeekAlarms = this.weekalarams;
      return;
    }

    switch (tabIndex) {
      case 0:
        this.filteredWeekAlarms = this.weekalarams?.filter((item) =>
          Object.values(item).some((value) =>
            value?.toString().toLowerCase().includes(value1.toLowerCase())
          )
        );

        break;
      case 1:
        this.filteredAlarms = this.alarms?.filter((item) =>
          Object.values(item).some((value) =>
            value?.toString().toLowerCase().includes(value1.toLowerCase())
          )
        );
        break;

      default:
        console.log("No such data exists!");
        break;
    }
  }



  // to change the tab of the alerts
  changeTab(tab: number) {
    this.selectedTab = tab;
    this.clearInput();
  }


  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}


