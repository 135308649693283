import { createAction, props } from "@ngrx/store";

export const loginSuccess = createAction(
  "[Auth] Login Success",
  props<{ token: string }>()
);

export const logout = createAction("[Auth] Logout");

export const saveICCID = createAction(
  "[Auth] Save ICCID",
  props<{ iccid: string }>()
);

export const saveIMEI = createAction(
  "[Auth] Save IMEI",
  props<{ imei: string }>()
);