import { createReducer, on } from "@ngrx/store";
import { initialState } from "../models/themeswitcher.state";
import * as ThemeActions from "../actions/themeswitcher.actions";

export const themeReducer = createReducer(
  initialState,
  on(ThemeActions.themeSuccess, (state, { currentTheme }) => ({
    ...state,
    currentTheme,
  }))
);
