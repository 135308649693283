import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, Subject, combineLatest } from 'rxjs';
import { DeviceParameterService } from 'src/services/device-parameter/device-parameter.service';
import { AppState } from '../store/app.state';
import { selectCompanyId, selectDevice } from '../store/selectors/company.selector';
import { ColDef, GridOptions, RowClassParams, RowStyle } from 'ag-grid-community';
import * as fromThemeSelectors from "../store/selectors/themeswitcher.selector";
import { ButtonAskData } from '../button-read-write-param/button-read-write-param.component';
import { DeviceProperty } from 'src/types/device.types';
import { SpinnerComponent } from '../spinner/spinner.component';
import { ParameterModalComponent } from '../parameter-modal/parameter-modal.component';

import { take, filter, switchMap, map } from 'rxjs/operators';

@Component({
    selector: 'app-table-param',
    styleUrls: ["./tableparam.component.scss"],
    templateUrl: "./tableparam.component.html",
})
export class TableDetailsComponent implements OnInit {
    @ViewChild(ParameterModalComponent) parameterModalComponent!: ParameterModalComponent;
    @ViewChild('agGrid') agGrid: any;
    apiResponse: any;
    data?: any[] = [];
    dataCategory: any[][] = [];
    ModuleData: any[] = [];
    tableData: DeviceProperty[] = [];

    private destroy$ = new Subject<void>();
    @Input() readonly: number[] | undefined;
    @Input() selectedIndex: number | undefined;

    orgsColumnDefs: ColDef[] = [
        { field: 'register', headerName: 'Registre', sortable: true, filter: true, flex: 1 },
        { field: 'paramName', headerName: 'Name', sortable: true, filter: true, flex: 1 },
        { field: 'value', headerName: 'Actual value', sortable: true, filter: true, flex: 1 },
        {
            field: 'lastUpdate',
            headerName: 'Last Update',
            sortable: true,
            filter: true,
            cellRenderer: SpinnerComponent,
            cellRendererParams: (params: any) => {
                return {
                    showSpinner: params.data.showSpinner
                };
            },
            flex: 1,
            cellStyle: {
                'display': 'flex',
                'align-items': 'center',
                'justify-content': 'flex-start',
            }
        },
        { field: 'lastParameterChange', headerName: 'Last Parameter Change', sortable: true, filter: true, flex: 1 },
        {
            headerName: 'Actions', field: 'id',
            suppressNavigable: true,
            sortable: false,
            cellRenderer: ButtonAskData,
            flex: 1,
        }
    ];

    gridOptions: GridOptions = {
        // suppressScrollOnNewData: true
    };

    private verticalScrollPosition = 0;
    private horizontalScrollPosition = 0;
    companyId$: Observable<string | undefined> = of(undefined);
    deviceId$: Observable<string | undefined> = of(undefined);
    currentTheme: string = "theme-brand-light";

    ngOnChanges(changes: SimpleChanges) {
        if (changes['selectedIndex']) {
            if (this.selectedIndex !== 0) {
                this.data = this.dataCategory[this.selectedIndex! - 1];
            } else {
                this.data = [...this.ModuleData, ...this.dataCategory.flat()];
            }
        }
    }


    constructor(
        private store: Store<AppState>,
        private deviceParameterService: DeviceParameterService
    ) { }

    ngOnInit(): void {

        this.companyId$ = this.store.select(selectCompanyId);
        this.deviceId$ = this.store.select(selectDevice).pipe(
            map(device => device ? device.DeviceId : undefined)
        );

        this.fetchAllData();
        this.store.select(fromThemeSelectors.selectThemeToken).subscribe((token) => {
            this.currentTheme = token || "theme-brand-light";
        });
    }
    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    // function to verify weither the register in the row is readonly or not
    getRowStyle = (params: RowClassParams): RowStyle | undefined => {
        const readonlyStrings = this.readonly!.map(String);
        if (params.data.permissions === 'R' || readonlyStrings.includes(params.data.register)) {
            return { 'background-color': '#69696326' };
        }
        return undefined;
    }
    //TODO make button refresh page
    async fetchAllData(): Promise<void> {
        combineLatest([this.companyId$, this.deviceId$])
            .pipe(
                filter(([companyId, deviceId]) => !!companyId && !!deviceId),
                switchMap(([companyId, deviceId]) => this.fetchDeviceAndHostParams(companyId!, deviceId!))
            )
            .subscribe({
                next: () => {
                    this.data = this.tableData;
                    this.restoreScrollPosition();
                    this.processData();
                    this.updateDataBasedOnSelectedIndex();
                },
                error: (error) => console.error('Error fetching data:', error),
            });
    }

    // Fetch device and host parameters
    fetchDeviceAndHostParams(companyId: string, deviceId: string) {
        this.resetTableData();

        const deviceParams$ = this.deviceParameterService.getDeviceParameters(companyId, deviceId).pipe(take(1));
        const hostParamsCoil$ = this.deviceParameterService.getHostDeviceParameters(companyId, deviceId, 'coil').pipe(take(1));
        const hostParamsRegister$ = this.deviceParameterService.getHostDeviceParameters(companyId, deviceId, 'register').pipe(take(1));

        return combineLatest([deviceParams$, hostParamsCoil$, hostParamsRegister$]).pipe(
            take(1),
            map(([deviceParams, hostParamsCoil, hostParamsRegister]) => {
                this.updateTableData(deviceParams, hostParamsCoil, hostParamsRegister);
            })
        );
    }

    // Reset the table data and associated variables
    resetTableData(): void {
        this.tableData = [];
        this.dataCategory = [];
        this.ModuleData = [];
    }

    // Update the table data with new fetched results
    updateTableData(deviceParams: any, hostParamsCoil: any, hostParamsRegister: any): void {
        const newDeviceData = this.initializeDataWithApiResponse(deviceParams, 'host');
        const newCoilData = this.initializeDataWithApiResponse(hostParamsCoil, 'coil');
        const newRegisterData = this.initializeDataWithApiResponse(hostParamsRegister, 'register');

        this.tableData = [...newDeviceData, ...newCoilData, ...newRegisterData];
    }

    // Process the table data to categorize it
    processData(): void {
        this.tableData.forEach(dataItem => {
            const categoryIndex = this.getCategoryIndex(dataItem.ParameterNumber);

            if (categoryIndex >= 0 && categoryIndex < 9) {
                this.dataCategory[categoryIndex] = this.dataCategory[categoryIndex] || [];
                this.dataCategory[categoryIndex].push(dataItem);
            } else {
                this.ModuleData.push(dataItem);
            }
        });
    }

    // Helper method to categorize data items based on ParameterNumber
    getCategoryIndex(parameterNumber: number): number {
        return Math.floor(parameterNumber / 100);
    }

    updateDataBasedOnSelectedIndex() {
        if (this.selectedIndex !== undefined) {
            if (this.selectedIndex !== 0) {
                this.data = this.dataCategory[this.selectedIndex - 1];
            } else {
                this.data = [...this.ModuleData, ...this.dataCategory.flat()];
            }
        }
    }

    onRefreshData() {
        this.saveScrollPosition();
        this.fetchAllData();
    }

    saveScrollPosition() {
        if (this.agGrid && this.agGrid.api) {
            const scrollPosition = this.agGrid.api.getHorizontalPixelRange();
            this.horizontalScrollPosition = scrollPosition.left;
            this.verticalScrollPosition = this.agGrid.api.getVerticalPixelRange().top;
        }
    }

    restoreScrollPosition() {
        if (this.agGrid && this.agGrid.api) {
            setTimeout(() => {

                const rowHeight = this.agGrid.api.getSizesForCurrentTheme().rowHeight;
                const normalizedScrollPosition = Math.floor(this.verticalScrollPosition / rowHeight);

                this.agGrid.api.ensureIndexVisible(normalizedScrollPosition, 'top');

                this.agGrid.api.ensureColumnVisible(this.horizontalScrollPosition);
            }, 500);

        }
    }
    initializeDataWithApiResponse(apiResponse: any, type: string): DeviceProperty[] {
        let tableData: DeviceProperty[] = [];
        if (!apiResponse || !apiResponse.data) {
            return tableData;
        }

        Object.keys(apiResponse.data).forEach(register => {
            if (register !== "DeviceId") {
                let dataItem = apiResponse.data[register];
                // convert the data from the api response to a structured object
                tableData.push({
                    register: register,
                    paramName: dataItem.ParameterName,
                    value: dataItem.Value,
                    lastUpdate: dataItem.LastUpdate || '',
                    lastParameterChange: dataItem.LastParameterChange || '',
                    min: dataItem.MinValue,
                    max: dataItem.MaxValue,
                    permissions: dataItem.Permission,
                    description: dataItem.Description,
                    showSpinner: dataItem.RequestStatus != null && dataItem.RequestStatus !== '',
                    DataType: dataItem.DataType,
                    ParameterNumber: dataItem.ParameterNumber,
                });
            }
        });

        return tableData;
    }
}