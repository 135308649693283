<!-- <div class="example-header">
  <mat-form-field>
    <input matInput (keyup)="applyFilter($event)" placeholder="Filter" />
  </mat-form-field>
</div>

<div class="example-container mat-elevation-z8">
  <mat-table [dataSource]="dataSource" matSort> -->
    <!-- ID Column -->
    <!-- <ng-container matColumnDef="Asset Type">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Asset Type </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.AssetType }} </mat-cell>
    </ng-container> -->

    <!-- Progress Column -->
    <!-- <ng-container matColumnDef="Claim Status">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Claim Status
      </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.ClaimStatus }}</mat-cell>
    </ng-container> -->

    <!-- Name Column -->
    <!-- <ng-container matColumnDef="IMEI">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        IMEI
      </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.IMEI }} </mat-cell>
    </ng-container> -->

    <!-- Color Column -->
    <!-- <ng-container matColumnDef="Serial Number">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Serial Number
      </mat-header-cell>
      <mat-cell *matCellDef="let row" [style.color]="row.color">
        {{ row.SerialNumber }}
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"> </mat-row>
  </mat-table>

  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div> -->

<form class="needs-validation m-3">
  <ix-input-group>
    <span slot="input-start" style="margin-left: 58%">
      <ix-icon name="search" size="16"></ix-icon>
    </span>
    <input
      ng-init="verifyStart()"
      [(ngModel)]="customSearch"
      name="input"
      (keyup)="onKey($event)"
      id="input-string"
      type="string"
    />
    <span slot="input-end">
      <ix-icon-button
        (click)="clearInput()"
        id="clear-button"
        icon="clear"
        ghost="{true}"
        size="16"
        [style.display]="display"
      ></ix-icon-button>
    </span>
  </ix-input-group>
</form>
<ag-grid-angular
        [rowData]="filteredDeviceData"
        [columnDefs]="deviceColumnDefs"
        class="ag ag-theme-alpine-dark ag-theme-ix"
      >
      </ag-grid-angular>
