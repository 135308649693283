import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable, from } from "rxjs";
import { AppState } from "src/app/store/app.state";
import * as fromAuthSelectors from '../../app/store/selectors/auth.selector';
import { map, switchMap, take } from 'rxjs/operators';
import { ApiService } from "./auth.service";
import { saveICCID, saveIMEI } from "src/app/store/actions/auth.actions";






@Injectable()
export class LoginGuardService implements CanActivate {

  constructor(private store: Store<AppState>, public auth: ApiService, public router: Router) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const iccid = route.queryParams['iccid'];
    const imei = route.queryParams['imei'];
    return from(this.auth.loadToken()).pipe(
      switchMap(() => {
        return this.store.select(fromAuthSelectors.selectToken).pipe(
          take(1),
          map(token => {
            if (token) {
              if(iccid && imei) {
                this.store.dispatch(saveICCID({ iccid }));
                this.store.dispatch(saveIMEI({ imei }));
                this.router.navigate(['dashboard/userOrgManagement'], { queryParams: { iccid, imei } });
                return false;
              }
              this.router.navigate(['dashboard/home']);
              return false;
            }
            if (iccid && imei) {
              this.store.dispatch(saveICCID({ iccid }));
              this.store.dispatch(saveIMEI({ imei }));
            }
            return true;
          })
        );
      })
    );
  }
}
