<div class="scrolledTable">
  <ix-tabs [selected]="selectedTab" layout="stretched" class="tabs">
    <ix-tab-item (click)="changeTab(0)">
      <span class="icon">
        <ix-icon name="user-management"></ix-icon>
        History
      </span>
    </ix-tab-item>
  </ix-tabs>
  <ng-container *ngIf="!isLoading && !isCompanyLoading ; else loading">
    <ng-container class="content">
      <div class="header">
        <div class="left-side">
          <div class="searchBar">
            <form class="needs-validation m-2">
              <ix-input-group>
                <span slot="input-start" style="margin-left: 58%">
                  <ix-icon name="search" size="16"></ix-icon>
                </span>
                <input ng-init="verifyStart()" [(ngModel)]="customSearch" name="input" (keyup)="onKey($event)"
                  id="input-string" type="string" />
                <span slot="input-end">
                  <ix-icon-button (click)="clearInput()" id="clear-button" icon="clear" ghost="{true}" size="16"
                    [style.display]="display"></ix-icon-button>
                </span>
              </ix-input-group>
            </form>
          </div>
          <ix-button *ngIf="selectedTab === 0" (click)="openModal()">Date range</ix-button>
        </div>
        <ix-button class="m-1" id="button" variant="primary">Export</ix-button>
      </div>
      <app-piechart [alarmData]="alarms"  *ngIf="selectedTab === 0"></app-piechart>
      <div class="title-header" [ngClass]="{'tab-0': selectedTab === 0, 'tab-1': selectedTab === 1}">
        <ix-content-header class="one" *ngIf="selectedTab === 0" headerTitle="Alerts list">
        </ix-content-header>

      </div>
      <div class="grid" *ngIf="selectedTab === 0">
        <ag-grid-angular class="gridMobile tab-0" [rowData]="filteredAlarms"
          [columnDefs]="alarmsColumnDefs" class="ag ag-theme-alpine-dark ag-theme-ix">
        </ag-grid-angular>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #loading>
    <ix-spinner class="spinner" size="large"></ix-spinner>
  </ng-template>
  <ng-template #customModal let-modal>
    <div>
      <ix-modal-header>
        Pick a range of dates
      </ix-modal-header>
      <ix-date-picker (dateSelect)="modal.close($event)"></ix-date-picker>
    </div>
  </ng-template>
</div>