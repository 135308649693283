export interface AuthState {
    token: string | null;
    iccid?: string | null;
    imei?: string | null;
}

export const initialState: AuthState = {
    token: null,
    iccid: null,
    imei: null,
};
