<div class="treecomponent">
  <div class="treetitle">
    <h3>Device List</h3>
  </div>
  <div class="searchbar">
    <input type="text" [(ngModel)]="searchText" (ngModelChange)="filterDevices()" placeholder="Search..." class="form-control"/>
  </div>

  <div class="tabledevices" *ngIf="searchText; else treeView">
    <ul>
      <li *ngFor="let device of filteredDevices" 
        (click)="onDeviceClick(device)"
        [class.selected]="selectedDevice === device">
      {{ device.DeviceId }}
    </li>
    </ul>
  </div>

  <ng-template #treeView>
    <div class="tree">
      <ix-tree
        root="root"
        [model]="model"
        [context]="context"
        [renderItem]="treeItem"
        (nodeClicked)="onNodeClicked($event)"
      ></ix-tree>
      <ng-template #treeItem let-item>
        <div class="d-flex align-items-center">
          <div class="text">
            <ix-icon [name]="item.icon.name" size="16" [color]="item.icon.color" class="me-2"></ix-icon>
            {{ item.name }}
          </div>
        </div>
      </ng-template>
    </div>
  </ng-template>
</div>
