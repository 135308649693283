import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "../../services/auth/auth.service";
import { AppState } from "../store/app.state";
import { Store } from "@ngrx/store";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  isAdmin : boolean = false;
  constructor(public route: Router, public apiService: ApiService,private store : Store<AppState>) {}

  ngOnInit(): void {
    // document.body.style.overflowY = "scroll";
    this.store.select('company').subscribe((data) => {
      if(data.userScope?.includes("ADMIN")){
        this.isAdmin = true;
      }
    })
  }

  goToPage(pageaddress: string) {
    this.route.navigate([pageaddress]);
  }

  goToUserManagement() {
    this.route.navigate(["dashboard/userOrgManagement"]);
  }

  async logout() {
    window.localStorage.clear();
    await this.apiService.logout().then((res) => {
      // console.log("ress", res);
    });

    this.route.navigate(["/login"]);
  }
}
