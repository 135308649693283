<div class="modelcontainer">
    <h1>CLAIM ASSET</h1>
    <form id="assetForm" class="row1 g-3 needs-validation justify-content:center"
        [ngClass]="{ 'was-validated': wasValidated }" [formGroup]="assetForm">
        <div class="formContainer">
            <div>
                <div class="row1">
                    <label for="type">Module type </label>
                    <div class="radio-group">
                        <div class="radio-option">
                          <input
                            type="radio"
                            id="moduleType1"
                            name="type"
                            formControlName="type"
                            value="4G"
                          />
                          <label for="moduleType1">4G</label>
                        </div>
                        <div class="radio-option">
                          <input
                            type="radio"
                            id="moduleType2"
                            name="type"
                            formControlName="type"
                            value="LORA"
                          />
                          <label for="moduleType2">LORA</label>
                        </div>
                        <div class="radio-option">
                            <input
                              type="radio"
                              id="moduleType3"
                              name="type"
                              formControlName="type"
                              value="LoraGateway"
                            />
                            <label for="moduleType3">LoRa Gateway</label>
                          </div>
                      </div>
                    <span class="text-danger"
                        *ngIf="(assetFormControl['type'] && submitted) && assetFormControl['type'].errors?.['required']">
                        <sup>*</sup>Type is required
                    </span>
                    
                </div>
                <div class="row1" *ngIf="assetFormControl['type'].value === '4G'">
                    <label for="iccid">ICCID </label>
                    <input id="iccid" type="text"
                        placeholder="Enter ICCID"
                        formControlName="iccid" required />
                    
                    <span class="text-danger"
                        *ngIf="(assetFormControl['iccid'] && submitted) && assetFormControl['iccid'].errors?.['required']">
                        <sup>*</sup>ICCID is required
                    </span>
                </div>
                <div class="row1" *ngIf="assetFormControl['type'].value === 'LORA'">
                    <label for="devEUI">devEUI </label>
                    <input id="devEUI" type="text"
                        placeholder="Enter devEUI"
                        formControlName="devEUI" required />
                    
                    <span class="text-danger"
                        *ngIf="(assetFormControl['devEUI'] && submitted) && assetFormControl['devEUI'].errors?.['required']">
                        <sup>*</sup>devEUI is required
                    </span>
                </div>
               
            </div>
        </div>
        <div class="buttons">
            <ix-button class="m-1" variant="primary"
                (click)="create($event)">Claim</ix-button>
            <ix-button class="close-modal" variant="secondary"
                (click)="close()">Close</ix-button>
        </div>
    </form>
</div>
